import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import Search from "../common/search";
import URI from "urijs";
import { Close } from "../../components/common/icons";
import { Store } from "../../contexts/Store";
import { useMediaQuery } from "react-responsive";
import Header from "../../components/header";
import Parse, { domToReact } from "html-react-parser";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import { FaTimes } from "react-icons/fa";
import Subscribe from "../global/subscribe";

const MenuBuilder = ({ menu, session, checkout }) => {
  const { config, t, addNotification } = Store.useState(s => s);
  const location = useLocation();
  const currentUri = URI(location.pathname + location.search).toString();
  const [elements, setElements] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const handleSelectSuggestion = suggestion => setRedirect(suggestion.path);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const pathToCompare = location.pathname + location.search;
  const isHeader = menu.location === "navbar";

  const getElementForEntry = useCallback(
    ({ entry, i }) => {
      switch (entry.type) {
        case "html": {
          const replace = (domNode) => {
            if (domNode.name === "a" && domNode.attribs.href && !(new URI(domNode.attribs.href).is("absolute"))) {
              return <Link to={domNode.attribs.href}>{domToReact(domNode.children)}</Link>;
            } else domToReact(domNode);
          };
          return (
            <div className={`html ${entry.className || ""}`} key={i} >
              {Parse(entry.data.html || "", { replace })}
            </div>
          );
        }
        case "image": {
          const Image = <img className={entry.type} src={entry.data.src} alt={entry.data.alt} />;
          const className = `image ${entry.className || ""}`;
          const isExternal = entry.data?.to && new URI(entry.data.to).is("absolute");
          if (entry.data?.to)
            return !isExternal ? <Link key={i} id={entry.id || ""} className={className} to={entry.data?.to || "/"}>
              {Image}
            </Link> : <a
              key={i}
              id={entry.id}
              className={`link ${entry.className || ""}`}
              target="_blank"
              rel="noopener noreferrer"
              href={entry.data.to}>
              {Image}
            </a>;
          else
            return (
              <div key={i} id={entry.id || ""} className={className}>
                {Image}
              </div>
            );
        }
        case "search":
          return (
            <div className={`search ${entry.className || ""}`} key={i}>
              <Search placeHolder={t("searchPlaceholder")} onSelected={handleSelectSuggestion} />
            </div>
          );
        case "link": {
          const isExternal = entry.data.to && new URI(entry.data.to).is("absolute");
          if (!isExternal)
            return (
              <Link key={i} className={`link ${entry.className || ""} ${pathToCompare === entry.data.to ? "active" : ""}`} to={entry.data.to}>
                {t(`${entry.data.key}`, entry.data.key)}
              </Link>
            );
          else
            return (
              <a
                key={i}
                id={entry.id}
                className={`link ${entry.className || ""}`}
                target="_blank"
                rel="noopener noreferrer"
                href={entry.data.to}>
                {t(`${entry.data.key}`, entry.data.key)}
              </a>
            );
        }
        case "dropdown":
          return (
            <Dropdown
              key={i}
              entry={entry}
              t={t}
              isTabletOrMobile={isTabletOrMobile}
              getElementForEntry={getElementForEntry}
              location={currentUri}
            />
          );
        case "homeOrLogin":
          return <HomeOrLogin key="homeOrLogin" session={session} t={t} checkout={checkout} isTabletOrMobile={isTabletOrMobile} />;
        case "group":
          return (
            <nav key={`group-${i}`} className={`group ${entry.className || ""}`}>
              {entry.entries
                .filter(e => !isHeader || (isTabletOrMobile ? e.type === "link" || e.type === "dropdown" : 1))
                .map((e, i) => getElementForEntry({ entry: e, i }))}
            </nav>
          );
        case "subscribe":
          return <SubscribeComponent addNotification={addNotification} config={config} key="homeOrLogin" session={session} t={t} checkout={checkout} isTabletOrMobile={isTabletOrMobile}/>;
        default:
          return null;
      }
    },
    [t, isTabletOrMobile, currentUri, session, checkout, isHeader]
  );
  const menuLocation = menu.location;

  const getAllElements = useCallback(async () => {
    const elementsToPush = [];
    let i = 0;
    let filteredEntries = menu.entries;
    if (isTabletOrMobile && menuLocation === "navbar") {
      filteredEntries = menu.entries.filter(e => e.type === "group" || e.type === "link");
      filteredEntries.push({ type: "homeOrLogin" });
    }
    for (const entry of filteredEntries) {
      i++;
      elementsToPush.push(getElementForEntry({ entry, i }));
    }
    setElements(elementsToPush);
  }, [menu.entries, isTabletOrMobile, getElementForEntry]);

  useEffect(() => {
    getAllElements();
  }, [getAllElements]);

  if (isTabletOrMobile && menuLocation === "navbar")
    return (
      <nav id={menu.id} className={"menu defaultNavbar mobile"}>
        <MobileStructure
          config={config}
          elements={elements}
          location={location}
          checkout={checkout}
          session={session}
          handleSelectSuggestion={handleSelectSuggestion}
          t={t}
        />
      </nav>
    );
  else
    return (
      <nav id={menu.id} className={`menu ${menu.className} ${isTabletOrMobile ? "mobile" : ""}`}>
        <div className="content">
          {elements}
          {redirect ? <Redirect to={redirect} /> : null}
        </div>
      </nav>
    );
};

const MobileStructure = ({ config, elements, location, handleSelectSuggestion, t, checkout, session }) => {
  const [isOpen, setIsOpen] = useState(false);
  const logo = config.getProperty("designs", "squareSVG") || config.getProperty("designs", "logoImage");
  const shopName = config.getProperty("information", "shopName");

  useLayoutEffect(() => {
    if (document.body.classList.contains("mobileMenuOpen")) {
      document.body.classList.remove("mobileMenuOpen");
    }
    if (isOpen) {
      document.body.classList.add("mobileMenuOpen");
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className={`content ${isOpen ? "open" : "closed"}`}>
      <Header />
      <div className="header">
        <div className="left">
          <Link onClick={e => setIsOpen(false)} to="/" id="brandLogo">
            {logo ? <img alt={shopName} src={logo} /> : shopName}
          </Link>
        </div>
        <div className="right">
          <button type="button" onClick={e => setIsOpen(!isOpen)}>
            {isOpen ? <Close /> : <i className="cg-icon-burger" />}
          </button>
        </div>
      </div>
      <div className={`entries ${isOpen ? "open" : "closed"}`}>{elements}</div>
      <div className={"search"}>
        <Search placeHolder={t("searchPlaceholder")} onSelected={handleSelectSuggestion} />
      </div>
    </div>
  );
};

export const Dropdown = ({ entry, t, isTabletOrMobile, getElementForEntry, location }) => {
  const { data } = entry;
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const isExternal = data.main.data.to && new URI(data.main.data.to).is("absolute");

  const handleClick = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = event => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.addEventListener("scroll", handleScroll);
    };
  });

  if (isTabletOrMobile) {
    return (
      <div className={`dropdown ${entry.className || ""}`}>
        <div className={`dropDownTitle ${data.className || ""} ${isOpen ? "open" : "closed"}`}>
          {data.main.data.to ? (
            <>
              {isExternal ? (
                <>
                  <a
                    id={entry.id}
                    className={`link ${entry.className || ""}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.main.data.to}>
                    {t(`${data.main.data.key}`, data.main.data.key)}
                  </a>
                  <i
                    className="cg-icon-catalogue-down"
                    onClick={e => setIsOpen(!isOpen)}
                  />
                </>
              ) : (
                <>
                  <Link to={data.main.data.to}> {t(`${data.main.data.key}`, data.main.data.key)}</Link>
                  <i
                    className="cg-icon-catalogue-down"
                    onClick={e => setIsOpen(!isOpen)}
                  />
                </>)
              }
            </>
          ) : (
            <>
              <span onClick={e => setIsOpen(!isOpen)}>{t(`${data.main.data.key}`, data.main.data.key)}</span>
              <i
                className="cg-icon-catalogue-down"
                onClick={e => setIsOpen(!isOpen)}
              />
            </>)
          }
        </div>
        <ul className={`subMenuMobile ${isOpen ? "open" : "closed"}`}>
          {data.entries && data.entries
            .filter(e => e.type === "link")
            .map((i, idx) => (
              <li id={i.id} className={i.className || ""} key={i.data.key}>
                {getElementForEntry({ entry: i, i: idx })}
              </li>
            ))}
        </ul>
      </div >
    );
  }

  return (
    <div className={`dropdown ${entry.className || ""}`} ref={wrapperRef}>
      <div className={`${data.main.className || ""} top`}>
        {data.main.data.to ? (
          <Link to={data.main.data.to}>
            {data.main.data.key && t(`${data.main.data.key}`, data.main.data.key)}
            <i
              className="cg-icon-catalogue-down"
              onClick={e => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            />
          </Link>
        ) : (
          <button type="button" className="navItem" onClick={e => setIsOpen(!isOpen)}>
            {data.main.data.key && t(`${data.main.data.key}`, data.main.data.key)}
            <i className="cg-icon-catalogue-down" />
          </button>
        )}
      </div>
      {isOpen ? (
        <div className="subMenu">
          <ul>
            {data.entries && data.entries.map((i, idx) => (
              <li className={i.className || ""} key={i.data.key}>
                {getElementForEntry({ entry: i, i: idx })}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export const HomeOrLogin = ({ session, t, checkout, isTabletOrMobile }) => {
  if (isTabletOrMobile)
    return (
      <div key="homeOrLogin" className="homeOrLogin">
        {session ? (
          <Link to="/home" className="home">
            {session.user.name} ({checkout.items.length})
          </Link>
        ) : (
          <span>
            <Link to="/home" className="basket">
              {t("basket")} ({checkout ? checkout.items.length : 0})
            </Link>
            <Link to="/login" className="login">
              {t("login")}
            </Link>
          </span>
        )}
      </div>
    );
  else
    return (
      <div key="homeOrLogin" className="homeOrLogin">
        {session ? (
          <Link to="/home" className="home">
            {session.user.name} ({checkout.items.length})
          </Link>
        ) : (
          <span>
            <Link to="/home" className="basket">
              {t("basket")} ({checkout ? checkout.items.length : 0})
            </Link>
            <span> | </span>
            <Link to="/login" className="login">
              {t("login")}
            </Link>
          </span>
        )}
      </div>
    );
};

const SubscribeComponent = ({ config, addNotification, session, t, checkout }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      minWidth: "30%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const hasDefaultList = !!config.getProperty("newsletter", "defaultListRef");
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} isOpen onRequestClose={hideModal} style={customStyles}>
        <div id="subscribeModal" className="">
          <div className="modalHeader">
            <h2>{t("subscribeToOurNewsletter")}</h2>
            <button onClick={e => hideModal()}>
              <FaTimes />
            </button>
          </div>
          <hr />
          <Subscribe
            config={config}
            addNotification={addNotification}
            t={t}
            listRef={config.getProperty("newsletter", "defaultListRef")}
          />
        </div>
      </ReactModal>
    ),
    []
  );
  return hasDefaultList ? (
    <button
      type="button"
      className="subscribe"
      onClick={e => {
        e.preventDefault();
        showModal();
      }}>
      {t("subscribe")}
    </button>
  ) : null;
};

export default MenuBuilder;
