import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import URI from "urijs";
import clone from "clone";
import { Store } from "../../contexts/Store";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";

const CookiesComponent = () => {
  const { t } = Store.useState(s => s);
  const [agreedToCookies, setAgreedToCookies] = useState(!!Cookies.get("cookies-performance"));
  const cookiesList = [
    { id: "performance", title: t("cookiesPerformanceTitle"), description: t("cookiesPerformanceDescription"), active: Cookies.get("cookies-performance") === "true" },
    { id: "functional", title: t("cookiesFunctionalTitle"), description: t("cookiesFunctionalDescription"), active: true }
  ];
  const [cookieSettings, setCookieSettings] = useState([...cookiesList]);
  const host = URI(window.location.href).hostname();
  const isDemoWebsite = host === "demo.common-ground.io";

  const handleCheckboxChange = (e) => {
    const state = cookieSettings;
    state.find(s => s.id === e.target.name).active = e.target.checked;
    setCookieSettings([...state]);
  };

  const acceptSelection = () => {
    const performance = cookieSettings.find(s => s.id === "performance");
    Cookies.set("cookies-performance", performance.active ? "true" : "false", { expires: 365 });
    setAgreedToCookies(true);
    hideModal();
  };

  useEffect(() => {
    if (agreedToCookies) {
      const performance = cookieSettings.find(s => s.id === "performance");
      if (!performance.active) {
        Cookies.remove("locale");
        Cookies.remove("_gid");
        Cookies.remove("_ga");
      }
    }
  }, [cookieSettings, agreedToCookies]);

  useEffect(() => {
    Store.update(s => {
      s.cookies = {
        openModal: showModal
      };
    });
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookies-performance", "true", { expires: 365 });
    const state = clone(cookieSettings);
    state.find(s => s.id === "performance").active = true;
    setCookieSettings([...state]);
    setAgreedToCookies(true);
    hideModal();
  };

  const handleRefuseCookies = (e) => {
    Cookies.set("cookies-performance", "false", { expires: 365 });
    const state = clone(cookieSettings);
    state.find(s => s.id === "performance").active = false;
    setCookieSettings([...state]);
    setAgreedToCookies(true);
    hideModal();
  };

  const CookiesSmall = () => {
    return (
      <>
        <p>
          {t("cookiesMainNotice") + " "}
          <br />
          <button type="button" onClick={showModal}>
            {t("managePreferences")}
          </button>
        </p>
        <div className="buttons">
          <button className="refuseAll" type="button" onClick={handleRefuseCookies}>
            {t("refuseAll")}
          </button>
          <button className="acceptAll" type="button" onClick={acceptCookies}>
            {t("agreeToAll")}
          </button>
        </div>
      </>
    );
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      minWidth: "30%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} isOpen onRequestClose={hideModal} style={customStyles}>
        <div id="cookiesModal" className="">
          <form onSubmit={e => e.preventDefault()}>
            <h3 className="cookiesTitle">{t("informationWeCollect")}</h3>
            <p>{t("cookiesMainDescription")} <a href="/legal">{t("privacyPolicy")}</a>
            </p>
            <div className="inline">
              <button className="acceptSelection" onClick={acceptSelection}>
                {t("acceptSelection")}
              </button>
              <button className="refuseAll" onClick={handleRefuseCookies}>
                {t("refuseAll")}
              </button>
            </div>
            <label className="cookieOption">
              <input type="checkbox" name="performance" checked={cookieSettings[0].active} onChange={handleCheckboxChange} />
              <div className="cookieDescription optional">
                <p><strong>{t("cookiesPerformanceTitle")}</strong></p>
                <p>{t("cookiesPerformanceDescription")}</p>
              </div>
            </label>
            <label className="cookieOption">
              <input type="checkbox" disabled name="performance" defaultChecked={true} />
              <div className="cookieDescription functional">
                <p><strong>{t("cookiesFunctionalTitle")}</strong></p>
                <p>{t("cookiesFunctionalDescription")}</p>
              </div>
            </label>
          </form>
        </div>
      </ReactModal>
    ),
    [cookieSettings]
  );

  if (isDemoWebsite)
    return (
      <div id="demoNotice">
        <p>
          This is a demonstration eshop website. Build your own at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://common-ground.io/register"
          >
            common-ground.io
          </a>
        </p>
      </div>
    );
  else if (!agreedToCookies) {
    return (
      <div id="cookies" className="">
        <CookiesSmall t={t} />
      </div >
    );
  } else return null;
};

export default CookiesComponent;
