import React from "react";
import Select from "../common/select";
import { getData, getName } from "country-list";

export const getDefaultAddressObject = props => {
  return {
    type: props.type || "billing",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    alpha2: "",
    postCode: ""
  };
};

export default function Address ({ address, t, readOnly, handleChange, type, index, withType, withDescription }) {
  if (readOnly) {
    return (
      <address>
        <p>
          {address.addressLine1}
          <br />
          {address.addressLine2 ? <span>{address.addressLine2}</span> : null}
          {address.state ? (
            <>
              {address.state}
              <br />
            </>
          ) : null}
          {address.city} {address.postCode}
          <br />
          {address.country}
        </p>
      </address>
    );
  }
  const typeValues = [
    { value: "billingAndShipping", label: "Billing & Shipping" },
    { value: "billing", label: "Billing" },
    { value: "shipping", label: "Shipping" }
  ];
  return (
    <div className="address">
      <div className="addressFields">
        <div className="inline">
          {withDescription ? (
            <label>
              {t("description")}:
              <input
                type="text"
                name="description"
                required
                value={address.description || ""}
                onChange={e => handleChange(type, e.target.name, e.target.value, index)}
              />
            </label>
          ) : (
            <div />
          )}
          {withType ? (
            <label>
              {t("type")}:
              <Select
                options={typeValues}
                value={{ value: address.type, label: typeValues.find(v => v.value === address.type).label }}
                onChange={option => handleChange(type, "type", option.value, index)}
              />
            </label>
          ) : (
            <div />
          )}
        </div>
        <label>
          {t("addressLine")} 1:
          <input
            type="text"
            name="addressLine1"
            required
            value={address.addressLine1 || ""}
            onChange={e => handleChange(type, e.target.name, e.target.value, index)}
          />
        </label>
        <label>
          {t("addressLine")} 2:
          <input
            type="text"
            name="addressLine2"
            value={address.addressLine2 || ""}
            onChange={e => handleChange(type, e.target.name, e.target.value, index)}
          />
        </label>
        <div className="inline">
          <label>
            {t("city")}:
            <input
              type="text"
              name="city"
              required
              value={address.city || ""}
              onChange={e => handleChange(type, e.target.name, e.target.value, index)}
            />
          </label>
          <label>
            {t("postCode")}:
            <input
              type="text"
              name="postCode"
              required
              value={address.postCode || ""}
              onChange={e => handleChange(type, e.target.name, e.target.value, index)}
            />
          </label>
        </div>
        <div className="inline">
          <label>
            {t("state")}:
            <input
              type="text"
              name="state"
              required
              value={address.state || ""}
              onChange={e => handleChange(type, e.target.name, e.target.value, index)}
            />
          </label>
          <label>
            {t("country")}:
            <Select
              value={{
                value: address.alpha2,
                label: getName(address.alpha2 || "")
              }}
              required
              onChange={option => handleChange(type, "alpha2", option.value, index)}
              options={getData().map(c => ({ label: c.name, value: c.code }))}
            />
          </label>
        </div>
      </div>
    </div>
  );
}
