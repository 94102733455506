import React, { useEffect } from "react";
import Meta from "./global/meta";
import { Store } from "../contexts/Store";

export default function Legal () {
  const { config, t } = Store.useState(s => s);

  useEffect(() => {
    Store.update(s => { s.globalClass = "legal"; });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTimeout(function () {
      window.prerenderReady = true;
    }, 100);
  }, []);

  return (
    <div id="legal">
      <Meta title="Terms and conditions" description="Our terms and conditions" />
      <div id="header">
        <h1>{t("termsAndConditions")}</h1>
      </div>
      <hr></hr>
      <div
        id="content"
        dangerouslySetInnerHTML={{
          __html: config.getProperty("information", "legalText")
        }}></div>
    </div>
  );
}
