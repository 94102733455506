import React from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Store } from "../../contexts/Store";
import ReactGA4 from "react-ga4";
import Parse from "html-react-parser";

export function truncate (string, num) {
  if (!num) num = 150;
  string = string.replace(/"/, " ");
  if (string.length > num) {
    return string.substring(0, num - 3) + " ...";
  } else return string;
}

export default function Meta (props) {
  const config = Store.useState(s => s.config);
  const { noIndex } = props;
  const canonical = props.path !== undefined ? "https://" + config.domain + props.path : null;
  const title = props.title ? `${props.title} - ${config.getProperty("information", "shopName")}` : config.getProperty("information", "shopName");
  const defaultImage = config.getProperty("designs", "logoImage");
  const introduction = config.getProperty("information", "introduction");
  const description = props.description || `${title} - ${introduction}`;

  let indexValue = "index";
  if (noIndex || config.getProperty("hosting", "noindex")) indexValue = "noindex";

  let indexMetaSelector = document.querySelector("meta[name=\"robots\"]");
  if (!indexMetaSelector) {
    indexMetaSelector = document.createElement("meta");
    indexMetaSelector.setAttribute("name", "robots");
    indexMetaSelector = document.getElementsByTagName("head")[0].appendChild(indexMetaSelector);
  }
  indexMetaSelector.setAttribute("content", indexValue);
  const defaultfavicon = "/64-defaultfavicon.png";

  ReactGA4.set({ title: title });

  const headTags = config?.eshop?.head?.tags;
  return (
    <Helmet>
      <title>{title}</title>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {canonical ? <meta property="og:url" content={canonical}></meta> : null}
      <meta name="keywords" content={title.replace(/[^a-zA-Z ]/g, "").replace(/ /g, ",")} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={config.getProperty("information", "shopName")} />
      {props.updated ? <meta property="og:updated_time" content={moment(props.updated).unix()} /> : null}
      {description ? <meta name="description" content={truncate(description, 150)} /> : null}
      {props.fourOFour ? <meta name="prerender-status-code" content="404" /> : null}
      {description ? <meta property="og:description" content={truncate(description, 200)} /> : null}
      <meta property="og:image" itemProp="image" content={props.image || defaultImage} />
      <link
        rel="shortcut icon"
        type="image/png"
        href={config.getProperty("designs", "favicon") || defaultfavicon}></link>
      <meta property="og:updated_time" content={moment(config.modified).format()} />
      {props.brand ? <meta property="product:brand" content={props.brand} /> : null}
      {props.listing ? (
        <meta property="product:availability" content={props.listing.stock.quantity > 0 ? "in stock" : "oos"} />
      ) : null}
      {props.listing ? <meta property="product:condition" content="new" /> : null}
      {props.listing ? <meta property="product:price:amount" content={props.listing.prices.sale} /> : null}
      {props.listing ? (
        <meta property="product:price:currency" content={config.getProperty("information", "currency")} />
      ) : null}
      {props.id ? <meta property="product:retailer_item_id" content={props.id} /> : null}
      {headTags ? Parse(headTags.join(""), {}) : null}
    </Helmet>
  );
}
