import React, { useState } from "react";
import Price from "../../common/price";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { Less, Plus } from "../../common/icons";
import { Store } from "../../../contexts/Store";
import clone from "clone";
import { getTranslatedOption } from "../../../utils/helpers";

export default function Basket (props) {
  const { checkout, checkoutUpdateQuantity, addNotification, config } = Store.useState(s => s);
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleQuantityUpdate = async (quantity, listingId, withSubmit) => {
    try {
      setIsUpdating(true);
      if (withSubmit) await checkoutUpdateQuantity(listingId, parseInt(quantity));
      else {
        const clonedCheckout = clone(checkout);
        const line = clonedCheckout.items.find(i => i.listing.id === listingId);
        line.quantity = parseInt(quantity);
        Store.update(s => {
          s.checkout = clonedCheckout;
        });
      }
    } catch (e) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSubmitQuantity = async e => {
    e.preventDefault();
    try {
      await checkoutUpdateQuantity(parseInt(e.target.listingId.value), parseInt(e.target.quantity.value));
    } catch (e) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const preTaxes = config.taxes.rules.showPricesBeforeTaxes;

  return (
    <div id="basketItems">
      <div id="basketHeader" className="basketEntry header">
        <p className="image">
          {t("yourBasket")} ({checkout.items.length})
        </p>
        <p className="itemDescription"></p>
        <p className="sale">{t("price")}</p>
        <p className="quantity">{t("qty")}</p>
        <p className="total">{t("total")}</p>
      </div>
      {checkout.items.map((i, index) => (
        <div key={i.listing.id} className="basketEntry">
          <div className="image">
            <Link to={i.item.path}>
              <img alt={i.item.description} src={i.item.thumbnail}></img>
            </Link>
          </div>
          <div className="itemDescription">
            <p className="itemDescription">
              <Link to={i.item.path}>{i.item.description}</Link>
            </p>
            <div className="options">
              {i.listing.options.map(o => (
                <div key={o.name} className="option">
                  <p>
                    {getTranslatedOption({ t, option: o })}: {o.value}
                  </p>
                </div>
              ))}
            </div>
            <br />
            {i.listing.preOrder ? (
              <>
                <br />
                <span className="preorder">{i.listing.available ? `${t("preOrderNotice")} ${moment(i.listing.available).fromNow()}` : t("preOrderState")}</span>
              </>
            ) : null}
          </div>
          <div className="sale">
            <Price value={preTaxes ? i.price.beforeTaxes : i.price.base} />
          </div>
          <div className="quantity">
            {props.readOnly ? (
              <p>{i.quantity}</p>
            ) : (
              <form onSubmit={handleSubmitQuantity}>
                <div className="quantityEditor">
                  <button disabled={isUpdating} onClick={e => handleQuantityUpdate(i.quantity - 1, i.listing.id, true)} type="button">
                    <Less />
                  </button>
                  <input type="hidden" name="listingId" value={i.listing.id} />
                  <input
                    type="number"
                    name="quantity"
                    min="0"
                    step="1"
                    onChange={e => handleQuantityUpdate(e.target.value, i.listing.id)}
                    max={i.listing.stock.quantity}
                    value={i.quantity || ""}
                  />
                  <button disabled={isUpdating} onClick={e => handleQuantityUpdate(i.quantity + 1, i.listing.id, true)} type="button">
                    <Plus />
                  </button>
                </div>
              </form>
            )}
          </div>
          <div className="total">
            <Price value={preTaxes ? i.price.subtotalBeforeTaxes : i.price.subtotal.toFixed(2)} />
          </div>
        </div>
      ))}
    </div>
  );
}
