import React from "react";
import Price from "../../common/price";
import { useTranslation } from "react-i18next";

export default function Summary ({ checkout, config }) {
  const { t } = useTranslation();
  const shopCollection = checkout.shipping.shopCollection;
  const shippingAddress = checkout.shipping.address || {};
  const placeBasket = config.getProperty("shipping", "placeBasket");

  return (
    <div id="summary">
      <h2>{t("orderSummary")}</h2>
      <hr />
      <p className="justify">
        <span>{t("totalItems")}</span>
        <span>{checkout.totals.itemQuantity}</span>
      </p>
      <p className="justify">
        <span>{t("subtotal")}</span>
        <span>
          <Price value={checkout.totals.subtotal} />
        </span>
      </p>
      {checkout.totals.taxes.map(t =>
        <p key={t.name} className="justify">
          <span>{t.name}</span>
          <Price value={t.amount} />
          </p>
      )}
      <p className="justify">
        <span>{t("shipping")}</span>
        <span>
          {shopCollection ? (
            t("shopCollection")
          ) : (
            <>
              {!checkout.shipping.method ? t("pending") : (
                <>
                  {shippingAddress.country} <Price value={checkout.shipping.price.base} />
                </>
              )}
            </>
          )}
        </span>
      </p>
      {checkout.totals.discount ? (
        <p className="justify">
          <span>Discount:</span>
          <Price value={checkout.totals.discount} />
        </p>
      ) : null}
      <hr />
      <h2 className="justify">
        <span>{t("total")}</span>
        <span>
          <Price value={checkout.totals.leftToPay} />
          {placeBasket && !shopCollection ? <small> + {t("shipping")}</small> : null}
        </span>
      </h2>
    </div>
  );
}
