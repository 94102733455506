import React, { useState, useEffect } from "react";
import Loader from "../common/loader";
import Address from "../common/address";
import { useMutation, useQuery } from "@apollo/client";
import { POST_USER_UPDATE, GET_USER, POST_LIST_OPTOUT, POST_LIST_OPTIN, POST_BUYER_DEACTIVATE } from "../../graphql/queries";
import { Store } from "../../contexts/Store";
import clone from "clone";
import Cookies from "js-cookie";

const Details = ({ t, addNotification }) => {
  const { loading, data } = useQuery(GET_USER);
  const [userToEdit, setUserToEdit] = useState(undefined);

  const [userUpdate, { loading: updateLoading }] = useMutation(POST_USER_UPDATE);
  const { config } = Store.useState(s => s);
  const listRef = config.getProperty("newsletter", "defaultListRef");
  const [optin, { loading: optinLoading }] = useMutation(POST_LIST_OPTIN);
  const [optout, { loading: optoutLoading }] = useMutation(POST_LIST_OPTOUT);
  const [closeAccount, { loading: closeAccountLoading }] = useMutation(POST_BUYER_DEACTIVATE);
  const { resetStore, createNewCheckout, reloadConfig } = Store.useState(s => s);
  const isLoading = updateLoading || closeAccountLoading || optinLoading || optoutLoading;

  useEffect(() => {
    if (data && data.user) setUserToEdit(clone(data.user));
  }, [data]);

  const handleAddressChange = async (type, name, value, index) => {
    const address = userToEdit.addresses[index];
    if (!address) return;
    address[name] = value;
    setUserToEdit({ ...userToEdit });
  };

  const handleUserDetailsChange = e => {
    setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value });
  };

  const handleUserDetailsBackInStockChange = e => {
    setUserToEdit({ ...userToEdit, preferences: { notifications: { backInStock: { email: e.target.checked } } } });
  };

  const handleSubmitDetails = async e => {
    e.preventDefault();
    try {
      const invalidAddress = userToEdit.addresses.find(a => !a.alpha2 || !a.description);
      if (invalidAddress) throw new Error("Address country missing");
      const dataToSubmit = { ...userToEdit };
      if (userToEdit.password && userToEdit.password2)
        if (userToEdit.password !== userToEdit.password2) throw new Error("Passwords do not match");
        else dataToSubmit.password = Buffer.from(userToEdit.password).toString("base64");
      else delete dataToSubmit.password;

      dataToSubmit.addresses.forEach(a => {
        delete a._id;
        delete a.country;
        delete a.id;
        delete a.__typename;
      });

      if (listRef) {
        if (!userToEdit.isSubscribed && e.target.subscribe.checked)
          await optin({ variables: { listRef, email: userToEdit.email } });
        else if (userToEdit.isSubscribed && !e.target.subscribe.checked)
          await optout({ variables: { listRef, email: userToEdit.email, reason: "User’s preference" } });
      }

      const { data } = await userUpdate({ variables: dataToSubmit });
      const user = data?.user;
      if (user) {
        setUserToEdit(clone(user));
        Store.update(s => {
          s.session.user = clone(user);
        });
        addNotification({ ok: 1, message: "Profile updated" });
      }
    } catch (e) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleAddAddress = () => {
    if (!userToEdit.addresses) userToEdit.addresses = [];
    userToEdit.addresses.push({ description: "New address", type: "billingAndShipping" });
    setUserToEdit(clone(userToEdit));
  };

  const handleDeleteAddress = i => {
    userToEdit.addresses.splice(i, 1);
    setUserToEdit(clone(userToEdit));
  };

  const onClickCloseAccount = async () => {
    if (window.confirm(t("closeAccountConfirmation"))) {
      try {
        await closeAccount();
        Cookies.remove("jwt");
        Store.update(s => {
          s.session = undefined;
        });
        await reloadConfig();
        await resetStore();
        await createNewCheckout();
        addNotification({ ok: 1, message: t("closeAccountSuccess") });
      } catch (e) {
        addNotification({ ok: 0, message: e.toString() });
      }
    };
  };

  if (!userToEdit || loading) return <Loader />;

  return (
    <div id="myDetails">
      <div className="left">
        <div className="header">
          <h1>{t("details")}</h1>
          <button disabled={isLoading} form="detailsForm" className="primary" type="submit">
            {t("saveDetails")}
          </button>
        </div>
        <div className="content">
          <form onSubmit={handleSubmitDetails} id="detailsForm">
            <section className="mainDetails">
              <label>
                {t("firstName")}:
                <input
                  type="text"
                  name="firstName"
                  required
                  value={userToEdit.firstName}
                  onChange={handleUserDetailsChange}
                />
              </label>
              <label>
                {t("lastName")}:
                <input
                  type="text"
                  required
                  name="lastName"
                  value={userToEdit.lastName}
                  onChange={handleUserDetailsChange}
                />
              </label>
              <label>
                {t("organisation")}:
                <input
                  type="text"
                  name="organisation"
                  value={userToEdit.organisation || ""}
                  onChange={handleUserDetailsChange}
                />
              </label>
              <label>
                {t("taxId")}:
                <input
                  type="text"
                  name="taxNumber"
                  value={userToEdit.taxNumber || ""}
                  onChange={handleUserDetailsChange}
                />
              </label>
              <label>
                {t("telephone")}:
                <input
                  type="text"
                  name="telephone"
                  value={userToEdit.telephone || ""}
                  onChange={handleUserDetailsChange}
                />
              </label>
              <div>
                <label>
                  {t("email")}:
                  <input
                    type="text"
                    name="email"
                    readOnly={false}
                    value={userToEdit.email || ""}
                    required
                    onChange={handleUserDetailsChange}
                  />
                </label>
              </div>
              {process.env.backinstock ? <div>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={userToEdit.preferences?.notifications?.backInStock?.email}
                    name="backInStock"
                    onChange={handleUserDetailsBackInStockChange}
                    />
                  {t("backInStock", "Notify me by email when items from my wantllist become available")}
                </label>
              </div> : null }
              {listRef ? (
                <>
                  <label className="subscribe">
                    <input type="checkbox" defaultChecked={userToEdit.isSubscribed} name="subscribe" />
                    {t("subscribe", { shopName: config.getProperty("information", "shopName") })}
                  </label>
                </>
              ) : null}
            </section>
            <section className="password">
              <div className="inline">
                <label>
                  {t("newPassword")}:
                  <input
                    type="password"
                    name="password"
                    defaultValue={""}
                    autoComplete="off"
                    onChange={handleUserDetailsChange}
                  />
                </label>
                <label>
                  {t("confirmPassword")}:
                  <input
                    type="password"
                    name="password2"
                    defaultValue={""}
                    autoComplete="off"
                    onChange={handleUserDetailsChange}
                  />
                </label>
              </div>
            </section>
            <section className="addresses">
              <div className="header">
                <h2>Addresses</h2>
              </div>
              <div className="content">
                {userToEdit.addresses.map((a, i) => (
                  <div key={i}>
                    <Address
                      withType={true}
                      withDescription={true}
                      index={i}
                      handleChange={handleAddressChange}
                      address={a}
                      t={t}
                    />
                    <button disabled={isLoading} type="button" className="delete primary" onClick={e => handleDeleteAddress(i)}>
                      {t("delete")}
                    </button>
                  </div>
                ))}
                {userToEdit.addresses.length < 2 ? (
                  <div className="addAddress">
                    <button disabled={isLoading} type="button" className="primary" onClick={handleAddAddress}>
                      {t("add")}
                    </button>
                  </div>
                ) : null}
              </div>
            </section>
            <button disabled={isLoading} form="detailsForm" className="primary" type="submit">
              {t("saveDetails")}
            </button>
          </form>
          <section className="closeAccount">
            <div className="header">
              <h2>{t("closeAccountPermanently")}</h2>
            </div>
            <div className="content">
              <p>{t("closeAccountDescription", { shopName: config.getProperty("information", "shopName") })}</p>
            </div>
            <button disabled={isLoading} onClick={onClickCloseAccount} className="red">{t("closeMyAccount")}</button>
          </section>
        </div>
      </div>
      <div className="right"></div>
    </div>
  );
};

export default Details;
