import React, { useEffect, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_CHECKOUT,
  POST_CHECKOUT,
  POST_CHECKOUT_UPDATE,
  POST_CHECKOUT_UPDATE_ITEMS,
  POST_VALIDATE_VOUCHER,
  REMOVE_VOUCHER,
  ADD_VOUCHER
} from "../graphql/queries";
import { Store } from "./Store";
import { Link } from "react-router-dom";
import clone from "clone";
import Cookies from "js-cookie";

export default props => {
  const { addNotification, checkout, updateAndSetCheckout } = Store.useState(s => s);
  const storedId = Cookies.get("checkout-id");

  const [getCheckout, { error, data: checkoutData }] = useLazyQuery(GET_CHECKOUT);
  const [createCheckout, { called: createCheckoutCalled }] = useMutation(POST_CHECKOUT);
  const [updateCheckout] = useMutation(POST_CHECKOUT_UPDATE);
  const [updateCheckoutItems] = useMutation(POST_CHECKOUT_UPDATE_ITEMS);
  const [validateVoucher] = useMutation(POST_VALIDATE_VOUCHER);
  const [addVoucher] = useMutation(ADD_VOUCHER);
  const [removeVoucher] = useMutation(REMOVE_VOUCHER);

  const setCheckout = checkout =>
    Store.update(s => {
      s.checkout = clone(checkout);
    });

  const createNewCheckout = useCallback(async () => {
    try {
      const {
        data: { checkoutCreate: checkout }
      } = await createCheckout();
      Cookies.set("checkout-id", checkout.id, { expires: 365 });
      setCheckout(checkout);
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  }, [createCheckout, addNotification]);

  useEffect(() => {
    if (storedId) getCheckout({ variables: { id: storedId } });
  }, [storedId, getCheckout]);

  useEffect(() => {
    if ((error || !storedId) && !createCheckoutCalled) createNewCheckout();
  }, [error, createNewCheckout, createCheckoutCalled, storedId]);

  useEffect(() => {
    const checkout = checkoutData && checkoutData.checkout;
    if (checkout) setCheckout(checkout);
  }, [checkoutData]);

  useEffect(() => {
    Store.update(s => {
      s.updateCheckout = async checkoutData => {
        try {
          const { data } = await updateCheckout({ variables: { id: checkout.id, ...checkoutData } });
          setCheckout(data.checkoutUpdate);
        } catch (e) {
          createNewCheckout();
          throw e;
        }
      };
      s.addVoucher = addVoucher;
      s.removeVoucher = removeVoucher;
      s.createNewCheckout = createNewCheckout;
    });
  }, [updateCheckout, removeVoucher, addVoucher, checkout, createNewCheckout]);

  useEffect(() => {
    Store.update(s => {
      s.updateBasket = async listingId => {
        try {
          const items = checkout.items.map(i => ({
            listingId: parseFloat(i.listing.id),
            quantity: i.quantity
          }));
          const index = items.findIndex(i => i.listingId === listingId);
          if (index > -1) items.splice(index, 1);
          else items.push({ listingId, quantity: 1 });
          const { data } = await updateCheckoutItems({ variables: { id: checkout.id, items } });
          setCheckout(data.checkoutUpdateItems);
          addNotification({
            ok: 1,
            message: (
              <div className="addWantlist">
                <p className="title">Checkout updated</p>
                <Link to="/home">View my basket</Link>
              </div>
            )
          });
        } catch (e) {
          if (e.toString() === "Error: Checkout not found") createNewCheckout();
          addNotification({
            ok: 0,
            message: (
              <div className="addWantlist">
                <Link to="/login">{e.message}</Link>
              </div>
            )
          });
        }
      };

      s.checkoutUpdateQuantity = async (listingId, quantity) => {
        try {
          const items = checkout.items.map(i => ({
            listingId: parseFloat(i.listing.id),
            quantity: i.quantity
          }));
          const index = items.findIndex(i => i.listingId === listingId);
          if (index === -1) return addNotification({ ok: 0, message: "Basket entry not found" });
          if (quantity <= 0) items.splice(index, 1);
          else items[index].quantity = quantity;
          const {
            data: { checkoutUpdateItems: updatedCheckout }
          } = await updateCheckoutItems({ variables: { id: checkout.id, items } });
          setCheckout(updatedCheckout);
          addNotification({ ok: 1, message: "Checkout updated" });
          return updatedCheckout;
        } catch (e) {
          if (e.toString() === "Error: Checkout not found") createNewCheckout();
          addNotification({ ok: 0, message: e.data || e.toString() });
        }
      };
    });
  }, [addNotification, checkout, updateCheckoutItems, createNewCheckout]);

  useEffect(() => {
    Store.update(s => {
      s.addVoucher = async id => {
        try {
          await validateVoucher({ variables: { id: checkout.id, voucherId: id } });
          const { data } = await addVoucher({ variables: { id: checkout.id, voucherId: id } });
          return data;
        } catch (e) {
          return { ok: 0, message: e.toString() };
        }
      };

      s.removeVoucher = async () => {
        await removeVoucher({ variables: { id: checkout.id } });
      };
    });
  }, [checkout, validateVoucher, addVoucher, removeVoucher, updateAndSetCheckout]);
  return null;
};
