import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Meta from "../components/global/meta";
import Loader from "../components/common/loader";
import URI from "urijs";
import { useLazyQuery } from "@apollo/client";
import { GET_COLLECTION } from "../graphql/queries";
import { Tile, Full, List } from "../components/common/views";
import { Store } from "../contexts/Store";
import { LinkWithSubmenu } from "../components/catalogue";
import FourOFour from "../components/global/404";
import Cookies from "js-cookie";

export default function Collection (props) {
  const location = useLocation();
  const [collectionData, setCollectionData] = useState();
  const currentUri = new URI(location.pathname + location.search);
  const search = currentUri.search(true);
  let variables = {};

  const id = props.match.params.id || props.id;

  if (!isNaN(id)) variables.id = parseInt(id);
  else if (props.match.params.handle) variables.handle = props.match.params.handle;

  const [getCollection, { loading, data, error }] = useLazyQuery(GET_COLLECTION);
  const { t, isTabletOrMobile, addNotification } = Store.useState(s => s);

  variables = {
    ...variables,
    page: parseInt(search.page) || 1,
    limit: parseInt(search.limit) || 1,
    sort: search.sort || undefined, // By default the collection's sort will be used
    order: parseInt(search.order) || undefined
  };

  useEffect(() => {
    window.scroll(0, 0);
    getCollection({ variables });
  }, [location.pathname, location.search]);

  const handleScrollToContent = () => {
    const element = document.getElementById("collectionContentWrapper");
    if (element) element.scrollIntoView();
  };

  useEffect(() => {
    if (data && data.collection) {
      setCollectionData(data.collection);
      Store.update(s => { s.globalClass = `collection collection-${data.collection.collection.id}`; });
    }
  }, [data]);

  const handlePerPageChange = amount => {
    Cookies.set("catalogue.limit", amount);
    currentUri.setSearch("limit", amount);
    props.history.push({ pathname: currentUri.pathname(), search: currentUri.search() });
  };

  if (error) return <FourOFour />;
  if (!collectionData) return <Loader />;

  const getImageForFormat = (media, format) => {
    const foundFormat = media.formats[format];
    if (foundFormat) return foundFormat.url;
    else return media.url;
  };

  let ViewObject = Tile;
  if (collectionData.collection?.viewType === "full") ViewObject = Full;
  else if (collectionData.collection?.viewType === "list") ViewObject = List;

  return (
    <div id="collection">
      <Meta title={collectionData.collection.title} />
      {collectionData?.collection?.banner?.media ? <div className="banner">
        <img src={getImageForFormat(collectionData.collection.banner.media, collectionData.collection.banner.format)} />
      </div> : null }
      <div className="header">
        <h1>{collectionData.collection.title}</h1>
      </div>
      {collectionData?.collection?.description ? <div className="description">
        <div dangerouslySetInnerHTML={{ __html: collectionData.collection.description?.content }} />
      </div> : null }
      <div id="collectionContentWrapper">
        <div className="content">
          <Header
            t={t}
            loading={loading}
            pagination={collectionData.page.pagination}
            isTabletOrMobile={isTabletOrMobile}
            currentUri={currentUri}
            catalogueName={collectionData.collection.title}
            handlePerPageChange={handlePerPageChange}
          />
          <div className="entries">
            {collectionData.page.items.map(e => (
              <ViewObject key={e.id} entry={e} addNotification={addNotification} />
            ))}
          </div>
          {collectionData?.page?.pagination?.pages > 1 ? <Header
            t={t}
            loading={loading}
            noSorting={true}
            scrollToContent={handleScrollToContent}
            pagination={collectionData.page.pagination}
            isTabletOrMobile={isTabletOrMobile}
            currentUri={currentUri}
            catalogueName={collectionData.collection.title}
            handlePerPageChange={handlePerPageChange}
          /> : null }
        </div>
      </div>
    </div>
  );
}

const CollectionEmbed = ({ data: blockData, shouldLoad }) => {
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const [page, setPage] = useState(1);
  const variables = { page, id: blockData.id, randomise: !!blockData.randomise, limit: blockData.limit, sort: blockData.sort, order: blockData.order };
  const [getCollection, { data }] = useLazyQuery(GET_COLLECTION);
  const t = Store.useState(s => s.t);

  useEffect(() => {
    if (shouldLoad && !data) getCollection({ variables });
  }, [location, shouldLoad, page, data]);

  const pagination = 0;
  const collectionData = data && data.collection;

  if (!collectionData) return <Loader />;

  const link = `${collectionData?.collection?.handle}?sort=${blockData.sort}&order=${blockData.order}`;
  return (
    <>
      <div className="content">
        <div className="header">
          <Link className="seeMore" to={link}><h1>{collectionData.collection.title}</h1></Link>
        </div>
        <div className="entries">
          {collectionData.page.items.map(e => (<Tile key={e.id} entry={e} />))}
        </div>
        <div className="footer">
          <Link className="seeMore" to={link}>
            <button type='button'>{t("seeMore")}</button>
          </Link>
        </div>
        {pagination ? <PaginationEmbed onPageChange={setPage} t={t} currentUri={currentUri} pagination={collectionData.page.pagination}/> : null}
      </div>
    </>
  );
};

const Header = ({
  t,
  noSorting,
  loading,
  pagination,
  currentUri,
  scrollToContent,
  isTabletOrMobile,
  handlePerPageChange
}) => {
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: pagination.page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: pagination.page - 1 });

  const limits = [40, 80, 120];
  if (isTabletOrMobile) {
    return (
      <div className="pagination mobile">
        <div className="top">
          <div className="left">
            <p className="pageCount">
              {t("page")} {pagination.page} {t("of")} {pagination.pages}
            </p>
            {loading ? <Loader /> : <span/>}
          </div>
          <ul className="naviguator">
            <li
              className={pagination.page <= 1 ? "disabled" : ""}
              onClick={e => pagination.page <= 1 && e.preventDefault()}>
              <Link to={prevUri.toString()} onClick={e => scrollToContent && scrollToContent()} className="previous">
                <span className="paginationWords">{t("previous")}</span>
              </Link>
            </li>
            <li className={pagination.page >= pagination.pages ? "disabled" : ""}>
              <Link
                to={nextUri.toString()}
                className="next"
                onClick={e => { if (scrollToContent) scrollToContent(); if (pagination.page >= pagination.pages) e.preventDefault(); } }>
                <span className="paginationWords">{t("next")}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom">
          <LinkWithSubmenu
            currentUri={currentUri}
            items={[
              {
                active: pagination.order === -1 && pagination.sort === "price",
                query: { sort: "price", order: -1 },
                name: t("priceDesc")
              },
              {
                active: pagination.order === 1 && pagination.sort === "price",
                query: { sort: "price", order: 1 },
                name: t("priceAsc")
              },
              {
                active: pagination.order === -1 && pagination.sort === "posted",
                query: { sort: "posted", order: -1 },
                name: t("addedDesc")
              },
              {
                active: pagination.order === 1 && pagination.sort === "posted",
                query: { sort: "posted", order: 1 },
                name: t("addedAsc")
              }
            ]}
            name={t("sortBy")}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="pagination">
      {!noSorting ? <div className="left">
        <LinkWithSubmenu
          currentUri={currentUri}
          items={[
            {
              active: pagination.order === -1 && pagination.sort === "price",
              query: { sort: "price", order: -1 },
              name: t("priceDesc")
            },
            {
              active: pagination.order === 1 && pagination.sort === "price",
              query: { sort: "price", order: 1 },
              name: t("priceAsc")
            },
            {
              active: pagination.order === -1 && pagination.sort === "posted",
              query: { sort: "posted", order: -1 },
              name: t("addedDesc")
            },
            {
              active: pagination.order === 1 && pagination.sort === "posted",
              query: { sort: "posted", order: 1 },
              name: t("addedAsc")
            }
          ]}
          name={t("sortBy")}
        />
        {loading ? <Loader /> : null}
      </div> : <span /> }
      <div className="right">
        <div className="page">
          <p className="pageCount">
            {t("page")} {pagination.page} {t("of")} {pagination.pages}
          </p>
          <div className="perPage">
            {limits.map((p, i) => (
              <React.Fragment key={p}>
                <button
                  className={pagination.limit === p ? "active" : ""}
                  type="button"
                  onClick={e => handlePerPageChange(p)}>
                  {p}
                </button>

                {i < limits.length - 1 ? <span>|</span> : ""}
              </React.Fragment>
            ))}
          </div>
        </div>
        <ul className="naviguator">
          {pagination.page > 1 && (
            <li>
              <Link onClick={e => scrollToContent && scrollToContent()} to={prevUri.toString()} className="previous">
                <span className="paginationWords">{t("previous")}</span>
              </Link>
            </li>
          )}
          {pagination.page < pagination.pages && (
            <li>
              <Link onClick={e => scrollToContent && scrollToContent()} to={nextUri.toString()} className="next">
                <span className="paginationWords">{t("next")}</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const PaginationEmbed = ({ pagination, t, onPageChange }) => {
  return (<ul className="naviguator">
    <li>Page {pagination.page} of {pagination.pages}</li>
    {pagination.page > 1 && (
      <li>
        <button onClick={e => onPageChange(pagination.page - 1)} className="previous">
          <span className="paginationWords">{t("previous")}</span>
        </button>
      </li>
    )}
    {pagination.page < pagination.pages && (
      <li>
        <button onClick={e => onPageChange(pagination.page + 1)} className="next">
          <span className="paginationWords">{t("next")}</span>
        </button>
      </li>
    )}
  </ul>);
};

export { CollectionEmbed };
