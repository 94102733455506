import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tile } from "./common/views";
import Price from "./common/price";
import ReactModal from "react-modal";
import { FaTimes, FaExclamation } from "react-icons/fa";
import Loader from "./common/loader";
import Summary from "./checkout/includes/summary";
import Basket from "./checkout/includes/basket";
import Subscribe from "./global/subscribe";
import Meta from "./global/meta";
import Cookies from "js-cookie";
import { useModal } from "react-modal-hook";
import Details from "./home/details";
import { Store } from "../contexts/Store";
import { useMutation, useQuery } from "@apollo/client";
import {
  POST_LOGOUT,
  GET_ITEM_SUGGESTIONS,
  POST_INVENTORY_STOCKLIST,
  POST_CHECKOUT_VERIFY,
  POST_CHECKOUT_DISASSOCIATE_USER
} from "../graphql/queries";
const isDev = process.env.NODE_ENV !== "production";

export default function Home (props) {
  const {
    config,
    session,
    reloadConfig,
    resetStore,
    addNotification,
    userUpdate,
    t,
    checkout,
    checkoutUpdateQuantity,
    createNewCheckout
  } = Store.useState(s => s);
  const isLoggedIn = !!session;
  const [isGeneratingStockList, setIsGeneratingStockList] = useState(false);
  const [basketMessage, setBasketMessage] = useState(null);

  const [logout] = useMutation(POST_LOGOUT);
  const [getInventoryFile] = useMutation(POST_INVENTORY_STOCKLIST);
  const [disassociateCheckout] = useMutation(POST_CHECKOUT_DISASSOCIATE_USER);
  const [verifyCheckout] = useMutation(POST_CHECKOUT_VERIFY);

  let checkoutUrl;
  if (isDev) checkoutUrl = `http://localhost:3004/checkout/${checkout.id}/details`;
  else checkoutUrl = `/checkout/${checkout.id}/details`;

  const { loading: suggestionsLoading, data: suggestionsData } = useQuery(GET_ITEM_SUGGESTIONS, {
    variables: { limit: 8, type: "ReleaseItem" }
  });

  const suggestions = suggestionsData && suggestionsData.suggestions;

  useEffect(() => {
    async function verify () {
      try {
        const {
          data: { checkoutVerifyAndUpdate }
        } = await verifyCheckout({ variables: { id: checkout.id } });
        if (checkoutVerifyAndUpdate && checkoutVerifyAndUpdate.message) {
          setBasketMessage(checkoutVerifyAndUpdate.message);
          Store.update(s => {
            s.checkout = checkoutVerifyAndUpdate.checkout;
          });
        }
      } catch (e) {
        createNewCheckout();
      }
      setTimeout(function () {
        window.prerenderReady = true;
      }, 100);
    }
    verify();
  }, [verifyCheckout, checkout.id, createNewCheckout]);

  const handleLogout = async () => {
    try {
      const { data } = await disassociateCheckout({ variables: { id: checkout.id } });
      await logout();
      Cookies.remove("jwt");
      Store.update(s => {
        s.session = undefined;
        s.checkout = data.checkout;
      });
      reloadConfig();
      resetStore();
      addNotification({ ok: 1, message: "Logged out" });
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      minWidth: "30%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} isOpen onRequestClose={hideModal} style={customStyles}>
        <div id="subscribeModal" className="">
          <div className="modalHeader">
            <h2>{t("subscribeToOurNewsletter")}</h2>
            <button onClick={e => hideModal()}>
              <FaTimes />
            </button>
          </div>
          <hr />
          <Subscribe
            config={config}
            addNotification={addNotification}
            t={t}
            listRef={config.getProperty("newsletter", "defaultListRef")}
          />
        </div>
      </ReactModal>
    ),
    []
  );

  const handleGetStockList = async e => {
    addNotification({ ok: 1, message: "Please wait while generating your export file" });
    setIsGeneratingStockList(true);
    try {
      const { data } = await getInventoryFile();
      window.location = data.inventoryFileExport;
      addNotification({ ok: 1, message: "Stocklist ready" });
    } catch (e) {
      addNotification({ ok: 0, message: e.data });
    } finally {
      setIsGeneratingStockList(false);
    }
  };

  const hasDefaultList = !!config.getProperty("newsletter", "defaultListRef");

  const step = props.match.params.step;

  if (session === undefined) return <Loader />;

  return (
    <div id="home">
      <Meta title="Home" />
      <div id="header">
        {isLoggedIn ? (
          <>
            <div id="left">
              <h1>{session.user.name}</h1>
              <Link to="/home">{t("basket")}</Link>
              <Link to="/orders">{t("orderHistory")}</Link>
              <Link to="/wantlist?stock=all">Wantlist</Link>
              {config.getProperty("information", "stockListDownload") ? (
                <button className="downloadStockList" onClick={handleGetStockList} disabled={isGeneratingStockList}>
                  {isGeneratingStockList ? <Loader /> : "Download stock list"}
                </button>
              ) : null}
              {hasDefaultList ? (
                <button type="button" onClick={showModal}>
                  {t("subscribe")}
                </button>
              ) : null}
            </div>
            <div id="right">
              <Link to={"/home/details"}>{t("myDetails")}</Link>
              <button type="button" className="logout" onClick={handleLogout}>
                {t("logout")}
              </button>
            </div>
          </>
        ) : (
          <>
            <h1>{t("haveAnAccountAlready")}</h1>
            <Link to="/login" className="navItem">
              {t("login")}
            </Link>
          </>
        )}
      </div>

      {step === "details" ? <Details userUpdate={userUpdate} t={t} addNotification={addNotification} /> : null}
      {step === undefined ? (
        <>
          {basketMessage ? (
            <>
              <h3 className="basketMessage">
                <FaExclamation />
                {basketMessage}
              </h3>
            </>
          ) : null}
          {checkout.items.length ? (
            <div id="basketAndSummary">
              <div id="basket">
                <Basket checkout={checkout} checkoutUpdateQuantity={checkoutUpdateQuantity} readOnly={false}></Basket>
              </div>
              <div id="summaryAndShipping">
                <Summary config={config} checkout={checkout} />
                {checkout.shipping && checkout.shipping.freeShippingThreshold ? (
                  <div id="freeShippingThreshold">
                    <p>
                      {t("freeShippingThreshold")} <Price value={checkout.shipping.freeShippingThreshold} />
                    </p>
                  </div>
                ) : null}
                <a href={checkoutUrl} >
                  <button id="proceedToCheckout">
                    {t("proceedToCheckout")}
                  </button>
                </a>
              </div>
            </div>
          ) : null}
          <div id="emptyBasket">
            <div id="suggestions">
              <h2>{!checkout.items.length ? `${t("emptyBasket")}:` : `${t("youMayAlsoLike")}:`}</h2>
              <div id="suggestionEntries">
                {!suggestionsLoading && suggestions ? suggestions.map(s => <Tile key={s.id} entry={s} />) : <Loader />}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
