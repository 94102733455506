import React, { useState, useEffect } from "react";
import URI from "urijs";
import Meta from "../components/global/meta";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { POST_LIST_OPTOUT } from "../graphql/queries";

export default function Unsubscribe (props) {
  const [reason, setReason] = useState("noLongerWantEmails");
  const [resetMessage, setResetMessage] = useState(undefined);
  const [isUnsubscribe] = useState(false);
  const location = useLocation();
  const params = new URI(location.pathname + location.search).search(true);
  const [optout] = useMutation(POST_LIST_OPTOUT);

  useEffect(() => {
    if (!params.email || !params.list) setResetMessage("Bad url arguments...");
  }, [params.email, params.list]);

  const handleChangeReason = e => {
    setReason(e.target.value);
  };

  const handleSubmitForm = async e => {
    e.preventDefault();
    try {
      const { data } = await optout({
        variables: {
          email: params.email,
          listRef: params.list,
          reason
        }
      });
      setResetMessage(data.listOptout);
    } catch (e) {
      setResetMessage(e.data || e.toString());
    }
  };

  return (
    <div id="unsubscribe">
      <Meta index={false} />
      <div id="content">
        {resetMessage ? (
          <h2 id="resetMessage">{resetMessage}</h2>
        ) : (
          <>
            {!isUnsubscribe ? (
              <div id="reason">
                <h2 id="resetMessage">Please let us know why you would like to unsubscribe:</h2>
                <form onSubmit={handleSubmitForm}>
                  <label>
                    <input
                      onChange={handleChangeReason}
                      checked={reason === "noLongerWantEmails"}
                      type="radio"
                      name="reason"
                      value="noLongerWantEmails"
                    />
                    I no longer want to receive these emails
                  </label>
                  <label>
                    <input
                      onChange={handleChangeReason}
                      checked={reason === "neverSignedUp"}
                      type="radio"
                      name="reason"
                      value="neverSignedUp"
                    />
                    I never signed up for this mailing list
                  </label>
                  <label>
                    <input
                      onChange={handleChangeReason}
                      checked={reason === "inappropriate"}
                      type="radio"
                      name="reason"
                      value="inappropriate"
                    />
                    The emails are inappropriate
                  </label>
                  <label>
                    <input
                      onChange={handleChangeReason}
                      checked={reason === "spam"}
                      type="radio"
                      name="reason"
                      value="spam"
                    />
                    The emails look like spam
                  </label>
                  <div className="submit">
                    <button type="submit">Unsubscribe</button>
                  </div>
                </form>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
