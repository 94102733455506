import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Loader from "./common/loader";
import { Tile } from "./common/views";
import { PlayerButtonDetailed } from "./common/buttons";
import { BuyButton } from "./common/buyButton";
import Image from "./image";
import moment from "moment";
import Meta from "./global/meta";
import FourOFour from "./global/404";
import { Wantlist } from "./common/icons";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_ITEM, GET_ITEM_SUGGESTIONS, POST_WANT, POST_RELEASE_SNIPPET_ARCHIVE, POST_REGISTER_EVENT } from "../graphql/queries";
import { Store } from "../contexts/Store";

const Item = props => {
  const { config, session, addNotification, t } = Store.useState(s => s);

  const [forSales, setForSales] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [isGeneratingZip, setIsGeneratingZip] = useState(false);
  const imgElement = useRef(null);

  const { loading, error, data } = useQuery(GET_ITEM, {
    variables: { id: parseInt(props.match.params.id) }
  });
  const item = data && data.item;

  const type = item?.type;

  const [
    getSuggestions,
    { loading: suggestionsLoading, data: suggestionsData }
  ] = useLazyQuery(GET_ITEM_SUGGESTIONS);

  const [submitWant] = useMutation(POST_WANT);
  const [registerEvent] = useMutation(POST_REGISTER_EVENT);
  const [downloadReleaseSnippets] = useMutation(POST_RELEASE_SNIPPET_ARCHIVE);

  if (error) addNotification({ ok: 0, message: error.toString() });

  const handleLoadImage = entry => setCurrentImage(entry);

  const handleDownloadSnippet = async e => {
    addNotification({
      ok: 1,
      message: "Please wait while generating your zip file"
    });
    setIsGeneratingZip(true);
    try {
      const { data } = await downloadReleaseSnippets({ variables: { id: item.id } });
      window.location = data.itemSnippetsArchive;
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
    setIsGeneratingZip(false);
  };

  useEffect(() => {
    if (!item) return;
    Store.update(s => { s.globalClass = `item ${item.type.replace("Item", "").toLowerCase()}`; });
    const query = { type, limit: 6, genres: item.data.genres, id: item.id };
    getSuggestions({ variables: query });
    registerEvent({ variables: { type: "pageView", data: { ref: item._id } } });
  }, [getSuggestions, item, type]);

  useEffect(() => {
    if (!item) window.scroll(0, 0);
    if (item) {
      setCurrentImage(null);
      try {
        document.title = item.descriptions.main;
        const forSales = item.listings.filter(l => l.stock.quantity);
        setForSales(forSales);
        setTimeout(() => {
          window.prerenderReady = true;
        }, 100);
      } catch (e) {
        console.log(e.toString());
      }
    }
  }, [props.match.params.id, item]);

  const handleAddToWantlist = () => {
    submitWant({ variables: { id: item.id } })
      .then(results =>
        addNotification({
          ok: 1,
          message: (
            <div className="addWantlist">
              <p className="title">Item {!item.wanted ? "added to" : "removed from"} wantlist</p>
              <Link to="/wantlist?stock=all">View my wantlist</Link>
            </div>
          )
        })
      )
      .catch(e => {
        addNotification({
          ok: 0,
          message: (
            <div className="addWantlist">
              <Link to="/login">{t("loginToYourAccount")}</Link>
            </div>
          )
        });
      });
  };

  if (item === undefined || loading) return <Loader style={{ marginTop: "var(--gutter)" }} />;
  if (item === null || (item.listings.length === 0 && !config.getProperty("information", "privatePrices")))
    return <FourOFour />;
  if (item && item.isForbiddenForSale)
    return (
      <div id="forbidden">
        <h1>This release is forbidden on the Common Ground marketplace</h1>
      </div>
    );
  const isRelease = item.type === "ReleaseItem";
  const isProduct = item.type === "ProductItem";
  const isBook = item.type === "BookItem";

  return (
    <div id="itemPage" className="itemContainer">
      <div id="item" className="item">
        <Meta
          description={item.descriptions.shop ? item.descriptions.shop.short || item.descriptions.shop.text : item.descriptions.main}
          title={item.descriptions.main}
          path={item.path}
          updated={item.created}
          image={item.data.images.length ? item.data.images[0].uri : null}
          listing={(forSales && forSales.length ? forSales : item.listings)[0]}
          id={item.id}
          brand={isRelease ? (item.data.labels.length ? item.data.labels[0].name : null) : item.data.manufacturer}
        />
        <div id="itemHeader" className="itemHeader">
          <h1>
            <span className="medium">
              {isRelease ? <ArtistNames artists={item.data.artists} /> : <>{item.data.manufacturer} </>}
            </span>
            <br />
            {item.data.title}
          </h1>
        </div>
        <div className="thumbnailAndImages">
          <Image
            ref={imgElement}
            id="mainImage"
            alt={item.data.title}
            config={config}
            entry={currentImage || (item.data.images.length && item.data.images[0])}
          />
          {item.data.images.length > 1 ? (
            <div className="images">
              {item.data.images.map((i, index) => (
                <img src={i.uri} onClick={e => handleLoadImage(i)} alt={item.data.title} key={index} />
              ))}
            </div>
          ) : null}
        </div>
        <div className="itemSpecs">
          {isRelease ? (
            <>
              <div className="itemInfo artists">
                <p>{t("artists")}</p>
                <p>
                  <ArtistNames artists={item.data.artists} />
                </p>
              </div>
              <div className="itemInfo labels">
                <p>{t("labels")}</p>
                <p>
                  <LabelNames labels={item.data.labels} />
                </p>
              </div>
              {item.data.labels.length > 0 ? (
                <div className="itemInfo catNo">
                  <p>{t("catNo")} </p> <p>{item.data.labels ? item.data.labels.filter(l => l.catno).map(l => <span key={l.catno}>{l.catno}{" "}</span>) : null}</p>
                </div>
              ) : null}
              <div className="itemInfo formats">
                <p>{t("formats")}</p>
                <p>
                  <FormatNames formats={item.data.formats} />
                </p>
              </div>
              <div className="itemInfo country">
                <p>{t("country")}</p>{" "}
                <p>
                  <Link to={`/catalogue?countries=${item.data.country}`}>{item.data.country}</Link>
                </p>
              </div>
              <div className="itemInfo releaseDate">
                <p>{t("releaseDate")}</p>{" "}
                <p> {item.data.releaseDate ? moment(item.data.releaseDate).format("ll") : ""}</p>
              </div>
              <div className="itemInfo genres">
                <p>{t("genres")}</p>
                <p>
                  {item.data.genres.map(g => (
                    <Link key={g} to={`/catalogue?genres=${g}`}>
                      {g}{" "}
                    </Link>
                  ))}
                </p>
              </div>
              <div className="itemInfo styles">
                <p>{t("styles")}</p>
                <p>
                  {item.data.styles.map(g => (
                    <Link key={g} to={`/catalogue?styles=${g}`}>
                      {g}{" "}
                    </Link>
                  ))}
                </p>
              </div>
            </>
          ) : isProduct ? (
            <>
              <div className="itemInfo title">
                <p>{t("title")}</p>
                <p>{item.data.title}</p>
              </div>
              <div className="itemInfo manufacturer">
                <p>{t("manufacturer")}</p>
                <p>
                  <Link to={`/products?manufacturers=${item.data.manufacturer}`}>{item.data.manufacturer}</Link>
                </p>
              </div>
              <div className="itemInfo catNo">
                <p>Catno</p>
                <p>{item.data.cat}</p>
              </div>
            </>
          ) : isBook ? <>
            <div className="itemInfo title">
              <p>{t("title")}</p>
              <p>{item.data.title}</p>
            </div>
            <div className="itemInfo title">
              <p>{t("subtitle")}</p>
              <p>{item.data.subtitle}</p>
            </div>
            <div className="itemInfo title">
              <p>{t("format")}</p>
              <p>{item.data.format}</p>
            </div>
            <div className="itemInfo title">
              <p>{t("publisher")}</p>
              <p>{item.data.publisher}</p>
            </div>
            <div className="itemInfo title">
              <p>{t("pages")}</p>
              <p>{item.data.pageCount}</p>
            </div>
            <div className="itemInfo title">
              <p>{t("language")}</p>
              <p>{item.data.language}</p>
            </div>
            <div className="itemInfo title">
              <p>{t("categories")}</p>
              <p>{item.data.categories.join(", ")}</p>
            </div>
            <div className="itemInfo releaseDate">
              <p>{t("publicationDate")}</p>{" "}
              <p> {item.data.publicationDate ? moment(item.data.publicationDate).format("ll") : ""}</p>
            </div>
          </> : null}
        </div>
        <div id="shopDescription" className="shopDescription">
          {item.descriptions.shop && item.descriptions.shop.short ? (
            <div className="shortDescription">
              <p>{item.descriptions.shop.short}</p>
            </div>
          ) : null}
          {item.descriptions.shop && item.descriptions.shop.html ? (
            <div className="fullDescription"
              dangerouslySetInnerHTML={{
                __html: item.descriptions.shop.html
              }}
            />
          ) : null}
        </div>
        {item.data.videos ? <div className="videos">
          {item.data.videos.map((v, i) => <iframe key={i} width="100%" height="200px" src={v.uri.replace("watch?v=", "embed/")} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />)}
        </div> : null}
        <div className="buyAndWantlist">
          {item.listings ? item.listings.map(l => <BuyButton key={l.id} listing={l} forSales={forSales} />) : null}
          <section className="wantlistAndButton">
            <button className="addToWantlist primary" type="button" onClick={handleAddToWantlist}>
              <span>{item.wanted ? t("removeFromWantlist") : t("addToWantlist")}</span>
              {item.wanted ? <Wantlist active /> : <Wantlist />}
            </button>
            {isRelease && session && config.getProperty("information", "snippetDownload") ? (
              <button
                onClick={handleDownloadSnippet}
                className="snippetDownload dotted primary"
                disabled={isGeneratingZip}>
                {isGeneratingZip ? <Loader /> : "Download audio"}
              </button>
            ) : null}
            {session && item.data.assetLink ? (
              <a target="_tab" href={item.data.assetLink}>
                <button className={"addToBasket dotted primary"}>Download assets</button>
              </a>
            ) : null}
          </section>
        </div>
        {isRelease ? (
          <div id="tracklist" className="tracklist">
            {item.data.tracklist
              .filter(t => t.type_ === "track")
              .map((t, index) => (
                <PlayerButtonDetailed
                  key={`${t.position}-${index}`}
                  track={t}
                  release={item}
                />
              ))}
          </div>
        ) : null}
        <div id="suggestions" className="suggestions">
          {!suggestionsLoading && suggestionsData?.suggestions?.length ? (
            <>
              <h2>{t("suggestionsHeader")}:</h2>
              <div id="suggestionEntries">
                {suggestionsData.suggestions.map(s => (
                  <Tile key={s.id} entry={s} />
                ))}
              </div>
            </>
          ) : (
            <>{suggestionsData?.suggestions?.length === 0 ? null : <Loader withMargins />}</>
          )}
        </div>
      </div>
    </div>
  );
};

export function ArtistNames (props) {
  return (
    <>
      {props.artists.map((a, index) => (
        <span key={index + a.name}>
          {a.id ? <Link to={`/catalogue?artists=${a.id}`}>{a.anv || a.name} </Link> : `${a.anv || a.name}`}
        </span>
      ))}
    </>
  );
}

export function LabelNames (props) {
  return (
    <>
      {props.labels.map((l, index) => (
        <span key={index + l.name}>{l.id ? <Link to={`/catalogue?labels=${l.id}`}>{l.name} </Link> : `${l.name}`}</span>
      ))}
    </>
  );
}

export function FormatNames (props) {
  return (
    <>
      {props.formats.map((f, i) => (
        <span key={f.name + i}>
          <span>
            {f.qty}x {f.name}
          </span>
          {f.descriptions
            .filter(d => !!d)
            .map(d => (
              <span key={d}> {d}</span>
            ))}
          <span>{f.description}</span>
        </span>
      ))}
    </>
  );
}

export default Item;
