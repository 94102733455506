import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import URI from "urijs";
import { Store } from "../contexts/Store";
import Meta from "./global/meta";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PASSWORD_USER_SESSION, POST_USER_PWD_RESET } from "../graphql/queries";

export default function Login (props) {
  const token = new URI(props.location.pathname + props.location.search).search(true).token;
  const [redirect, setRedirect] = useState(null);
  const [resetError, setResetError] = useState(!token ? "A token is required" : null);
  const [userForReset, setUserForReset] = useState(null);
  const { addNotification } = Store.useState(s => s);

  const { data, error } = useQuery(GET_PASSWORD_USER_SESSION, { variables: { token } });
  const [updatePassword] = useMutation(POST_USER_PWD_RESET);

  useEffect(() => {
    window.prerenderReady = true;
    if (data && data.user) setUserForReset(data.user);
    else if (error) setResetError("The provided token was invalid");
  }, [token, data, error]);

  const handlePasswordReset = async e => {
    e.preventDefault();
    const password = e.target.password.value;
    if (password.length < 4) return addNotification({ message: "Password must be at least 4 characters long" });

    try {
      const { data } = await updatePassword({
        variables: { token, password: Buffer.from(password).toString("base64") }
      });
      addNotification({ ok: 1, message: data.userPasswordReset });
      setRedirect("/login");
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  if (redirect) return <Redirect to={redirect}></Redirect>;

  return (
    <div id="userPasswordReset">
      <Meta title="Password reset" description="Reset your password" noIndex={true}/>
      <div id="content">
        {resetError ? <p>{resetError}</p> : null}
        {userForReset ? (
          <>
            <h2>Enter a new password below:</h2>
            <form onSubmit={handlePasswordReset}>
              <label>
                Email address:
                <input
                  type="email"
                  readOnly
                  name="email"
                  required
                  defaultValue={userForReset ? userForReset.email : ""}></input>
              </label>
              <label>
                Password reset:
                <input type="password" placeholder="Your new password" name="password" required></input>
              </label>
              <button type="submit">Reset password</button>
            </form>
          </>
        ) : null}
      </div>
    </div>
  );
}
