import React from "react";
import { Play, Pause } from "../common/icons";
import { PlayerStore } from "../../contexts/Store";

export const PlayerButton = props => {
  const { playPauseTrack, currentTrackData, isPlaying, loadTracklist } = PlayerStore.useState(s => s);
  const buttonIsPlaying = isPlaying && currentTrackData && currentTrackData.track.uri === props.track.uri;

  const tracklist = props.release.data.tracklist.filter(t => t.uri);
  const position = tracklist.findIndex(t => t.uri === props.track.uri);

  const tracklistToLoad = {
    tracklist: tracklist.map(t => ({ track: t, release: props.release })),
    playOffset: position
  };

  return (
    <button
      key={props.track.position}
      onClick={e => (buttonIsPlaying ? playPauseTrack() : loadTracklist(tracklistToLoad))}
      className="player">
      {buttonIsPlaying ? <Pause /> : <Play />}
    </button>
  );
};

export const PlayerButtonDetailed = ({ track, release }) => {
  const { playPauseTrack, currentTrackData, isPlaying, loadTracklist } = PlayerStore.useState(s => s);
  const buttonIsPlaying = isPlaying && currentTrackData && currentTrackData.track.uri === track.uri;

  const tracklist = release.data.tracklist.filter(t => t.uri);
  const position = tracklist.findIndex(t => t.uri === track.uri);

  const tracklistToLoad = {
    tracklist: tracklist.map(t => ({ track: t, release })),
    playOffset: position
  };

  return (
    <div
      key={track.position}
      onClick={e => (buttonIsPlaying ? playPauseTrack() : loadTracklist(tracklistToLoad))}
      className={`track ${buttonIsPlaying ? "playing " : ""}${track.uri ? "playable " : ""}`}>
      <button disabled={!track.uri} className="play">
        {buttonIsPlaying ? <Pause /> : <Play />}
      </button>

      <p className="position">
        <span>{track.position}</span>
      </p>
      <p className="description">
        <span>
          {track.artists.length ? (track.artists[0].anv || track.artists[0].name) + " - " : null}
          {track.title}
        </span>
      </p>
      <p className="duration">
        <span>{track.duration}</span>
      </p>
    </div>
  );
};
