import React from "react";
import { round } from "@common-ground-io/common-assets/helpers/utils";
import { Store } from "../../contexts/Store";

export default function Price (props) {
  const config = Store.useState(s => s.config);
  const symbol = props.currencySymbol || config.currencySymbol;
  const position = props.position || (config ? config.getProperty("information", "currencySymbolPosition") : "before");

  return (
    <span className={`price ${props.className || ""}`}>
      {position === "before" ? (
        <>
          {symbol}
          {round(props.value)}
        </>
      ) : (
        <>
          {round(props.value)}
          {symbol}
        </>
      )}
    </span>
  );
}
