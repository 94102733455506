import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Navbar from "./components/common/navbar";
import Landing from "./components/landing";
import Footer from "./components/global/footer";
import Header from "./components/header";
import Page from "./components/page";
import Login from "./components/login";
import Catalogue from "./components/catalogue";
import PasswordReset from "./components/passwordReset";
import Shipping from "./components/shipping";
import Legal from "./components/legal";
import Home from "./components/home";
import Player from "./components/global/player";
import ThankYou from "./components/thankyou";
import DevHeader from "./components/devHeader";
import Orders from "./components/orders/orders";
import Order from "./components/orders/single";
import CookiesComponent from "./components/global/cookies";
import Articles from "./components/articles";
import Item from "./components/item";
import Loader from "./components/common/loader";
import Artists from "./pages/artists";
import Labels from "./pages/labels";
import Maintenance from "./pages/maintenance";
import ThemePreview from "./pages/themePreview";
import Collection from "./pages/collection";
import Unsubscribe from "./pages/unsubscribe";
import Verify from "./pages/verify";
import "./styles/scss/app.scss";
import "./i18n";

import { AppContext } from "./contexts/AppContext";
import PlayerContext from "./contexts/PlayerContext";
import SessionContext from "./contexts/SessionContext";
import ConfigContext from "./contexts/ConfigContext";
import CheckoutContext from "./contexts/CheckoutContext";
import useAnalytics from "./hooks/useAnalytics";
import { Store } from "./contexts/Store";

const { overwrite } = require("country-list");
overwrite([{ code: "GB", name: "United Kingdom" }]);

const Analytics = () => {
  useAnalytics();
  return null;
};

const App = ({ apolloClient }) => {
  console.log("Starting app with config:", process.env.REACT_APP_BUILD_ENV);

  return (
    <Suspense fallback={() => <Loader style={{ margin: "20%" }} />}>
      <ConfigContext />
      <CheckoutContext />
      <AppContext apolloClient={apolloClient}>
        <SessionContext />
        <Maintenance>
          <PlayerContext />
          <AppClass>
            <Router>
              <ThemePreview />
              <Analytics />
              <NotificationContainer />
              {process.env.NODE_ENV !== "production" || process.env.REACT_APP_BUILD_ENV === "staging" ? (
                <DevHeader />
              ) : null}
              <Header />
              <Navbar />
              <Player />
              <div id="contentWrapper">
                <CookiesComponent />
                <Switch>
                  <Route path="/" exact component={Landing} />
                  <Route path={["/catalogue", "/preorder", "/preorders"]} exact component={Catalogue} />
                  <Route path={["/products", "/merchandise"]} exact component={Catalogue} />
                  <Route
                    path={["/item/:id/:title?", "/:title/release/:id/", "/release/:id/:title?", "/product/:id/:title?", "/book/:id/:title"]}
                    component={Item}
                  />
                  <Route path="/login" component={Login} />
                  <Route path="/home/:step?" component={Home} />
                  <Route path="/catalogue/artists" component={Artists} />
                  <Route path="/catalogue/labels" component={Labels} />
                  <Route path="/shipping" component={Shipping} />
                  <Route path="/legal" component={Legal} />
                  <Route path="/thankyou" component={ThankYou} />
                  <Route path="/unsubscribe" component={Unsubscribe} />
                  <Route path="/verify" component={Verify} />
                  <Route path="/orders" component={Orders} />
                  <Route path="/order/:id" component={Order} />
                  <Route path="/password/reset" component={PasswordReset} />
                  <Route path="/wantlist" component={Catalogue} />
                  <Route path="/articles" component={Articles} />
                  <Route path="/collection/:id/:handle" component={Collection} />
                  <Route path="/collection/:handle" component={Collection} />
                  <Route path="/:handle" component={Page} />
                </Switch>
              </div>
              <Footer />
            </Router>
          </AppClass>
        </Maintenance>
      </AppContext>
    </Suspense>
  );
};

const AppClass = ({ children }) => {
  const globalClass = Store.useState(s => s.globalClass);

  return <div id="app" className={globalClass}>{children}</div>;
};

export default App;
