import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_THEME } from "../graphql/queries";
import URI from "urijs";
import Cookies from "js-cookie";
import { Store } from "../contexts/Store";

const ThemePreview = () => {
  const location = useLocation();
  const history = useHistory();
  const search = new URI(location.pathname + location.search).search(true);

  const existingElement = document.querySelector("style[name=\"customCss\"]");

  let themeIdFromLocalStorage;
  try {
    themeIdFromLocalStorage = Cookies.get("theme");
  } catch (e) { console.log(e); }

  const [theme, setTheme] = useState(undefined);

  const [getTheme, { data }] = useLazyQuery(GET_THEME);

  useEffect(() => {
    if (search.theme || themeIdFromLocalStorage) getTheme({ variables: { id: search.theme || themeIdFromLocalStorage } });
  }, [search.theme, themeIdFromLocalStorage, getTheme]);

  useEffect(() => {
    if (data) {
      setTheme(data.theme);
      Store.update(s => {
        s.previewTheme = data.theme;
      });
      Cookies.set("theme", data.theme._id, { expires: 14 });
    }
  }, [data]);

  const updateStyle = useCallback(
    css => {
      if (existingElement) existingElement.innerHTML = css;
      else {
        const style = document.createElement("style");
        style.setAttribute("name", "customCss");
        document.head.appendChild(style);
        style.innerHTML = css;
      }
    },
    [existingElement]
  );

  useEffect(() => {
    if (theme) updateStyle(theme.css);
  }, [theme, updateStyle]);

  const handleClearPreview = () => {
    Store.update(s => {
      s.previewTheme = null;
    });
    Cookies.remove("theme");
    setTheme(undefined);
    history.push("/");
    window.location.reload();
  };

  if (!theme) return null;

  return (
    <div id="theme">
      <h2>Previewing theme: {theme.title}</h2>
      <button onClick={handleClearPreview} type="button">
        Stop previewing
      </button>
    </div>
  );
};

export default ThemePreview;
