import React from "react";
import ReactSelect from "react-select";

export default function Select (props) {
  return (
    <ReactSelect
      menuIsOpen={props.menuIsOpen}
      className={`select ${props.className || ""}`}
      id={props.id}
      name={props.name}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      placeholder={props.placeholder}
      classNamePrefix="select"
      isDisabled={props.readOnly || props.readonly}
      required={props.required}
    />
  );
}
