import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Store } from "../contexts/Store";
import Meta from "./global/meta";
import URI from "urijs";

export default function ThankYou (props) {
  const { t, session } = Store.useState(s => s);
  const uri = new URI(props.location.pathname + props.location.search);
  const args = uri.search(true);
  const orderId = args && args.order;

  useEffect(() => {
    Store.update(s => { s.globalClass = "thankYou"; });
  }, []);

  return (
    <div id="thankyou">
      <Meta title="Thank you" description="Thanks for your order" noIndex={true}/>
      <div className="content">
        <h1>{t("thanksForYourOrder")}</h1>
        <h2>{t("aConfirmationWasSent")}</h2>
        {orderId ? <p>Order ID: #{orderId}</p> : null }

        {session && orderId ? <Link className="orderLink" to={`/order/${orderId}`}>
          <button>{t("viewMyOrder")}</button></Link> : null}

        <Link className="homeLink" to="/">
          <button>{t("home")}</button>
        </Link>
      </div>
    </div>
  );
}
