import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import URI from "urijs";
import Slider, { createSliderWithTooltip } from "rc-slider";
import Price from "./common/price";
import "rc-slider/assets/index.css";
import { Tile, List } from "./common/views";
import Checkbox from "./common/checkbox";
import Meta from "./global/meta";
import Loader from "./common/loader";
import { useLazyQuery } from "@apollo/client";
import { GET_INVENTORY } from "../graphql/queries";
import { Store } from "../contexts/Store";
import Cookies from "js-cookie";

const SliderWithTooltip = createSliderWithTooltip(Slider);

const Catalogue = props => {
  const location = useLocation();
  const { config, t, addNotification, isTabletOrMobile } = Store.useState(s => s);
  const currentPath = location.pathname + location.search;
  const currentUri = new URI(props.location.pathname + props.location.search);
  const isWantlist = currentPath.includes("/wantlist");
  const isProduct = currentPath.includes("/products");
  const [viewType, setViewType] = useState(Cookies.get("viewType") || "tile");
  const [filtersHidden, setFiltersHidden] = useState(isTabletOrMobile);
  const [page, setPage] = useState(undefined);

  const [getInventory, { loading: inventoryLoading, data: inventoryData }] = useLazyQuery(GET_INVENTORY, {
    fetchPolicy: isWantlist ? "no-cache" : "cache-first"
  });

  useEffect(() => {
    Store.update(s => { s.globalClass = "catalogue"; });
    if (inventoryData && inventoryData.inventory) setPage(inventoryData.inventory);
  }, [inventoryData]);

  const generateFilters = useCallback(() => {
    const searchQuery = new URI(location.pathname + location.search.replace(/\/$/, "")).normalize().search(true);
    const initialFiltersData = {
      styles: [],
      artists: [],
      genres: [],
      labels: [],
      years: [],
      formats: [],
      mediums: [],
      countries: [],
      manufacturers: [],
      types: [],
      maxPrice: null,
      categories: [],
      stock: isProduct ? "all" : "instock",
      from: "",
      page: 1
    };
    if (searchQuery.styles) {
      initialFiltersData.styles = Array.isArray(searchQuery.styles) ? searchQuery.styles : [searchQuery.styles];
    }
    initialFiltersData.sort = searchQuery.sort || "added";

    const order = parseInt(searchQuery.order);
    initialFiltersData.order = !isNaN(order) ? order : -1;

    if (searchQuery.genres) {
      initialFiltersData.genres = Array.isArray(searchQuery.genres) ? searchQuery.genres : [searchQuery.genres];
    }
    if (searchQuery.labels) {
      initialFiltersData.labels = (Array.isArray(searchQuery.labels) ? searchQuery.labels : [searchQuery.labels]
      ).map(id => parseInt(id));
    }
    if (searchQuery.years) {
      initialFiltersData.years = (Array.isArray(searchQuery.years) ? searchQuery.years : [searchQuery.years]).map(id =>
        parseInt(id)
      );
    }
    if (searchQuery.artists) {
      initialFiltersData.artists = (Array.isArray(searchQuery.artists) ? searchQuery.artists : [searchQuery.artists]
      ).map(id => parseInt(id));
    }
    if (searchQuery.formats) {
      initialFiltersData.formats = Array.isArray(searchQuery.formats) ? searchQuery.formats : [searchQuery.formats];
    }
    if (searchQuery.mediums) {
      initialFiltersData.mediums = Array.isArray(searchQuery.mediums) ? searchQuery.mediums : [searchQuery.mediums];
    }
    if (searchQuery.manufacturers)
      initialFiltersData.manufacturers = Array.isArray(searchQuery.manufacturers) ? searchQuery.manufacturers : [searchQuery.manufacturers];
    if (searchQuery.types)
      initialFiltersData.types = Array.isArray(searchQuery.types) ? searchQuery.types : [searchQuery.types];
    if (searchQuery.from) initialFiltersData.from = searchQuery.from;
    if (location.pathname.includes("preorder")) initialFiltersData.preorder = true;
    if (searchQuery.countries) initialFiltersData.countries = searchQuery.countries;
    if (searchQuery.categories) initialFiltersData.categories = searchQuery.categories;
    if (searchQuery.page) initialFiltersData.page = parseInt(searchQuery.page) || 1;
    if (searchQuery.stock) initialFiltersData.stock = searchQuery.stock;
    if (searchQuery.condition) initialFiltersData.condition = searchQuery.condition;
    if (searchQuery.maxPrice) initialFiltersData.maxPrice = searchQuery.maxPrice;
    initialFiltersData.limit = parseInt(searchQuery.limit || Cookies.get("catalogue.limit") || 40);
    return initialFiltersData;
  }, [location.search, location.pathname, isProduct]);

  const initialFilters = generateFilters();

  const handleCheckboxChange = e => {
    if (page.isFetching) return;

    if (e.target.name === "showSoldOut") currentUri.setSearch({ stock: e.target.checked ? "all" : "instock" });
    else if (e.target.name === "showSecondHand")
      currentUri.setSearch({ condition: e.target.checked ? "secondhand" : undefined });

    currentUri.removeQuery("page");
    props.history.push({ pathname: currentUri.pathname(), search: currentUri.search() });
  };

  const handleMaxPriceChange = v => {
    if (v === 50) currentUri.removeSearch("maxPrice");
    else currentUri.setSearch({ maxPrice: v });
    currentUri.removeQuery("page");
    props.history.push({ pathname: currentUri.pathname(), search: currentUri.search() });
  };

  const handleFilterCheckboxChange = e => {
    const hasFilter = currentUri.hasQuery(e.target.name, e.target.value, true);
    currentUri.removeQuery("page");
    if (hasFilter) currentUri.removeQuery({ [e.target.name]: e.target.value });
    else currentUri.addSearch(e.target.name, e.target.value);
    props.history.push({ pathname: currentUri.pathname(), search: currentUri.search() });
  };

  const handlePerPageChange = amount => {
    Cookies.set("catalogue.limit", amount);
    currentUri.setSearch("limit", amount);
    props.history.push({ pathname: currentUri.pathname(), search: currentUri.search() });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    try {
      const filters = generateFilters();
      const query = {
        ...filters,
        type: !isProduct ? "release" : "product",
        limit: filters.limit,
        maxPrice: parseInt(filters.maxPrice),
        applyDataFilters: true,
        wantlist: isWantlist
      };
      getInventory({ variables: query });
      setFiltersHidden(true);
      setTimeout(function () {
        window.prerenderReady = true;
      }, 100);
    } catch (e) {
      addNotification({ ok: 0, message: e.data });
    }
  }, [addNotification, isProduct, isWantlist, generateFilters, getInventory, location.search]);

  const toggleFilters = () => {
    setFiltersHidden(!filtersHidden);
  };

  const hasAnyFilters = () => !!location.search;

  const filtersToShow = isProduct ? [
    { type: "categories", name: t("categories"), id: "_id", showId: "_id", layout: "row" },
    { type: "manufacturers", name: "Manufacturers", id: "_id", showId: "_id", layout: "row" },
    { type: "types", name: "Product Types", id: "_id", showId: "_id", layout: "row" }
  ] : [
    { type: "categories", name: t("categories"), id: "_id", showId: "_id", layout: "row" },
    { type: "genres", name: t("genres"), id: "_id", showId: "_id", layout: "column" },
    { type: "styles", name: t("styles"), id: "_id", showId: "_id", layout: "column" },
    { type: "mediums", name: t("formats"), id: "_id", showId: "_id", layout: "column" },
    { type: "formats", name: t("formatDescription"), id: "_id", showId: "_id", layout: "column" },
    { type: "labels", name: t("labels"), id: "id", showId: "_id", layout: "row" },
    { type: "artists", name: t("artists"), id: "id", showId: "_id", layout: "row" },
    { type: "years", name: t("years"), id: "_id", showId: "_id", layout: "column" },
    { type: "countries", name: t("countries"), id: "_id", showId: "_id", layout: "column" }
  ];

  const rangeMarks = {
    1: <Price value={1} />,
    10: <p></p>,
    20: <p></p>,
    30: <p></p>,
    40: <p></p>,
    50: <p>Inf.</p>
  };

  const primary = config.eshop.theme.selected?.colours?.primary || config.getProperty("designs", "primary");
  const secondary = "var(--cg-grey)";
  const railStyle = { backgroundColor: secondary, height: 4, color: primary };
  const trackStyle = { backgroundColor: primary, height: 4, color: primary };
  const handleStyle = {
    borderColor: secondary,
    height: 15,
    color: primary,
    width: 15,
    backgroundColor: primary
  };

  if (!page) return <Loader style={{ marginTop: "calc(var(--cg-gutter-md)*2)" }} />;

  const catalogueName = isProduct ? t("products") : isWantlist ? "Wantlist" : t("catalogue");

  return (
    <div id="catalogue">
      <Meta description="Catalogue" title="Catalogue" path="/catalogue" noIndex={true}></Meta>
      {page && page.pagination ? (
        <Header
          t={t}
          inventoryLoading={inventoryLoading}
          isTabletOrMobile={isTabletOrMobile}
          pagination={page.pagination}
          currentUri={currentUri}
          toggleFilters={toggleFilters}
          initialFilters={initialFilters}
          setViewType={setViewType}
          catalogueName={catalogueName}
          handlePerPageChange={handlePerPageChange}
        />
      ) : null}
      <div id="filtersAndContent">
        <div id="filters" className={`filters ${isTabletOrMobile && filtersHidden ? "hidden" : ""}`}>
          {page && page.filters ? (
            <>
              {hasAnyFilters() && page ? <ClearFilter t={t} currentUri={currentUri} /> : null}
              {filtersToShow
                .filter(f => page.filters[f.type].length)
                .map((f, i) => (
                  <Filters
                    key={i}
                    t={t}
                    isTabletOrMobile={isTabletOrMobile}
                    onChange={handleFilterCheckboxChange}
                    initialFilter={initialFilters[f.type]}
                    currentUri={currentUri}
                    filter={f}
                    entries={page.filters[f.type]}
                  />
                ))}
              <div className="filterSection row priceRange">
                <div className="header">
                  <h2>{t("price")}</h2>
                </div>
                <div className="priceRange">
                  <SliderWithTooltip
                    className="priceRange"
                    tipFormatter={value => <>{value !== 50 ? <Price value={value} /> : "No max"}</>}
                    marks={rangeMarks}
                    min={1}
                    dotStyle={{ borderColor: secondary }}
                    activeDotStyle={{ borderColor: primary }}
                    max={50}
                    railStyle={railStyle}
                    trackStyle={trackStyle}
                    handleStyle={handleStyle}
                    onAfterChange={handleMaxPriceChange}
                    defaultValue={initialFilters.maxPrice ? parseInt(initialFilters.maxPrice) : 50}
                    step={1}
                  />
                </div>
                <hr />
              </div>
              <div className="filterSection column showSoldOut">
                <div className="filterEntries">
                  <div className={"showSoldOut"}>
                    <label>
                      <Checkbox
                        name="showSoldOut"
                        onChange={e => handleCheckboxChange(e)}
                        checked={initialFilters.stock === "all"}
                      />
                      {t("showSoldOut")}
                    </label>
                  </div>
                  <div className={"showSecondHand"}>
                    <label>
                      <Checkbox
                        name="showSecondHand"
                        onChange={e => handleCheckboxChange(e)}
                        checked={initialFilters.condition === "secondhand"}
                      />
                      {t("showSecondHand")}
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        {page && page.items && !inventoryLoading ? (
          <div id="catalogueEntries">
            <div id="catalogueEntriesContent" className={`${viewType}Type`}>
              {page.items && page.items.length ? (
                page.items.map(e => (
                  <React.Fragment key={e._id}>
                    {viewType === "tile" ? (
                      <Tile defaultImage={config.getProperty("designs", "defaultImage")} entry={e} />
                    ) : null}
                    {viewType === "list" ? (
                      <List defaultImage={config.getProperty("designs", "defaultImage")} entry={e} />
                    ) : null}
                  </React.Fragment>
                ))
              ) : (
                <p>No results found</p>
              )}
            </div>
          </div>
        ) : (
          <DummyEntries />
        )}
      </div>
      {page && page.pagination ? (
        <Header
          t={t}
          pagination={page.pagination}
          isTabletOrMobile={isTabletOrMobile}
          currentUri={currentUri}
          toggleFilters={toggleFilters}
          initialFilters={initialFilters}
          setViewType={setViewType}
          catalogueName={catalogueName}
          handlePerPageChange={handlePerPageChange}
        />
      ) : null}
    </div>
  );
};

const DummyEntries = () => {
  const items = [];
  for (let i = 0; i < 24; i++) {
    items.push(<div key={i} className="item animatedBackground" />);
  }
  return (
    <div className="playlist dummy">
      <div className="entries">{items}</div>
    </div>
  );
};

const Filters = props => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { entries, filter, initialFilter, currentUri, isTabletOrMobile, t } = props;

  const limit = isTabletOrMobile ? 0 : 8;
  return (
    <div className={`filterSection ${filter.layout} ${filter.type}`}>
      <div className="header">
        <h2>{filter.name}</h2>
        {entries.length > limit ? (
          <button onClick={e => setIsCollapsed(!isCollapsed)} type="button">
            {!isCollapsed ? (
              <>
                {t("more")} <i className="cg-icon-filter-more" />
              </>
            ) : (
              <>
                {t("less")} <i className="cg-icon-filter-less" />
              </>
            )}
          </button>
        ) : null}
      </div>
      <div className="filterEntries">
        {entries
          .filter((e, i) => (!isCollapsed ? i < limit : 1))
          .map((g, i) => (
            <div key={g._id} className={`filter ${initialFilter.includes(g[filter.id]) ? "selected" : ""}`}>
              {filter.layout === "row" ? (
                <Link
                  to={
                    initialFilter.includes(g[filter.id]) ? currentUri
                      .clone()
                      .removeSearch({
                        [filter.type]: [g[filter.id]]
                      })
                      .removeSearch("page")
                      .toString() : currentUri
                      .clone()
                      .addSearch({
                        [filter.type]: g[filter.id]
                      })
                      .removeSearch("page")
                      .toString()
                  }>
                  {g[filter.showId]}
                </Link>
              ) : null}
              {filter.layout === "column" ? (
                <label className="checkbox">
                  <Checkbox
                    onChange={props.onChange}
                    name={filter.type}
                    value={g[filter.id]}
                    checked={initialFilter.includes(g[filter.id])}
                  />
                  {g[filter.showId]}
                </label>
              ) : null}
            </div>
          ))}
      </div>
      <hr />
    </div>
  );
};

const ClearFilter = props => {
  return (
    <div id="clearFilters" className="filterSection row">
      <Link to={new URI(props.currentUri).search("").toString()}>
        <button className="clearContent">
          <span>{props.t("clearAllFilters")}</span>
          <i className="cg-icon-filter-close" />
        </button>
      </Link>
      <hr />
    </div>
  );
};

const Header = ({
  t,
  inventoryLoading,
  pagination,
  catalogueName,
  setViewType,
  currentUri,
  toggleFilters,
  isTabletOrMobile,
  initialFilters,
  handlePerPageChange
}) => {
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: initialFilters.page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: initialFilters.page - 1 });

  const limits = [40, 80, 120];

  const switchView = t => {
    Cookies.set("viewType", t, { expires: 365 });
    setViewType(t);
  };

  if (isTabletOrMobile) {
    return (
      <div className="pagination mobile">
        <div className="top">
          <div id="viewSwitch" className="viewSwitch">
            <button type="button" onClick={e => switchView("tile")}>
              <i className="cg-icon-view-grid-mobile" />
            </button>
            <button type="button" onClick={e => switchView("list")}>
              <i className="cg-icon-view-list-mobile" />
            </button>
            <button type="button" onClick={e => toggleFilters()}>
              <i className="cg-icon-filter-mobile" />
            </button>
          </div>
          {inventoryLoading ? <Loader /> : null}
          <ul className="naviguator">
            <li
              className={pagination.page <= 1 ? "disabled" : ""}
              onClick={e => pagination.page <= 1 && e.preventDefault()}>
              <Link to={prevUri.toString()} className="previous">
                <span className="paginationWords">{t("previous")}</span>
              </Link>
            </li>
            <li className={pagination.page >= pagination.pages ? "disabled" : ""}>
              <Link
                to={nextUri.toString()}
                className="next"
                onClick={e => pagination.page >= pagination.pages && e.preventDefault()}>
                <span className="paginationWords">{t("next")}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom">
          <LinkWithSubmenu
            currentUri={currentUri}
            items={[
              {
                active: initialFilters.order === -1 && initialFilters.sort === "price",
                query: { sort: "price", order: -1 },
                name: t("priceDesc")
              },
              {
                active: initialFilters.order === 1 && initialFilters.sort === "price",
                query: { sort: "price", order: 1 },
                name: t("priceAsc")
              },
              {
                active: initialFilters.order === -1 && initialFilters.sort === "added",
                query: { sort: "added", order: -1 },
                name: t("addedDesc")
              },
              {
                active: initialFilters.order === 1 && initialFilters.sort === "added",
                query: { sort: "added", order: 1 },
                name: t("addedAsc")
              }
            ]}
            name={t("sortBy")}
          />
          <p className="pageCount">
            {t("page")} {pagination.page} {t("of")} {pagination.pages}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="pagination">
      <div className="left">
        <div className="title">
          <h1>{catalogueName}</h1>
        </div>
        {inventoryLoading ? <Loader /> : null}
      </div>
      <div className="right">
        <LinkWithSubmenu
          currentUri={currentUri}
          items={[
            {
              active: initialFilters.order === -1 && initialFilters.sort === "price",
              query: { sort: "price", order: -1 },
              name: t("priceDesc")
            },
            {
              active: initialFilters.order === 1 && initialFilters.sort === "price",
              query: { sort: "price", order: 1 },
              name: t("priceAsc")
            },
            {
              active: initialFilters.order === -1 && initialFilters.sort === "added",
              query: { sort: "added", order: -1 },
              name: t("addedDesc")
            },
            {
              active: initialFilters.order === 1 && initialFilters.sort === "added",
              query: { sort: "added", order: 1 },
              name: t("addedAsc")
            }
          ]}
          name={t("sortBy")}
        />
        <div className="page">
          <p className="pageCount">
            {t("page")} {pagination.page} {t("of")} {pagination.pages}
          </p>
          <div className="perPage">
            {limits.map((p, i) => (
              <React.Fragment key={p}>
                <button
                  className={initialFilters.limit === p ? "active" : ""}
                  type="button"
                  onClick={e => handlePerPageChange(p)}>
                  {p}
                </button>

                {i < limits.length - 1 ? <span>|</span> : ""}
              </React.Fragment>
            ))}
          </div>
          <div id="viewSwitch">
            <button type="button" onClick={e => switchView("tile")}>
              <i className="cg-icon-view-grid" />
            </button>
            <button type="button" onClick={e => switchView("list")}>
              <i className="cg-icon-view-list" />
            </button>
          </div>
        </div>
        <ul className="naviguator">
          {pagination.page > 1 && (
            <li>
              <Link to={prevUri.toString()} className="previous">
                <span className="paginationWords">{t("previous")}</span>
              </Link>
            </li>
          )}
          {pagination.page < pagination.pages && (
            <li>
              <Link to={nextUri.toString()} className="next">
                <span className="paginationWords">{t("next")}</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const LinkWithSubmenu = ({ items, name, main, currentUri }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = event => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.addEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className="sorter" ref={wrapperRef}>
      <button onClick={e => setIsOpen(!isOpen)} className="navItem">
        {name}
        <i className="cg-icon-catalogue-down" />
      </button>
      {isOpen ? (
        <div className="menu">
          <ul>
            {items.map(i => (
              <li key={i.name} className={i.active ? "active" : ""}>
                <Link
                  onClick={e => setIsOpen(!isOpen)}
                  to={new URI(currentUri).setSearch({ ...i.query, page: 1 }).toString()}>
                  {i.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export { LinkWithSubmenu };
export default Catalogue;
