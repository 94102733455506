import React from "react";

export function Pause (props) {
  return <i className="cg-icon-pause" />;
}

export function Play (props) {
  return <i className="cg-icon-play" />;
}

export function PlayerPause (props) {
  return <i className="cg-icon-player-pause" />;
}

export function PlayerPlay (props) {
  return <i className="cg-icon-player-play" />;
}

export function Less (props) {
  return <i className="cg-icon-basket-remove" />;
}

export function Plus (props) {
  return <i className="cg-icon-basket-add" />;
}

export function BasketAdd (props) {
  if (props.active) return <i className="cg-icon-basket-selected" />;
  return <i className="cg-icon-basket-cart" />;
}

export function BasketAddPreorder (props) {
  if (props.active) return <i className="cg-icon-basket-selected" />;
  return <i className="cg-icon-basket-cart-preorder" />;
}

export function Wantlist (props) {
  if (props.active) return <i className="cg-icon-wantlist-fill" />;
  return <i className="cg-icon-wantlist" />;
}

export function Close (props) {
  return <i className="cg-icon-burger-close" />;
}

export function four0FourDisc () {
  return (
    <img
      alt="404 background"
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NDQiIGhlaWdodD0iNDQ0IiB2aWV3Qm94PSIwIDAgNDQ0IDQ0NCI+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGZpbGw9IiNGN0Y3RjciIGQ9Ik0wIDIxOS45OTVDMCA5OC41MDMgOTguNTA1IDAgMjIwLjAxIDAgMzQxLjUwNCAwIDQ0MCA5OC41MDMgNDQwIDIxOS45OTUgNDQwIDM0MS40OTcgMzQxLjUwNSA0NDAgMjIwLjAxIDQ0MCA5OC41MDQgNDQwIDAgMzQxLjQ5NyAwIDIxOS45OTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgMikiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjRjdGN0Y3IiBzdHJva2U9IiNGN0Y3RjciIHN0cm9rZS13aWR0aD0iMi44MzQiIGQ9Ik0wIDIxOS45OTVDMCA5OC41MDMgOTguNTA1IDAgMjIwLjAxIDAgMzQxLjUwNCAwIDQ0MCA5OC41MDMgNDQwIDIxOS45OTUgNDQwIDM0MS40OTcgMzQxLjUwNSA0NDAgMjIwLjAxIDQ0MCA5OC41MDQgNDQwIDAgMzQxLjQ5NyAwIDIxOS45OTV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIi8+CiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRkZGRSIgZD0iTTEyMiAyMjAuNWMwLTUzLjg1MiA0My42NTgtOTcuNSA5Ny41MS05Ny41IDUzLjg0MiAwIDk3LjQ5IDQzLjY0OCA5Ny40OSA5Ny41UzI3My4zNTIgMzE4IDIxOS41MSAzMThjLTUzLjg1MiAwLTk3LjUxLTQzLjY0OC05Ny41MS05Ny41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIi8+CiAgICAgICAgPHBhdGggZmlsbD0iI0Y3RjdGNyIgZD0iTTIxMyAyMjEuNDk2YzAtMy4wMzkgMi40NzMtNS40OTYgNS41MDQtNS40OTYgMy4wMzkgMCA1LjQ5NiAyLjQ1NyA1LjQ5NiA1LjQ5NiAwIDMuMDM5LTIuNDU3IDUuNTA0LTUuNDk2IDUuNTA0LTMuMDMgMC01LjUwNC0yLjQ2NS01LjUwNC01LjUwNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMiAyKSIvPgogICAgICAgIDxwYXRoIHN0cm9rZT0iI0Y3RjdGNyIgc3Ryb2tlLXdpZHRoPSIyLjgzNCIgZD0iTTIxMyAyMjEuNDk2YzAtMy4wMzkgMi40NzMtNS40OTYgNS41MDQtNS40OTYgMy4wMzkgMCA1LjQ5NiAyLjQ1NyA1LjQ5NiA1LjQ5NiAwIDMuMDM5LTIuNDU3IDUuNTA0LTUuNDk2IDUuNTA0LTMuMDMgMC01LjUwNC0yLjQ2NS01LjUwNC01LjUwNHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgMikiLz4KICAgIDwvZz4KPC9zdmc+Cg=="
    />
  );
}

export const Prev = () => {
  return <i className="cg-icon-player-prev" />;
};

export const Next = () => {
  return <i className="cg-icon-player-next" />;
};
