import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Price from "../common/price";

export default function OrderTable ({ orders, t }) {
  return (
    <div id="orderTable">
      <div className="orderTableItem header">
        <p className="title">
          {t("yourOrders")}({orders.length})
        </p>
        <p className="origin">{t("origin")}</p>
        <p>Status</p>
        <p>{t("created")}</p>
        <p>{t("total")}</p>
        <p></p>
      </div>
      {orders.map((o, index) => (
        <div key={`${o.id}`} className={"orderTableItem"}>
          <>
            <div className="id">
              <Link to={`/order/${o.id}`}>#{o.id}</Link>
            </div>
            <div className="origin">
              <p>{o.origin}</p>
            </div>
            <div className="status">
              <p>{o.status}</p>
            </div>
            <div className="created">
              <p>{moment(o.created).format("ll")}</p>
            </div>
            <div className="total">
              <Price value={o.totals.grand} />
            </div>
            <div className="action">
              <Link to={`/order/${o.id}`}>{t("details")}</Link>
            </div>
          </>
        </div>
      ))}
    </div>
  );
}
