import React from "react";
import MenuBuilder from "../menus/menuBuilder";
import { Store } from "../../contexts/Store";

const Navbar = () => {
  const { config, session, checkout } = Store.useState(s => s);

  const menus = config.eshop?.menus;
  const customMenu = !!menus && menus.length && menus.find(m => m.location === "navbar");

  if (!customMenu) return null;

  return <MenuBuilder session={session} checkout={checkout} menu={customMenu} />;
};

export default Navbar;
