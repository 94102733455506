import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Store } from "../contexts/Store";
import Cookies from "js-cookie";

export default function Header () {
  const { config, addNotification, t, i18n, cookies } = Store.useState(s => s);

  const openingHours = config.getProperty("information", "openings");
  const dayIndex = moment().isoWeekday();
  const openings = openingHours ? openingHours[dayIndex - 1] : null;

  const openCookiePrefs = () => {
    cookies.openModal();
  };

  return (
    <div className="topHeader">
      <div className="container">
        <div id="left">
          {openings && openings.length ? (
            <p>
              {t("openToday")}:{" "}
              {openings.map((o, i) => (
                <span key={o.from}>
                  {moment(o.from, "HH:mm").format("HH:mm")} - {moment(o.to, "HH:mm").format("HH:mm")}
                  {i < openings.length - 1 ? " | " : null}
                </span>
              ))}
            </p>
          ) : (
            <p>{t("closedToday")}</p>
          )}
        </div>
        <div id="right">
          <div className="links">
            <button type="button" className="openCookies" onClick={openCookiePrefs}>Cookies</button>
            <p> | </p>
            <Link to="/shipping">{t("shipping")}</Link>
            <p> | </p>
            <Link to="/legal">{t("termsAndConditions")}</Link>
          </div>
          <LanguageSelector config={config} i18n={i18n} addNotification={addNotification} />
        </div>
      </div>
    </div >
  );
}

const LanguageSelector = ({ config, i18n, addNotification }) => {
  const currentLanguage = i18n.language;
  const languages = config.eshop.preferences.languages.whitelist;
  const [open, setOpen] = useState(false);
  const localRef = useRef();

  const handleClickOutside = event => {
    if (localRef.current && !localRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  }, []);

  const handleChangeLanguage = locale => {
    Cookies.set("locale", locale, { expires: 365 });
    moment.locale(locale);
    i18n.changeLanguage(locale, (_, newt) => {
      addNotification({ ok: 1, message: newt("languageUpdate") });
    });
  };

  useEffect(() => {
    if (languages.indexOf(currentLanguage) === -1) {
      if (languages.length) handleChangeLanguage(languages[0]);
      else handleChangeLanguage("en");
    }
  }, [config.eshop.preferences.languages.whitelist, currentLanguage]);

  if (languages.length < 2) return null;

  return (
    <div className="languageSelector" ref={localRef}>
      <div className="current">
        <button onClick={e => setOpen(!open)} className="current">
          {currentLanguage}
          <i className="cg-icon-dictionary" />
        </button>
      </div>
      {open ? <div className="entries">
        {languages.filter(l => l !== currentLanguage).map(l => <button key={l}
          className={"entry" + (i18n.language === l ? "selected" : "")}
          type="button"
          onClick={e => { setOpen(false); handleChangeLanguage(l); }}>
          {l}
        </button>)}
      </div> : null}
    </div>);
};
