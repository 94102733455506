import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ModalProvider } from "react-modal-hook";
import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Store } from "./contexts/Store";
import Cookies from "js-cookie";
import URI from "urijs";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        Cookies.remove("jwt");
        Store.update(s => {
          s.session = null;
        });
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

Sentry.init({
  environment: process.env.REACT_APP_BUILD_ENV,
  dsn: process.env.REACT_APP_SENTRY_ID,
  enabled: process.env.NODE_ENV === "production",
  integrations: []
});

const httpLink = createHttpLink({ uri: process.env.REACT_APP_APOLLO_URI });
const authLink = setContext((_, { headers = {} }) => {
  const jwt = Cookies.get("jwt");

  let origin;
  if (process.env.REACT_APP_BUILD_ENV === "production") {
    const host = URI(window.location.href).hostname();
    origin = host.replace("www.", "");
  } else if (process.env.NODE_ENV !== "production" || process.env.REACT_APP_BUILD_ENV === "staging") {
    const currentUri = URI(window.location.href);
    if (currentUri.search(true).config) Cookies.set("origin", currentUri.search(true).config, { expires: 365 });
    else if (!Cookies.get("origin")) Cookies.set("origin", "demo.common-ground.io");
    origin = Cookies.get("origin").replace("www.", "");
  }

  const apiKey = Cookies.get("x-api-key") || process.env.REACT_APP_API_GLOBAL_KEY;
  if (origin) headers["commonground-origin"] = origin;
  if (jwt) headers["commonground-jwt"] = jwt;
  if (apiKey) headers["x-api-key"] = apiKey;
  return { headers };
});

const cache = new InMemoryCache({
  typePolicies: {
    ItemLabel: { keyFields: ["catno", "id"] },
    ItemArtist: { keyFields: ["id", "name"] },
    Track: { keyFields: ["title", "position", "uri"] }
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: cache
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ApolloProvider client={apolloClient}>
    <ModalProvider>
      <App apolloClient={apolloClient} />
    </ModalProvider>
  </ApolloProvider>
);

serviceWorker.unregister();
