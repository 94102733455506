import React from "react";
import { Link } from "react-router-dom";
import { Tile } from "../common/views";
import URI from "urijs";

export default function Playlist ({ playlist, dummy }) {
  if (dummy) {
    const items = [];
    for (let i = 0; i < 6; i++) {
      items.push(<div key={i} className="item animatedBackground" />);
    }
    return (
      <div className="playlist dummy">
        <div className="title animatedBackground" />
        <div className="entries">{items}</div>
      </div>
    );
  }
  return (
    <div className="playlist">
      <h2>{playlist.link ? <Link to={new URI(playlist.link).resource()}>{playlist.title}</Link> : playlist.title}</h2>
      <div className="entries">
        {playlist.entries.map((e, i) => (
          <Tile key={i} entry={e.item} />
        ))}
      </div>
    </div>
  );
}
