import React from "react";

import { Store } from "../../contexts/Store";
import MenuBuilder from "../menus/menuBuilder";

export default function Footer () {
  const { config, session, checkout } = Store.useState(s => s);

  const menus = config.eshop?.menus;
  const customMenu = !!menus && menus.length && menus.find(m => m.location === "footer");

  if (customMenu && customMenu.entries.length > 0)
    return <MenuBuilder session={session} checkout={checkout} menu={customMenu} />;

  else return null;
}
