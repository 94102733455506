import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

export default function Loader (props) {
  return (
    <div className="loader" style={props.withMargins ? { marginTop: "5%" } : props.style || {}}>
      <BeatLoader size={props.size || 10} color={"var(--cg-primary)"} loading={true} />
    </div>
  );
}
