const getTranslatedOption = ({ t, option }) => {
  if (option.name === "Media Condition") return t("mediaCondition");
  else if (option.name === "Sleeve Condition") return t("sleeveCondition");
  else return option.name;
};

const findUrlForMedia = (media, format) => {
  if (!media || !media.formatArray) return null;
  else if (!format) return media.url;

  const foundFormat = media?.formatArray?.find(f => f.name === format);
  return foundFormat?.url || media.url;
};

export { getTranslatedOption, findUrlForMedia };
