import React from "react";
import { Store } from "../contexts/Store";
import Page from "./page";
import Collection from "../pages/collection";
import Catalogue from "./catalogue";
import URI from "urijs";

export default function Landing (props) {
  const config = Store.useState(s => s.config);

  const homepage = config.eshop?.preferences?.homepage;

  if (!homepage) return null;
  else if (homepage.type === "article")
    return <Page handle={homepage.handle} {...props} />;
  else if (homepage.type === "collection") {
    const id = new URI(homepage.handle).segment(1);
    return <Collection handle={homepage.handle} id={id} {...props} />;
  } else if (homepage.type === "catalogue")
    return <Catalogue {...props} />;

  return null;
}
