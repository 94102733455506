import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { POST_LIST_OPTIN } from "../../graphql/queries";

const Subscribe = ({ onSubmit, listRef, config, addNotification, t }) => {
  const [subscribeMessage, setSubscribeMessage] = useState(undefined);

  const [optin] = useMutation(POST_LIST_OPTIN);

  const handleAddToNewsletter = async e => {
    const email = e.target.email.value;
    e.preventDefault();
    try {
      const { data } = await optin({ variables: { email, listRef } });
      setSubscribeMessage(data.listOptin);
      addNotification({ ok: 1, message: data.listOptin });
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  return (
    <div className="newsletterSubscribe">
      {!subscribeMessage ? (
        <form onSubmit={handleAddToNewsletter}>
          <label>
            {t("enterEmail")}
            <input type="email" name="email" placeholder={t("placeholder")} required />
          </label>
          <button type="submit" className="primary">
            {t("submit")}
          </button>
        </form>
      ) : (
        <h3>{subscribeMessage}</h3>
      )}
    </div>
  );
};

const SubscribeBlock = ({ onSubmit, config, addNotification, t, listRef }) => {
  const [subscribeMessage, setSubscribeMessage] = useState(undefined);

  const [optin, { loading }] = useMutation(POST_LIST_OPTIN);

  const handleAddToNewsletter = async e => {
    const email = e.target.email.value;
    e.preventDefault();
    try {
      const { data } = await optin({ variables: { email, listRef } });
      setSubscribeMessage(data.listOptin);
      addNotification({ ok: 1, message: data.listOptin });
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  return (
    <div className="block subscribe">
      <form onSubmit={handleAddToNewsletter}>
        <h2>{t("subscribe")}</h2>
        {!subscribeMessage ? (
          <div className="content">
            <label>
              {t("enterEmail")}:
              <input type="email" name="email" placeholder={t("placeholder")} required />
            </label>
            <button type="submit" className="primary" disabled={subscribeMessage || loading}>
              {t("subscribe")}
            </button>
          </div>
        ) : (
          <h3>{subscribeMessage}</h3>
        )}
      </form>
    </div>
  );
};

export { SubscribeBlock };
export default Subscribe;
