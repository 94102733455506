import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import URI from "urijs";
import Meta from "../components/global/meta";
import Loader from "../components/common/loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useQuery } from "@apollo/client";
import { GET_LABELS } from "../graphql/queries";
import { Store } from "../contexts/Store";

const Label = props => {
  const config = Store.useState(s => s.config);
  const location = useLocation();
  const search = new URI(location.pathname + location.search).search(true);
  const currentLetter = search.letter || "a";

  const { loading, data } = useQuery(GET_LABELS, { variables: { letter: currentLetter } });
  const labels = data && data.labels;

  useEffect(() => {
    Store.update(s => { s.globalClass = "labels"; });
    setTimeout(() => {
      window.prerenderReady = true;
    }, 100);
  }, []);

  const letters = ["0-9"];
  for (let i = 0; i < 26; i++) letters.push(String.fromCharCode(97 + i));
  letters.push("~");

  return (
    <div id="labels">
      <div className="container">
        <Meta title="Labels" noIndex={true} />
        <div className="header">
          <h1>Labels</h1>
          <div className="abecedaire">
            {letters.map(l => (
              <Link
                key={l}
                className={`${l === currentLetter ? "active" : ""}`}
                to={new URI(location.pathname).setSearch({ letter: l }).toString()}>
                {l}
              </Link>
            ))}
          </div>
        </div>
        {!loading && labels ? (
          <div className="content">
            {labels.map(e => (
              <div key={e.id} className="entry">
                <Link className="name" to={`/catalogue?labels=${e.id}&stock=all`}>
                  <div className="img">
                    <LazyLoadImage
                      alt={e.name}
                      src={
                        e.images[0]?.uri ||
                        config.getProperty("designs", "missingImageUri") ||
                        "/img/default-release.png"
                      }
                    />
                  </div>{" "}
                </Link>
                <p>{e.name} </p>
              </div>
            ))}
          </div>
        ) : (
          <Loader style={{ marginTop: "var(--gutter)" }} />
        )}
      </div>
    </div>
  );
};

export default Label;
