import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Meta from "./meta";

export default function FourOFour () {
  useEffect(() => {
    const locationMeta = document.createElement("meta");
    locationMeta.httpEquiv = "prerender-status-code";
    locationMeta.content = "404";
    document.getElementsByTagName("head")[0].appendChild(locationMeta);
    setTimeout(function () {
      window.prerenderReady = true;
    }, 10);
  }, []);

  return (
    <div id="fourOFour">
      <Meta description="404" fourOFour={true} title="Not found"></Meta>
      <h1>
        Error 404 <br />
        Page Not Found
      </h1>
      <p>
        The page you’re looking for does not exist...
        <br />
        <Link to="/">Take me home</Link>
      </p>
    </div>
  );
}
