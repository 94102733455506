import { gql } from "@apollo/client";

const ITEM = `
  _id
  id
  type
  path
  wanted
  listings {
    id
    available
    preOrder
    stock {
      quantity
    }
    prices {
        beforeTaxes
        sale
        compare
    }
    onePerCustomer
    options {
        name
        value
    }
    comments
  }
  descriptions {
    main
    shop {
        text
        html
        short
    }
  }
  data {
    title
    images {
        uri
        alternative {
          uri
        }
    }
    assetLink
    weight
    genres
    styles
    manufacturer

    authors
    subtitle
    format
    publisher
    pageCount
    language
    categories
    publishedDate
    industryIdentifiers {
      type
      identifier
    }

    cat
    releaseDate
    tracklist {
      type_
      title
      artists {
        name
        id
        anv
      }
      uri
      duration
      position
    }
    formats {
      descriptions
      name
      qty
    }
    country
    artists {
      name
      anv
      id
    }
    labels {
      name
      id
      catno
    }
    videos {
      uri
    }
  }
`;

const PLAYLIST = `
  _id
  id
  title
  link
  entries {
    item {
      ${ITEM}
    }
    comments
  }
`;

const ARTICLE = `
  _id
  id
  seo {
    index
    description
  }
  published {
    date
  }
  content {
    rows {
      id
      layout
      options {
        fullWidth
        carousel
        backgroundColor
        noPadding
      }
      columns {
        id
        blocks {
          type
          id
          data
        }
      }
    }
  }
  thumbnail {
    title
    formatArray {
      name
      url
      width
      height
    }
  }
  homepage
  title
  tags
  handle
`;

const ARTIST = `
  id
  name
`;

const LABEL = `
  id
  name
  images {
    uri
  }
`;

const THEME = `
  _id
  id
  css
  stylesheet
  title
  description
  colours {
    primary
    secondary
    areas {
      name
      value
    }
    presets {
      name
      value
    }
  }
  miscs {
    name
    value
  }
  spacings {
    desktop {
      name
      value
    }
    mobile {
      name
      value
    }
  }
  fontSizes {
    desktop {
      name
      value
    }
    mobile {
      name
      value
    }
  }
  views {
    full {
      imagesAsCarousel
    }
  }
  buttons {
    addToBasket {
      withQuantity
    }
  }
  fontFaces
  styles {
    _id
    title
    css
  }
`;

const CONFIG = `
  _id
  id
  domain
  uri
  apiKey {
    value
  }
  eshop {
    menus
    head {
      tags
    }
    checkout {
      version
    }
    preferences {
      languages {
        whitelist
      }
      homepage {
        handle
        type
      }
    }
    theme {
      selected {
        ${THEME}
      }
    }
  }
  currencySymbol
  currencySymbolPosition
  modules {
    id
    data
  }
  google {
    analytics {
      view {
        id
        name
      }
      property {
        id
        name
        tracker
      }
    }
  }
  articles {
    handle
    title
    type
  }
  taxes {
    rules {
      editPricesBeforeTaxes
      showPricesBeforeTaxes
    }
  }
`;

const INVENTORY = `
  pagination {
    limit
    pages
    page
  }
  filters
  items {
    ${ITEM}
  }
`;

const ADDRESS = `
  description
  firstName
  lastName
  addressLine1
  addressLine2
  streetNumber
  city
  state
  postCode
  alpha2
  type
`;

const USER = `
  _id
  id
  firstName
  lastName
  name
  email
  telephone
  taxNumber
  organisation
  addresses {
    ${ADDRESS}
  }
  isSubscribed
  preferences {
    notifications {
      backInStock {
        email
      }
    }
  }
`;

const CHECKOUT_PRICE = `
  base
  sale
  compare
`;

const CHECKOUT = `
  _id
  id
  buyer {
    _id
    email
    firstName
    id
    lastName
    name
    telephone
    organisation
    taxNumber
  }
  shipping {
    status
    date
    price {
      base
    }
    method
    methods  {
      name
      total
    }
    freeShippingThreshold
    address {
      ${ADDRESS}
    }
    tracking
    shopCollection
    weight
  }
  billing {
    status
    address {
      ${ADDRESS}
    }
    voucher {
      id
      redeemType
      expires
      value
    }
  }
  orderId
  items {
    listing {
      _id
      id
      prices {
        ${CHECKOUT_PRICE}
      }
      onePerCustomer
      preOrder
      stock {
        quantity
      }
      available
      options {
        name
        value
      }
    }
    item {
      id
      description
      thumbnail
      weight
      path
    }
    quantity
    price {
      base
      beforeTaxes
      subtotal
      subtotalBeforeTaxes
    }
  }
  totals {
    grand
    leftToPay
    discount
    itemQuantity
    subtotal
    shipping
    taxes {
      name
      amount
    }
  }
`;

const SESSION = `
  session {
    user {
      ${USER}
    }
    jwt
    expires
  }
`;

const ORDER = `
  _id
  id
  origin
  status
  paymentUrl
  buyer {
    name
    email
    firstName
    lastName
  }
  messages {
    content
    subject
    created
    fromBuyer
    attachments {
      _id
      uri
      title
    }
    sender {
      name
      alias
    }
  }
  billing {
    status
    invoice {
      date
      pdf
      id
    }
    paymentMethods {
      origin
      amount
    }
  }
  shipping {
    tracking
    shopCollection
    address {
      alpha2
    }
  }
  items {
    listing {
      options {
        name
        value
      }
    }
    item {
      id
      description
      thumbnail
      path
    }
    quantity
    price {
      subtotal
    }
    unavailable
  }
  totals {
    subtotal
    shipping
    grand
    discount
    itemQuantity
  }
`;

const COLLECTION_WITH_DATA = `
  collection {
    id
    title
    handle
    viewType
    description {
      content
    }
    sort {
      key
      order
    }
    banner {
      media {
        _id
        url
        formats {
          banner {
            url
            name
          }
        }
      }
      format
    }
  }
  page {
    pagination {
      limit
      pages
      page
      sort
      order
    }
    items {
      ${ITEM}
    }
  }
`;

export const GET_ITEM = gql(`
  query($id: Float!) {
    item(id: $id) {
        ${ITEM}
    }
}`);

export const GET_ITEMS = gql(`
  query($ids: [Float]!) {
    items(ids: $ids) {
      ${ITEM}
    }
  }
`);

export const GET_CHECKOUT = gql(`
  query($id: String!) {
    checkout(id: $id) {
      ${CHECKOUT}
    }
}`);

export const GET_ITEM_SUGGESTIONS = gql(`
  query($id: Float, $type: String, $genres: [String], $styles: [String], $categories: [String], $country:String, $limit: Int) {
    suggestions(id: $id, type: $type, genres: $genres, styles: $styles, categories: $categories, country: $country, limit: $limit) {
        ${ITEM}
    }
}`);

export const GET_SEARCH = gql(`
  query($term: String!) {
    search(term: $term) {
      items {
        path
        data {
          images {
            uri
          }
          formats {
            qty
            name
          }
        }
        descriptions {
          main
        }
      }
    }
  }
`);

export const GET_PLAYLIST = gql(`
  query($id: Int!) {
    playlist(id: $id) {
        ${PLAYLIST}
    }
}`);

export const GET_ARTICLE = gql(`
  query($homepage: Boolean, $id: Int, $handle: String $preview: Boolean) {
    article(homepage: $homepage, id: $id, handle: $handle preview: $preview) {
      ${ARTICLE}
    }
  }
`);

export const GET_ARTICLES = gql(`
  query {
    articles {
        ${ARTICLE}
    }
}`);

export const GET_ARTISTS = gql(`
  query {
    artists {
        ${ARTIST}
    }
}`);

export const GET_LABELS = gql(`
  query($letter: String) {
    labels(letter: $letter) {
        ${LABEL}
    }
}`);

export const GET_CONFIG = gql(`
  query($domain: String) {
    config(domain: $domain) {
        ${CONFIG}
    }
}`);

export const GET_ORDERS = gql(`
  query {
    orders {
      ${ORDER}
    }
}`);

export const GET_USER = gql(`
  query {
    user {
      ${USER}
    }
}`);

export const GET_PASSWORD_USER_SESSION = gql(`
  query($token: String!) {
    user : userPasswordSession(token: $token) {
      ${USER}
    }
}`);

export const GET_SESSION = gql(`
  query {
    ${SESSION}
}`);

export const GET_THEME = gql(`
  query($id: String!) {
    theme(id: $id) {
      ${THEME}
    }
}`);

export const GET_ORDER = gql(`
  query($id: String!) {
    order(id: $id) {
      ${ORDER}
    }
}`);

export const POST_INVENTORY_STOCKLIST = gql(`
  mutation {
    inventoryFileExport
}`);

export const GET_INVENTORY = gql(`
  query($wantlist: Boolean, $to: String, $preorder: Boolean, $from: String, $page: Int, $types: [String], $manufacturers: [String], $type: String! $maxPrice: Int, $countries: [String], $years: [Int], $artists: [Float], $labels: [Float], $formats: [String], $categories: [String], $stock: String, $condition: String, $order: Int, $sort: String, $limit: Int, $styles: [String], $genres: [String], $mediums: [String]) {
    inventory(wantlist: $wantlist, to: $to, from: $from, preorder: $preorder, page: $page, types: $types, manufacturers: $manufacturers, type: $type, maxPrice: $maxPrice, countries: $countries, years: $years, artists: $artists, labels: $labels, formats: $formats, categories: $categories, stock: $stock, condition: $condition, order: $order, sort: $sort, styles: $styles, genres: $genres, limit: $limit, mediums: $mediums) {
      ${INVENTORY}
    }
  }
`);

export const GET_COLLECTION = gql(`
  query($handle: String, $id: Int, $page: Int, $limit: Int, $order: Int, $sort: String, $randomise: Boolean) {
    collection(handle: $handle, id: $id, page: $page, limit: $limit, order: $order, sort: $sort, randomise: $randomise) {
      ${COLLECTION_WITH_DATA}
    }
  }
`);

export const POST_LOGIN = gql(`
  mutation($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      ${SESSION}
      sessionsCount
    }
}`);

export const POST_CHECKOUT_ASSOCIATE_USER = gql(`
  mutation($id: String!) {
    checkout: checkoutAssociateUser(id: $id) {
      ${CHECKOUT}
    }
}`);

export const POST_CHECKOUT_DISASSOCIATE_USER = gql(`
  mutation($id: String!) {
    checkout: checkoutDisassociateUser(id: $id) {
      ${CHECKOUT}
    }
}`);

export const POST_USER_CREATE_PASSWORD_TOKEN = gql(`
  mutation($email: String!) {
    userCreatePasswordToken(email: $email)
}`);

export const POST_RELEASE_SNIPPET_ARCHIVE = gql(`
  mutation($id: Float!) {
    itemSnippetsArchive(id: $id)
}`);

export const POST_CHECKOUT = gql(`
  mutation {
    checkoutCreate {
      ${CHECKOUT}
    }
}`);

export const POST_CHECKOUT_UPDATE = gql(`
  mutation($id: String!, $buyer: CheckoutBuyerInput, $shipping: CheckoutShippingInput, $billing: CheckoutBillingInput) {
    checkoutUpdate(id: $id, buyer: $buyer, shipping: $shipping, billing: $billing) {
      ${CHECKOUT}
    }
}`);

export const POST_CHECKOUT_VERIFY = gql(`
  mutation($id: String!) {
    checkoutVerifyAndUpdate(id: $id) {
      checkout {
        ${CHECKOUT}
      }
      message
    }
  }
`);

export const POST_VERIFY = gql(`
  mutation($token: String) {
    userVerify(token: $token) {
      message
    }
  }
`);

export const POST_LIST_OPTIN = gql(`
  mutation($listRef: ID!, $email: String!) {
    listOptin(listRef: $listRef, email: $email)
  }
`);

export const POST_LIST_OPTOUT = gql(`
  mutation($listRef: ID!, $email: String!, $reason: String!) {
    listOptout(listRef: $listRef, email: $email, reason: $reason)
  }
`);

export const POST_ORDER_MESSAGE = gql(`
  mutation($id: String!, $message: String!) {
    order: orderAddMessage(id: $id, message: $message) {
      ${ORDER}
    }
}`);

export const POST_CHECKOUT_UPDATE_ITEMS = gql(`
  mutation($id: String!, $items: [CheckoutItemInput]) {
    checkoutUpdateItems(id: $id, items: $items) {
      ${CHECKOUT}
    }
  }
`);

export const POST_VALIDATE_VOUCHER = gql(`
  mutation($id: String!, $voucherId: String!) {
    validateVoucher(id: $id, voucherId: $voucherId) {
      id
    }
  }
`);

export const ADD_VOUCHER = gql(`
  mutation($id: String!, $voucherId: String!) {
    addVoucher(id: $id, voucherId: $voucherId) {
      ${CHECKOUT}
    }
  }
`);

export const REMOVE_VOUCHER = gql(`
  mutation($id: String!) {
    removeVoucher(id: $id) {
      ${CHECKOUT}
    }
  }
`);

export const POST_REGISTER = gql(`
  mutation($password: String!, $email: String!, $firstName: String!, $lastName: String!) {
    register(password: $password, email: $email, firstName: $firstName, lastName: $lastName) {
      message
    }
}`);

export const POST_USER_UPDATE = gql(`
  mutation($email: String, $password: String, $firstName: String, $lastName: String, $telephone: String, $organisation: String, $taxNumber: String, $addresses: [AddressInput], $preferences: JSON) {
    user: userUpdate(email: $email, password: $password, firstName: $firstName, lastName: $lastName, telephone: $telephone, organisation: $organisation, taxNumber: $taxNumber, addresses: $addresses, preferences: $preferences) {
      ${USER}
    }
}`);

export const POST_USER_PWD_RESET = gql(`
  mutation($password: String!, $token: String!) {
    userPasswordReset(password: $password, token: $token)
}`);

export const POST_LOGOUT = gql(`
  mutation {
    logout {
      ok
    }
}`);

export const POST_WANT = gql(`
  mutation($id: Float!) {
    want(id: $id) {
      ${ITEM}
    }
  }
`);

export const POST_REGISTER_EVENT = gql(`
  mutation($type: String!, $data: JSON!) {
    registerEvent(type: $type, data: $data) {
      id
    }
  }
`);

export const POST_PLAY_COUNT = gql(`
  mutation($id: Float!, $position: String!) {
    playCount(id: $id, position: $position)
  }
`);

export const POST_STRIPE_INTENT = gql(`
  mutation($documentId: String!, $paymentMethodId: String, $provider: String) {
    stripeCreateIntent(documentId: $documentId, paymentMethodId: $paymentMethodId, provider: $provider) {
      intent
      results {
        success
        requires_action
        payment_intent_client_secret
        error
      }
    }
  }
`);

export const POST_STRIPE_CONFIRM_INTENT = gql(`
  mutation($documentId: String!, $intentId: String!) {
    stripeConfirmIntent(intentId: $intentId, documentId: $documentId) {
      intent
      results {
        success
        error
      }
    }
  }
`);

export const POST_CHECKOUT_SUBMIT = gql(`
  mutation($checkoutId: String!, $paymentData: JSON) {
    checkoutSubmit(checkoutId: $checkoutId, paymentData: $paymentData) {
      order {
        id
      }
    }
  }
`);

export const POST_CREATE_PAYPAL_PAYMENT = gql(`
  mutation($documentId: String!) {
    paypalCreateOrder(documentId: $documentId) {
      orderId
    }
  }
`);

export const POST_AUTHORIZE_PAYPAL_PAYMENT = gql(`
  mutation($paypalOrderId: String!) {
    paypalAuthorizePayment(paypalOrderId: $paypalOrderId) {
      authorizationId
    }
  }
`);

export const POST_BUYER_DEACTIVATE = gql(`
  mutation {
    buyerDeactivate
  }
`);
