import React from "react";
import { GoPrimitiveDot } from "react-icons/go";

export default function Checkbox (props) {
  const isActive = !!props.checked || false;
  const handleClick = e => {
    e.preventDefault();
    props.onChange({ target: { checked: !isActive, name: props.name, value: props.value } });
  };

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        onClick={handleClick}
        defaultChecked={isActive}
        name={props.name}
        value={props.value}
        style={{ display: "none" }}
      />
      <button type="button" onClick={handleClick}>
        {isActive ? <GoPrimitiveDot /> : null}
      </button>
    </div>
  );
}
