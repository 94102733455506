import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "./common/loader";
import moment from "moment";
import Meta from "./global/meta";
import { useQuery } from "@apollo/client";
import { GET_ARTICLES } from "../graphql/queries";
import { findUrlForMedia } from "../utils/helpers";

export default function Articles () {
  const { loading, data } = useQuery(GET_ARTICLES, { variables: {} });
  const articles = data && data.articles;

  useLayoutEffect(() => {
    setTimeout(() => {
      window.prerenderReady = true;
    }, 100);
  }, []);

  if (loading || articles === undefined) return <Loader withMargins />;

  return (
    <div id="articles">
      <Meta title="Articles" description="Articles" />
      <div className="articles grid">
        {articles.map(a => (
          <div key={a.id} className="article">
            <Link to={a.handle}>
              <div className="header">
                <div className="image">
                  {a.thumbnail ? <img alt={a.alt} src={findUrlForMedia(a.thumbnail, "banner-sm")} /> : <span />}
                </div>
              </div>
            </Link>
            <Link to={a.handle}>
              <h2>{a.title}</h2>
            </Link>
            <p className="description">{a.seo.description || ""}</p>
            <div className="footer">
              <Link to={a.handle}>Read more</Link>
              <p>{moment(a.published.date).fromNow()}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
