import React, { useEffect } from "react";
import Meta from "./global/meta";
import { Store } from "../contexts/Store";

export default function Shipping () {
  const config = Store.useState(s => s.config);

  useEffect(() => {
    Store.update(s => { s.globalClass = "shippingPolicies"; });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTimeout(function () {
      window.prerenderReady = true;
    }, 100);
  }, []);

  return (
    <div id="shippingPolicies">
      <Meta title="Shipping" description="Our shipping policies" />
      <div id="header">
        <h1>Shipping</h1>
      </div>
      <hr />
      <div id="shippingContentContainer">
        <div
          id="content"
          dangerouslySetInnerHTML={{
            __html: config.getProperty("shipping", "policyText")
          }}
        />
      </div>
    </div>
  );
}
