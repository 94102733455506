import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Meta from "../global/meta";
import Loader from "../common/loader";
import Price from "../common/price";
import moment from "moment";
import { Store } from "../../contexts/Store";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ORDER, POST_ORDER_MESSAGE } from "../../graphql/queries";
import { round } from "@common-ground-io/common-assets/helpers/utils";
import { getTranslatedOption } from "../../utils/helpers";

export default function Order (props) {
  const { config, addNotification, t } = Store.useState(s => s);
  const [newMessageContent, setNewMessageContent] = useState("");

  const [getOrder, { error, refetch, data, loading }] = useLazyQuery(GET_ORDER, {
    variables: { id: props.match.params.id },
    fetchPolicy: "cache-and-network"
  });
  const [sendMessage, { loading: orderIsSendingMessage }] = useMutation(POST_ORDER_MESSAGE);

  const order = data?.order;

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  const handleAddMessage = async e => {
    e.preventDefault();
    if (orderIsSendingMessage) return;
    const newMessageContent = e.target.messageContent.value;
    if (!newMessageContent) return;
    try {
      await sendMessage({ variables: { id: order.id, message: newMessageContent } });
      refetch();
      addNotification({ ok: 1, message: "Message sent" });
      setNewMessageContent("");
    } catch (e) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleMessageChange = e => {
    setNewMessageContent(e.target.messageContent);
  };

  if (error || order === null) return <Redirect to="/login" />;
  else if (order === undefined || loading)
    return (
      <div id="order">
        <Loader />
      </div>
    );
  else if (order === null) {
    return (
      <div id="order">
        <div id="content">
          <h2>Order not found</h2>
        </div>
      </div>
    );
  }

  return (
    <div id="order">
      <Meta title={`Order ${order ? order.id : null}`} />
      <div id="content">
        <div id="left">
          <div id="summary">
            <h1>
              {t("order")} #{order.id}
            </h1>
            <hr />
            <p className="justify">
              <span>{t("status")}</span>
              <span>{order.status}</span>
            </p>
            {order.shipping && order.shipping.tracking ? (
              <p className="justify">
                <span>Tracking</span>
                <span>
                  <a target="_tab" href={order.shipping.tracking}>
                    Track this package{" "}
                  </a>
                </span>
              </p>
            ) : null}
            <p className="justify">
              <span>Created:</span>
              <span>{moment(order.created).format("llll")}</span>
            </p>
            {order.packages &&
              order.packages.map((p, i) => (
                <p key={i} className="package justify">
                  <span>Package {i + 1} subtotal:</span>
                  <span>
                    <Price value={p.subtotal}></Price>
                  </span>
                </p>
              ))}
            <p className="justify">
              <span>{t("totalNumberOfItems")}:</span>
              <span>{order.totals.itemQuantity}</span>
            </p>
            <p className="justify">
              <span>{t("subtotal")}:</span>
              <span>
                <Price value={round(order.totals.subtotal)}></Price>
              </span>
            </p>
            <p className="justify">
              <span>{t("shipping")}:</span>
              <span>
                {order && order.shipping && order.shipping.shopCollection ? (
                  "Shop collection"
                ) : (
                  <>
                    {(order.shipping && order.shipping.address && order.shipping.address.alpha2) || "NA"} -{" "}
                    <Price value={order.totals.shipping} />
                  </>
                )}
              </span>
            </p>
            {order.totals.discount ? (
              <p className="justify">
                <span>Discount:</span>
                <Price value={-order.totals.discount} />
              </p>
            ) : null}
            {order.billing.invoice && order.billing.invoice.pdf ? (
              <p className="justify">
                <span>PDF invoice</span>
                <span>
                  <a target="_tab" href={order.billing.invoice.pdf}>
                    Download
                  </a>
                </span>
              </p>
            ) : null}
            {order.billing.paymentMethods.length ? (
              <p className="justify">
                <span>{t("paymentMethods")}:</span>
                <span>
                  {order.billing.paymentMethods.map(m => (
                    <span key={m}>
                      {m.origin} - <Price value={m.amount} />
                    </span>
                  ))}
                </span>
              </p>
            ) : null}
            <hr />
            <h2 className="justify">
              <span>{t("total")}</span>
              <span>
                <Price value={order.totals.grand}></Price>
              </span>
            </h2>
          </div>
          {order.billing.status === "Pending" && order.status === "New Order" ? <h3>{t("beingProcessed")}</h3> : null}
          {order.billing.status === "Pending" && order.status === "Invoice Sent" && order.paymentUrl ? (
            <div id="orderPayment">
              <a href={order.paymentUrl}><button className="primary" style={{ width: "100%" }}>{t("proceedToPayment")}</button></a>
            </div>
          ) : null}
        </div>
        <div id="right">
          <div id="items">
            <div id="itemsHeader">
              <p className="title">{t("items")}</p>
              <p></p>
              <p>{t("qty")}</p>
              <p>{t("total")}</p>
            </div>
            {order.items.map((i, index) => (
              <div className="entry" key={index}>
                <div className="image">
                  <Link to={`/item/${i.item.id}`}>
                    <img alt={i.item.description} src={i.item.thumbnail} />
                  </Link>
                </div>
                <div className="description">
                  <Link to={`/item/${i.item.id}`}>
                    <p>{i.item.description}</p>
                  </Link>
                  <div className="options">
                    {i.listing.options && i.listing.options.length ? i.listing.options.map(o => (
                      <p key={o.name}>
                        {getTranslatedOption({ option: o, t })}: {o.value}
                      </p>
                    )) : null}
                  </div>
                </div>
                <p className="quantity">{i.quantity}</p>
                <div className="total">
                  <p>
                    <Price value={round(i.price.subtotal)} />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div id="messagesContainer">
            <div id="messagesHeader">
              <h2>
                {t("newMessageTitle")} {config.getProperty("information", "shopName")}
              </h2>
            </div>
            <form id="newMessage" onSubmit={handleAddMessage}>
              <textarea
                rows="8"
                value={newMessageContent}
                onChange={handleMessageChange}
                name="messageContent"
                required
              />
              <button type="submit" disabled={orderIsSendingMessage} className="primary">
                {orderIsSendingMessage ? "Sending..." : t("sendMessage")}
              </button>
            </form>
            <div id="orderMessages">
              {order.messages && order.messages.length ? (
                <>
                  <h2>Messages</h2>
                  <hr />
                  {order.messages.map((m, index) => (
                    <div key={m.created} className="message">
                      <p className="header">
                        {m.sender.alias || m.sender.name} - {moment(m.created).fromNow()}
                      </p>
                      <p className="content" dangerouslySetInnerHTML={{ __html: m.content }} />
                      <div className="attachments">
                        {m.attachments && m.attachments.length ? m.attachments.map(a => <div key={a._id} className="attachment">
                          <a target="_blank" href={a.uri} rel="noreferrer">{a.title}</a>
                        </div>) : null}
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
