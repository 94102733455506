import React, { useState, useEffect } from "react";
import URI from "urijs";
import { useMutation } from "@apollo/client";
import { POST_VERIFY } from "../graphql/queries";
import { useLocation } from "react-router-dom";

export default function Verify (props) {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const params = new URI(location.pathname + location.search).search(true);
  const [verify] = useMutation(POST_VERIFY);

  useEffect(() => {
    verify({ variables: { token: params.token } }).then((result) => {
      setMessage(result.data.userVerify.message);
    }).catch(result => { setMessage(result.message.toString()); });
  }, []);

  return (
    <div id="unsubscribe" style={{ textAlign: "center" }}>
      <h1>{message}</h1>
    </div>
  );
}
