import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Price from "./price";
import moment from "moment";
import { BasketAdd, BasketAddPreorder } from "./icons";
import useCheckout from "../../hooks/useCheckout";
import { Store } from "../../contexts/Store";

export const BuyButton = props => {
  const listing = props.listing;
  const [isLoading, setIsLoading] = useState(false);
  const { t, updateBasket, addNotification, config } = Store.useState(s => s);
  const { hasInBasket } = useCheckout();

  const isAvailableForSale = props.forSales;

  const isPreorder = () => {
    return listing.preOrder;
  };

  const extractOption = option => {
    return option.substring(option.lastIndexOf("(") + 1, option.lastIndexOf(")"));
  };

  const handleAddToBasket = async e => {
    if (isLoading) return;
    if (listing.stock.quantity === 0) return addNotification({ ok: 0, message: t("soldOut") });

    e.preventDefault();
    setIsLoading(true);
    await updateBasket(listing.id);
    setIsLoading(false);
  };

  const optionsDisplayer = options => {
    const mediaCondition = options.find(o => o.name === "Media Condition");
    const sleeveCondition = options.find(o => o.name === "Sleeve Condition");

    if (mediaCondition || sleeveCondition) {
      const regex = / *\([^)]*\) */g;
      const mediaValue = mediaCondition && mediaCondition.value;
      const sleeveValue = sleeveCondition && sleeveCondition.value;

      return (
        <>
          <div className="media">
            {mediaValue ? (
              <span>
                {t("media")}: {extractOption(mediaValue) || mediaValue}
              </span>
            ) : null}
            <span
              className="mediaTooltip"
              data-multiline="true"
              data-background-color="#d8d8d8"
              data-arrow-color="transparent"
              data-html={true}
              data-tip={`
                  ${mediaValue ? `<p>Media: ${mediaValue.replace(regex, "")}</p>` : ""}
                  ${sleeveValue ? `<p>Sleeve: ${sleeveValue.replace(regex, "")}</p>` : ""}
                `}>
              i
            </span>
            <ReactTooltip effect="solid" delayhide="1000" />
          </div>
          {sleeveValue ? (
            <div className="sleeve">
              <span>
                {t("sleeve")}: {extractOption(sleeveValue) || sleeveValue}
              </span>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {options.map(o => (
            <div key={o.name} className={o.name}>
              <span>
                {o.name}: {o.value}
              </span>
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <div
      key={listing.id}
      className={`priceAndBuy ${listing.stock.quantity ? "buyable" : "soldOut"}`}
      onClick={handleAddToBasket}>
      <div className="preorderNotice">
        {isPreorder() && listing.available ? (
          <>
            {" "}
            <p>
              {t("available")}{" "}
              {moment(listing.available).isBefore(moment().add(1, "day")) ? t("tomorrow") : moment(listing.available).fromNow()}
            </p>
            <p>{moment(listing.available).format("ll")}</p>
          </>
        ) : null}
        {isPreorder() && !listing.available ? (
          <>
            {" "}
            <p>
              {t("preOrderState")}
            </p>
          </>
        ) : null}
      </div>

      <div className="options">{optionsDisplayer(listing.options)}</div>

      <div className="priceAndButton">
        {isAvailableForSale ? (
          <h3>
            <Price value={config.taxes.rules.showPricesBeforeTaxes ? listing.prices.beforeTaxes : listing.prices.sale} />
            <span>*</span>
            {listing.stock.quantity ? (
              listing.prices.compare ? (
                <Price className="discounted" value={listing.prices.compare} />
              ) : null
            ) : null}
          </h3>
        ) : null}
        {listing.stock.quantity ? (
          <div className="addToBasket">
            {isPreorder(listing.available) ? (
              <BasketAddPreorder active={hasInBasket(listing.id)} />
            ) : (
              <BasketAdd active={hasInBasket(listing.id)} />
            )}
          </div>
        ) : (
          <p disabled className="primary notAvailable">
            {t("soldOut")}
          </p>
        )}
      </div>

      <div className="taxNotice">
        <p> {config.taxes.rules.showPricesBeforeTaxes ? t("taxNoticeExcluded") : t("taxNotice")} </p>
      </div>

      <div className="listingComments">{listing.comments ? <p> {listing.comments} </p> : null}</div>
    </div>
  );
};
