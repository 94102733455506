import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Meta from "./global/meta";
import Loader from "./common/loader";
import Checkbox from "./common/checkbox";
import { Store } from "../contexts/Store";
import { useMutation } from "@apollo/client";
import {
  POST_LOGIN,
  POST_REGISTER,
  POST_USER_CREATE_PASSWORD_TOKEN,
  POST_CHECKOUT_ASSOCIATE_USER,
  POST_LIST_OPTIN
} from "../graphql/queries";
import Cookies from "js-cookie";
import ReactGA4 from "react-ga4";

export default function Login (props) {
  const { session, config, reloadConfig, resetStore, t, addNotification, checkout } = Store.useState(s => s);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [rememberMe, setRememberMe] = useState("");
  const [error, setError] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [resetMessage, setResetMessage] = useState("");
  const listRef = config.getProperty("newsletter", "defaultListRef");

  const [optin] = useMutation(POST_LIST_OPTIN);
  const [login, { loading: loginLoading }] = useMutation(POST_LOGIN);
  const [associateUser] = useMutation(POST_CHECKOUT_ASSOCIATE_USER);
  const [register, { loading: registerLoading }] = useMutation(POST_REGISTER);
  const [createPasswordToken, { loading: createPasswordTokenLoading }] = useMutation(POST_USER_CREATE_PASSWORD_TOKEN);

  const handleLogin = async e => {
    e.preventDefault();
    try {
      const results = await login({
        variables: {
          email: Buffer.from(email).toString("base64"),
          password: Buffer.from(password).toString("base64")
        }
      });
      const succeeded = !!results?.data?.login;
      if (succeeded) {
        const user = results.data.login.session.user;
        Cookies.set("jwt", results.data.login.session.jwt, { expires: 90 });
        const { data } = await associateUser({ variables: { id: checkout.id } });
        Store.update(s => {
          s.checkout = data.checkout;
        });
        ReactGA4.set("userId", user._id);
        addNotification({ ok: 1, message: `Hi ${user.name}` });
        reloadConfig();
      } else {
        setError("Login error");
      }
      Store.update(s => {
        s.session = results?.data?.login?.session;
      });
      resetStore();
    } catch (e) {
      setError(e.toString());
      addNotification({ ok: 0, message: e.toString() });
      Store.update(s => {
        s.session = undefined;
      });
    }
  };

  const handleRegister = async e => {
    e.preventDefault();
    const email = Buffer.from(e.target.email.value.toLowerCase()).toString("base64");
    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const password = Buffer.from(e.target.password.value).toString("base64");
    const subscribe = e.target.subscribe && e.target.subscribe.checked;
    try {
      const { data } = await register({ variables: { email, firstName, lastName, password } });
      const succeeded = !!data?.register;
      if (succeeded) {
        resetStore();
        addNotification({ ok: 1, message: data.register.message });
        if (subscribe) await optin({ variables: { email, listRef } });
        setRegisterError(data.register.message);
      }
    } catch (e) {
      setRegisterError(e.toString());
      addNotification({ ok: 0, message: e.toString() });
      Store.update(s => {
        s.session = undefined;
      });
    }
  };

  const handleCheckboxChange = async e => {
    setRememberMe(e.target.checked);
  };

  const handlePasswordReset = async e => {
    e.preventDefault();
    const email = e.target.email.value;
    try {
      await createPasswordToken({ variables: { email } });
      setResetEmail("");
      setResetMessage("Reset instructions were sent to your email address.");
    } catch (e) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    Store.update(s => { s.globalClass = "loginAndRegister"; });
    setTimeout(function () {
      window.prerenderReady = true;
    }, 100);
  }, []);

  if (session) return <Redirect to="home" />;
  setTimeout(function () {
    window.prerenderReady = true;
  }, 100);

  return (
    <div id="loginAndRegister">
      <Meta title="Login" description="Login to your account" />
      <div className="left">
        <section id="login">
          <h1>{t("loginToYourAccount")}</h1>
          <form onSubmit={handleLogin}>
            <label>
              {t("enterEmail")}
              <input
                type="email"
                name="email"
                placeholder={t("yourEmail")}
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </label>
            <label>
              {t("enterPwd")}
              <input
                type="password"
                name="password"
                placeholder={t("yourPassword")}
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
            </label>
            <label className="checkbox">
              <Checkbox
                type="checkbox"
                name="showSoldOut"
                onChange={e => handleCheckboxChange(e)}
                checked={rememberMe}
              />
              {t("rememberMe")}
            </label>
            <button type="submit" label="Login" disabled={loginLoading} className="primary">
              {t("login")}
            </button>
            {error ? <p className="error">{error}</p> : null}
          </form>
        </section>
        <section id="passwordReset">
          <h2>{t("lostYourPassword")}</h2>
          <form onSubmit={handlePasswordReset}>
            <label>
              {t("yourEmail")}
              <input
                type="text"
                placeholder={t("yourEmail")}
                name="email"
                required
                value={resetEmail}
                onChange={e => setResetEmail(e.target.value)}></input>
            </label>
            <button disabled={createPasswordTokenLoading} type="submit" className="primary">
              {createPasswordTokenLoading ? t("pleaseWait") : t("resetPassword")}
            </button>
            <p className="error">{resetMessage}</p>
          </form>
        </section>
      </div>
      <div className="right">
        {!config.getProperty("information", "preventRegistration") ? (
          <div id="register">
            <h1>{t("createAccount")}</h1>
            <form onSubmit={handleRegister} className="userCreate">
              <div className="createUserDetails">
                <label>
                  {t("enterEmail")}
                  <input required name="email" type="email" placeholder={t("yourEmail")} />
                </label>
              </div>
              <div className="createUserDetails">
                <label>
                  {t("firstName")}
                  <input required name="firstName" type="string" placeholder={t("yourFirstName")} />
                </label>
                <label>
                  {t("lastName")}
                  <input required name="lastName" type="string" placeholder={t("yourLastName")} />
                </label>
                <label>
                  {t("enterPwd")}
                  <input required name="password" placeholder={t("aSecurePassword")} type="password" />
                </label>
              </div>
              {listRef ? (
                <label className="subscribe">
                  <input type="checkbox" defaultChecked={false} name="subscribe" />
                  {t("subscribeToOurNewsletter", { shopName: config.getProperty("information", "shopName") })}
                </label>
              ) : null}
              <button onClick={props.onAdd} disabled={registerLoading} type="submit" className="primary">
                {registerLoading ? <Loader /> : t("submit")}
              </button>
            </form>
            {registerError ? <p className="error">{registerError}</p> : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
