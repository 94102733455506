import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import { PlayerStore } from "../../contexts/Store";
import { Close, PlayerPlay, PlayerPause, Prev, Next } from "../common/icons";
import { useMediaQuery } from "react-responsive";

const Player = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const {
    playPauseTrack,
    isLoading,
    currentTrackData,
    isPlaying,
    stopAndClose,
    isShowing,
    position,
    tracklist
  } = PlayerStore.useState(s => s);

  useEffect(() => {
    PlayerStore.update(s => {
      s.domReady = true;
    });
  }, []);

  const handleNext = () => {
    PlayerStore.update(s => {
      s.shouldLoadNext = true;
    });
  };

  const handlePrevious = () => {
    PlayerStore.update(s => {
      s.shouldLoadPrevious = true;
    });
  };

  let title =
    currentTrackData && currentTrackData.track.title ? `${currentTrackData.track.position} - ${currentTrackData.track.title}` : null;
  if (title && isTabletOrMobile && title.length > 20) title = title.substring(0, 20) + "...";

  return (
    <div id="player" className={`${currentTrackData && isShowing ? "" : "hidden"}`}>
      <div id="playerContent">
        {currentTrackData && currentTrackData.release ? (
          <div id="left">
            <div id="artwork">
              <img
                alt={currentTrackData.release.descriptions.main}
                src={currentTrackData.release.data?.images[0]?.uri || currentTrackData.release.data.thumb}></img>
            </div>
            <div id="playerTitle">
              <Link to={currentTrackData.release.path}>{title}</Link>
            </div>

            {isLoading ? (
              <Loader size={5} color="#000" />
            ) : (
              <div className="controls">
                <button type="button" onClick={handlePrevious} disabled={position === 0}>
                  <Prev />
                </button>
                <button className="playPauseButton" onClick={playPauseTrack}>
                  {isPlaying ? <PlayerPause /> : <PlayerPlay />}
                </button>
                <button type="button" onClick={handleNext}>
                  <Next />
                </button>
              </div>
            )}
          </div>
        ) : null}
        <div id="waveform" />
        <div id="right">
          <p>
            {position + 1} / {tracklist.length}
          </p>
          {currentTrackData ? (
            <>
              <button onClick={stopAndClose}>
                <Close />
              </button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Player;
