import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Playlist from "../global/playlist";
import { useInView, defaultFallbackInView } from "react-intersection-observer";
import Carousel from "../global/carousel";
import { CollectionEmbed } from "../../pages/collection";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PLAYLIST, GET_ARTICLE, GET_ITEMS } from "../../graphql/queries";
import { Store } from "../../contexts/Store";
import { SubscribeBlock } from "../global/subscribe";
import Parse, { domToReact } from "html-react-parser";
import URI from "urijs";
import { Tile } from "../common/views";
import { findUrlForMedia } from "../../utils/helpers";
defaultFallbackInView(true);

const PlaylistBlock = ({ data: blockData }) => {
  const [ref, onScreen] = useInView();
  const [getPlaylistData, { loading, data: playlistData }] = useLazyQuery(GET_PLAYLIST);
  const playlist = playlistData && playlistData.playlist;

  useEffect(() => {
    if (onScreen && !playlist) getPlaylistData({ variables: { id: parseInt(blockData.id) } });
  }, [onScreen, playlist, blockData.id, getPlaylistData]);

  if (playlist === null) return null;

  return (
    <div ref={ref} className="block playlist">
      {playlist && !loading ? <Playlist playlist={playlist} /> : <Playlist dummy />}
    </div>
  );
};

const CollectionBlock = ({ data: blockData }) => {
  const [ref, onScreen] = useInView();

  return (
    <div ref={ref} className="block collection">
      <CollectionEmbed data={blockData} shouldLoad={onScreen} />
    </div>
  );
};

const ItemsBlock = ({ block, isMobile }) => {
  const { data, loading } = useQuery(GET_ITEMS, { variables: { ids: block.data.entries } });

  const items = data?.items;
  if (loading) return null;
  else if (!data) return null;

  return <div className="block items">
    {block.data.title ? <div className="header"><h2 className="title">{block.data.title}</h2></div> : null}
    <div className="entries" style={{ gridTemplateColumns: `repeat(${isMobile ? 2 : block.data.entriesPerRow}, 1fr)` }}>
      {items.map((e, idx) => <Tile key={idx} entry={e} />)}
    </div>
  </div>;
};

const ArticleBlock = ({ block }) => {
  const { data, loading } = useQuery(GET_ARTICLE, { variables: { id: block.data.articleId } });

  const article = data?.article;
  if (loading || !data) return null;

  const format = block.data.format;
  const image = findUrlForMedia(article.thumbnail, format);

  return <div className="block article">
    {image ? <Link to={article.handle}><img src={image} /></Link> : null}
    <Link to={article.handle}><h2>{article.title}</h2></Link>
  </div>;
};

export default function BlocksV2 ({ rows }) {
  const { config, addNotification, t, isTabletOrMobile } = Store.useState(s => s);

  const getAllBlocks = useCallback(({ column }) => {
    const elementsToPush = [];
    for (const index in column.blocks) {
      const b = column.blocks[index];
      const classes = "block ";

      switch (b.type) {
        case "text":
          elementsToPush.push(
            <div key={index} className={classes + "text paragraph"} dangerouslySetInnerHTML={{ __html: b.data.content }} />
          );
          break;
        case "embed": {
          const replace = (domNode) => {
            if (domNode.name === "a" && domNode.attribs.href && !(new URI(domNode.attribs.href).is("absolute"))) {
              return <Link to={domNode.attribs.href}>{domToReact(domNode.children)}</Link>;
            } else domToReact(domNode);
          };
          elementsToPush.push(
            <div key={index} className={classes + "embed"}>
              {Parse(b.data.html || "", { replace })}
            </div>
          );
          break;
        }
        case "separator":
          elementsToPush.push(
            <div key={index} className={classes + "separator"}>
              <hr />
            </div>
          );
          break;
        case "article":
          return <ArticleBlock block={b} t={t} />;
        case "items":
          return <ItemsBlock block={b} t={t} isMobile={isTabletOrMobile} />;
        case "carousel":
          elementsToPush.push(
            <div key={index} className={classes + "images carousel"}>
              <Carousel
                entries={b.data.entries.map(e => ({
                  src: e.format && e.media?.formats && e.media?.formats[e.format]?.url ? e.media?.formats[e.format]?.url : e.media?.url,
                  to: e.to
                }))}
              />
            </div>
          );
          break;
        case "playlist":
          elementsToPush.push(<PlaylistBlock key={index} data={b.data} />);
          break;
        case "subscribe": {
          const listRef = b.data.listRef || config.getProperty("newsletter", "defaultListRef");
          if (listRef)
            elementsToPush.push(
              <SubscribeBlock config={config} t={t} listRef={listRef} addNotification={addNotification} key={index} />
            );
          break;
        }
        case "collection":
          elementsToPush.push(<CollectionBlock key={index} data={b.data} />);
          break;
        case "image": {
          if (!b.data || !b.data.media) break;
          const link = b.data.to || b.data.link;
          const imageUrl = b.data.format && b.data.media?.formats && b.data.media?.formats[b.data.format]?.url ? b.data.media.formats[b.data.format].url : b.data.media?.url;
          if (link) {
            if (link.includes("http"))
              elementsToPush.push(
                <a key={index} target="_blank" rel="noreferrer noopener" href={link} className={classes + "image"}>
                  <img src={imageUrl} />
                </a>
              );
            else
              elementsToPush.push(
                <Link key={index} to={link} className={classes + "image"}>
                  <img src={imageUrl} />
                </Link>
              );
          } else
            elementsToPush.push(
              <div key={index} className={classes + "image"}>
                <img src={imageUrl} />
              </div>
            );
          break;
        }
        default:
          break;
      }
    }
    return elementsToPush;
  }, [addNotification, config, t]);

  return <div className='articleRows'>
    {rows.map(r => (
      <div key={r.id} className={`row ${r.layout} ${(r.options && r.options.fullWidth ? "fullWidth " : "")} ${(r.options && r.options.noPadding ? "noPadding " : "")}`}>
        {r.columns.map(c => (
          <div key={c.id} className="column">
            {getAllBlocks({ column: c, row: r })}
          </div>
        ))}
      </div>
    ))}
  </div>;
}
