import React, { useState, useRef, useEffect } from "react";
import Loader from "./loader";
import { Link, useHistory } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import useClickOutside from "../../hooks/useClickOutside";
import useKeyPress from "../../hooks/useKeyPress";
import { useLazyQuery } from "@apollo/client";
import { GET_SEARCH } from "../../graphql/queries";
import { Store } from "../../contexts/Store";

export default function SearchInput (props) {
  const history = useHistory();
  const { isTabletOrMobile } = Store.useState(s => s);
  const [searchValue, setSearchValue] = useState("");
  const [index, setIndex] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef();
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");

  const [search, { loading: isLoading, data: searchData }] = useLazyQuery(GET_SEARCH);

  const suggestions = searchData?.search?.items;

  useClickOutside(ref, () => {
    if (isFocused) {
      handleResetAndClose();
      setIsFocused(false);
    }
  });

  const handleResetAndClose = () => setSearchValue("");

  useEffect(() => {
    if (!suggestions || !isFocused) return;
    if (suggestions && downPress) setIndex(index => (index + 1) % suggestions.length);
  }, [downPress, enterPress, isFocused, suggestions]);

  useEffect(() => {
    if (upPress && suggestions) setIndex(index => Math.abs((index - 1) % suggestions.length));
  }, [upPress, suggestions]);

  useEffect(() => {
    if (enterPress && index !== null && suggestions && suggestions[index]) {
      const path = suggestions[index].path;
      history.push({ pathname: path });
      handleResetAndClose();
    }
  }, [enterPress, index, isFocused, suggestions, history]);

  const handleSearch = async term => {
    setSearchValue(term);
    if (term && term.length > 1)
      search({ variables: { term } });
  };

  return (
    <div id="mainSearch" ref={ref} onFocus={e => setIsFocused(true)} className="inventorySearch">
      <div className="searchArea">
        <DebounceInput
          type="text"
          minLength={2}
          value={searchValue}
          onChange={event => handleSearch(event.target.value)}
          debounceTimeout={300}
          className="inputSearch"
          placeholder={props.placeHolder}
        />
        <i className={`cg-icon-search${isTabletOrMobile ? "-mobile" : ""}`} />
      </div>

      {searchValue ? (
        <ul className="suggestions">
          {isLoading ? (
            <li className="suggestion noResults">
              <Loader />
            </li>
          ) : null}
          {suggestions && suggestions.length === 0 ? (
            <li className="suggestion noResults">
              <p>No results found...</p>
            </li>
          ) : null}
          {suggestions &&
            suggestions.map((s, i) => (
              <li
                onMouseEnter={e => setIndex(i)}
                key={s.path}
                className={`suggestion ${i === index ? "selected" : ""}`}>
                {s.data.images && s.data.images.length ? (
                  <img alt={s.descriptions.main} src={s.data.images[0].uri} />
                ) : null}
                <Link onClick={handleResetAndClose} to={s.path}>
                  {" "}
                  {s.descriptions.main} {s.data.formats ? <> - {s.data.formats.map(f => <span key={f.name}>{f.qty}x{f.name}</span>)}</> : null}
                </Link>
              </li>
            ))}
        </ul>
      ) : null}
    </div>
  );
}
