import React, { useLayoutEffect, useState } from "react";
import Meta from "../components/global/meta";
import { Store } from "../contexts/Store";
import URI from "urijs";
import Cookies from "js-cookie";

export default function Maintenance ({ handleSubmit, children }) {
  const config = Store.useState(s => s.config);
  const isFromBackoffice = (new URI(window.location.href).search(true).from) === "backoffice";
  const [input, setInput] = useState(Cookies.get("maintenancePassword"));

  const [phrase] = useState(config.getProperty("information", "maintenanceModeNotice") || "Coming soon...");

  const comparePwd = pwd => {
    return pwd === config.getProperty("information", "maintenancePassword");
  };

  let unlocked = false;
  if (config) {
    if (config.getProperty("information", "maintenanceMode")) unlocked = isFromBackoffice || comparePwd(input);
    else unlocked = true;
    if (process.env.REACT_APP_BUILD_ENV === "dev") unlocked = true;
  }

  const handleSubmitMaintenancePassword = e => {
    e.preventDefault();
    Cookies.set("maintenancePassword", e.target.password.value, { expires: 365 });
    setInput(e.target.password.value);
  };

  useLayoutEffect(() => {
    if (unlocked) return;
    setTimeout(() => {
      window.prerenderReady = true;
    }, 100);
  }, [phrase, unlocked]);

  if (unlocked) return children;
  else
    return (
      <div id="maintenance">
        <Meta noIndex description={phrase} title={phrase} />
        <h1>{config.getProperty("information", "shopName")}</h1>
        <p>{phrase}</p>
        <form id="login" onSubmit={handleSubmitMaintenancePassword}>
          <label>
            Have a preview password?
            <input type="password" name="password" required placeholder="Enter password..." />
          </label>
        </form>
      </div>
    );
}
