import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/de";
import enLocale from "./utils/i18n/translations/en.json";
import frLocale from "./utils/i18n/translations/fr.json";
import deLocale from "./utils/i18n/translations/de.json";
import esLocale from "./utils/i18n/translations/es.json";
import itLocale from "./utils/i18n/translations/it.json";
import ruLocale from "./utils/i18n/translations/ru.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enLocale },
      fr: { translation: frLocale },
      de: { translation: deLocale },
      it: { translation: itLocale },
      es: { translation: esLocale },
      ru: { translation: ruLocale }
    },
    fallbackLng: "en",
    debug: false,
    whitelist: ["en", "fr", "de", "it", "es", "ru"],
    interpolation: { escapeValue: false }
  });

export default i18n;
