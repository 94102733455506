import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { PlayerButton, PlayerButtonDetailed } from "../common/buttons";
import { BuyButton } from "../common/buyButton";
import Price from "../common/price";
import Image from "../image";
import Loader from "../common/loader";
import { BasketAdd, Wantlist } from "../common/icons";
import useCheckout from "../../hooks/useCheckout";
import { truncate } from "../global/meta";
import { useMutation } from "@apollo/client";
import { POST_WANT, POST_RELEASE_SNIPPET_ARCHIVE } from "../../graphql/queries";
import { Store } from "../../contexts/Store";
import Carousel from "../global/carousel";
import { ArtistNames, LabelNames, FormatNames } from "../item";
import sanitizeHtml from "sanitize-html";

export function Tile ({ entry, disabled }) {
  const { config, addNotification, updateBasket, t } = Store.useState(s => s);
  const [isLoading, setIsLoading] = useState(false);
  const [submitWant] = useMutation(POST_WANT);
  const { hasInBasket } = useCheckout();
  const handleBasketUpdate = async (id) => {
    setIsLoading(true);
    await updateBasket(id);
    setIsLoading(false);
  };

  const handleAddToWantlist = () => {
    submitWant({ variables: { id: entry.id } })
      .then(results =>
        addNotification({
          ok: 1,
          message: (
            <div className="addWantlist">
              <p className="title">Item {!entry.wanted ? "added to" : "removed from"} wantlist</p>
              <Link to="/wantlist?stock=all">View my wantlist</Link>
            </div>
          )
        })
      )
      .catch(e => {
        addNotification({
          ok: 0,
          message: (
            <div className="addWantlist">
              <Link to="/login">{t("loginToYourAccount")}</Link>
            </div>
          )
        });
      });
  };

  const forSaleListings = entry.listings ? entry.listings.filter(l => l.stock.quantity > 0) : [];
  const cheapest = forSaleListings.length ? forSaleListings[0] : null;

  const sanitizedDescription = sanitizeHtml(entry.descriptions.shop.html, { allowedTags: ["b", "strong"], allowedAttributes: {} });

  return (
    <div className="tile">
      <div className="image">
        <Link to={entry.path}>
          <Image entry={entry.data.images.length ? entry.data.images[0] : null} config={config} />
        </Link>
      </div>
      <div className="description">
        <div className="priceAndWantlist">
          {cheapest ? (
            <button disabled={isLoading || disabled} onClick={e => handleBasketUpdate(cheapest.id)} className="addToBasket">
              <Price value={config.taxes.rules.showPricesBeforeTaxes ? cheapest.prices.beforeTaxes : cheapest.prices.sale} />
              {cheapest.prices.compare ? <Price className="discounted" value={cheapest.prices.compare} /> : null}
              {!hasInBasket(cheapest.id) ? <BasketAdd /> : <BasketAdd active />}
            </button>
          ) : (
            <p title="Not available" className="notAvailable">
              N/A
            </p>
          )}
          <button className="addToWantlist" onClick={e => handleAddToWantlist(entry._id)}>
            {entry.wanted ? <Wantlist active /> : <Wantlist />}
          </button>
        </div>
        {entry.type === "ReleaseItem" ? (
          <>
            <div className="artistsAndTitle">
              {entry.data.artists.length ? (
                <p className="artistName">
                  {entry.data.artists.map((a, i) => (
                    <React.Fragment key={i}>
                      <Link to={`/catalogue?artists=${a.id}`} className="singleArtistName">
                        {a.anv || a.name}
                      </Link>
                      {(i < entry.data.artists.length - 1) ? <span className="join">&nbsp;</span> : null}</React.Fragment>))}
                </p>
              ) : null}
              <Link to={entry.path}>
                <p>{truncate(entry.data.title, 60)}</p>
              </Link>
            </div>
          </>
        ) : (
          <div className="manufacturerAndTitle">
            <Link to={entry.path}>
              <p className="manufacturer">{entry.data.manufacturer}</p>
              <p>{entry.data.title}</p>
            </Link>
          </div>
        )}
      </div>
      {entry.type === "ReleaseItem" && entry.data.tracklist ? (
        <div className="tracks">
          {entry.data.tracklist
            .filter(t => t.uri)
            .map((t, i) => (
              <PlayerButton key={i} track={t} release={entry} />
            ))}
        </div>
      ) : null}
      {sanitizedDescription ? (
        <div className="descriptionTextContainer">
          <div className="descriptionText"
            dangerouslySetInnerHTML={{
              __html: sanitizedDescription
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export function List ({ entry, disabled }) {
  const { config, addNotification, updateBasket, t, session } = Store.useState(s => s);

  const [submitWant] = useMutation(POST_WANT);
  const { hasInBasket } = useCheckout();

  const handleAddToWantlist = () => {
    if (!session)
      return addNotification({
        ok: 0,
        message: (
          <div className="addWantlist">
            <Link to="/login">Please login to your account</Link>
          </div>
        )
      });
    else
      addNotification({
        ok: 1,
        message: (
          <div className="addWantlist">
            <p className="title">Item {!entry.wanted ? "added to" : "removed from"} wantlist</p>
            <Link to="/wantlist?stock=all">View my wantlist</Link>
          </div>
        )
      });
    submitWant({ variables: { id: entry.id } });
  };

  const forSaleListings = entry.listings ? entry.listings.filter(l => l.stock.quantity > 0) : [];
  const cheapest = forSaleListings.length ? forSaleListings[0] : null;
  const mostExpensive = Math.max(entry.listings.map(o => o.prices.sale));
  const wantlistText = entry.wanted ? t("removeFromWantlist") : t("addToWantlist");

  const isVarious = entry.type === "ReleaseItem" && entry.data.artists.find(a => a.id === 194);

  return (
    <div className="listView">
      <hr />
      <div className="container">
        <div className="thumbnail">
          <Link to={entry.path}>
            <Image alt={entry.description} entry={entry.data.images.length ? entry.data.images[0] : null} config={config} />
          </Link>
        </div>
        {entry.type === "ReleaseItem" ? (
          <>
            <div className="releaseInformation">
              <Link to={entry.path}>
                <div className="artistAndTitle">
                  {entry.data.artists.length ? (
                    <p className="artists artist">
                      {entry.data.artists.map((a, i) => (
                        <React.Fragment key={i}>
                          <span className="singleArtistName">
                            {a.anv || a.name}
                          </span>
                          {a.join && i < entry.data.artists.length - 1 ? <span className="join">{a.join + " "}</span> : " "}
                        </React.Fragment>
                      ))}
                    </p>
                  ) : null}
                  <p className="title">{entry.data.title}</p>
                </div>
              </Link>
              <p className="releaseDescription">
                {entry.data.labels.map((l, index) => (
                  <span className="label" key={index + l.name}>
                    {l.id ? (
                      <Link to={`/catalogue?labels=${l.id}`}>
                        {l.name} | {l.catno}
                      </Link>
                    ) : (
                      l.name
                    )}
                  </span>
                ))}
                <span className="country"> | {entry.data.country}</span>
                {entry.data.releaseDate ? (
                  <span className="year"> | {moment(entry.data.releaseDate).format("YYYY")} </span>
                ) : (
                  ""
                )}
              </p>
            </div>
            {cheapest ? (
              <div className="priceContainer">
                <button className="buttonsContainer" onClick={e => updateBasket(cheapest.id)}>
                  <div className="priceAndBasket">
                    {cheapest.prices.compare ? <Price className="discounted" value={cheapest.prices.compare} /> : null}
                    <span className="priceButtonHover">
                      {!hasInBasket(cheapest.id) ? (
                        <span className="hiddenBasket">
                          <BasketAdd />
                        </span>
                      ) : (
                        <BasketAdd active />
                      )}
                    </span>
                    <span className="priceButton">
                      <Price value={config.taxes.rules.showPricesBeforeTaxes ? cheapest.prices.beforeTaxes : cheapest.prices.sale} />
                    </span>
                    <span className="addToBasket">
                      {!hasInBasket(cheapest.id) ? t("addToBasket") : t("removeFromBasket")}
                    </span>
                  </div>
                </button>
              </div>
            ) : (
              <div className="priceContainer">
                <Price className="soldOutPrice" value={mostExpensive} />
                <div className="priceAndBasket">
                  <p title="Not available" className="priceButton">
                    <span className="soldOut">Sold Out</span>
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="productInformation">
              <div className="artistAndTitle">
                <Link to={entry.path}>
                  <p className="productName">{entry.data.title}</p>
                </Link>
                <Link to={`/products?manufacturers=${entry.data.manufacturer}`}>
                  <p className="manufacturer">{entry.data.manufacturer}</p>
                </Link>
              </div>
            </div>
            {cheapest ? (
              <div className="priceContainer">
                <button className="buttonsContainer" onClick={e => updateBasket(cheapest.id)}>
                  <div className="priceAndBasket">
                    <span className="priceButtonHover">
                      {cheapest.prices.compare ? (
                        <Price className="discounted" value={cheapest.prices.compare} />
                      ) : null}
                      {!hasInBasket(cheapest.id) ? (
                        <span className="hiddenBasket">
                          <BasketAdd />
                        </span>
                      ) : (
                        <BasketAdd active />
                      )}
                    </span>
                    <span className="priceButton">
                      <Price value={config.taxes.rules.showPricesBeforeTaxes ? cheapest.prices.beforeTaxes : cheapest.prices.sale} />
                    </span>
                    <span className="addToBasket">
                      {!hasInBasket(cheapest.id) ? t("addToBasket") : t("removeFromBasket")}
                    </span>
                  </div>
                </button>
              </div>
            ) : (
              <div className="priceContainer">
                <Price className="soldOutPrice" value={mostExpensive} />
                <div className="priceAndBasket">
                  <p title="Not available" className="priceButton">
                    <span className="soldOut">Sold Out</span>
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        <div className="wantlistContainer">
          <button className="buttonsContainer" onClick={e => handleAddToWantlist(entry._id)}>
            <span className="addToWantlist">
              {wantlistText}
              <Wantlist active={entry.wanted} />
            </span>
          </button>
        </div>
        {entry.type === "ReleaseItem" ? (
          <div className="shopDescription">
            <p className="descriptionWrapper">{entry.descriptions.shop.text.replace(/(<([^>]+)>)/gi, "")}</p>
          </div>
        ) : (
          <div className="productDescription">
            <p className="descriptionWrapper">{entry.descriptions.shop.text.replace(/(<([^>]+)>)/gi, "")}</p>
          </div>
        )}
        <div className="bottom">
          {entry.type === "ReleaseItem" ? (
            <div className="tracklist">
              {entry.data.tracklist
                .filter(t => t.type_ === "track")
                .map((t, index) => (
                  <div key={`${t.position}-${index}`} className="trackEntry">
                    <PlayerButtonDetailed key={index} track={t} release={entry} variousArtist={isVarious} />
                  </div>
                ))}
            </div>
          ) : (
            <div className="productOptions"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export function Full ({ entry, addNotification }) {
  const { config, t, session } = Store.useState(s => s);
  const [submitWant] = useMutation(POST_WANT);
  const [isGeneratingZip, setIsGeneratingZip] = useState(false);
  const [downloadReleaseSnippets] = useMutation(POST_RELEASE_SNIPPET_ARCHIVE);
  const [currentImage, setCurrentImage] = useState(null);
  const theme = config.eshop.theme.selected;

  const isVarious = entry.type === "ReleaseItem" && entry.data.artists.find(a => a.id === 194);
  const forSaleListings = entry.listings ? entry.listings.filter(l => l.stock.quantity > 0) : [];
  const cheapest = forSaleListings.length ? forSaleListings[0] : null;
  const isRelease = entry.type === "ReleaseItem";

  const handleLoadImage = entry => setCurrentImage(entry);

  const handleAddToWantlist = () => {
    submitWant({ variables: { id: entry.id } })
      .then(results =>
        addNotification({
          ok: 1,
          message: (
            <div className="addWantlist">
              <p className="title">Item {!entry.wanted ? "added to" : "removed from"} wantlist</p>
              <Link to="/wantlist?stock=all">View my wantlist</Link>
            </div>
          )
        })
      )
      .catch(e => {
        addNotification({
          ok: 0,
          message: (
            <div className="addWantlist">
              <Link to="/login">{t("loginToYourAccount")}</Link>
            </div>
          )
        });
      });
  };

  const handleDownloadSnippet = async e => {
    addNotification({ ok: 1, message: "Please wait while generating your zip file" });
    setIsGeneratingZip(true);
    try {
      const { data } = await downloadReleaseSnippets({ variables: { id: entry.id } });
      window.location = data.itemSnippetsArchive;
    } catch (e) {
      addNotification({ ok: 0, message: e.toString() });
    }
    setIsGeneratingZip(false);
  };

  return (
    <div className="fullView">
      <div className={isRelease ? "content release" : "content product"}>
        <div className="title">
          <h2 className="artistsAndTitle">
            {isRelease ? <>
              {entry.data.artists.length ? (
                <span className="artists">
                  {entry.data.artists.map((a, i) => (
                    <Link to={`/catalogue?artists=${a.id}`} key={i} className="artist">
                      <span className="singleArtistName">{a.anv || a.name}</span>
                      {a.join && i < entry.data.artists.length - 1 ? <span className="join">{a.join + " "}</span> : " "}
                    </Link>
                  ))}
                </span>
              ) : null}
            </> : <> {entry.data.manufacturer} </>}
          </h2>
          <Link to={entry.path}>
            <h2 className="title">{entry.data.title}</h2>
          </Link>
        </div>
        <div className="summary">
          {isRelease ? (
            <>
              <div className="itemInfo artists">
                <p>
                  <ArtistNames artists={entry.data.artists} />
                </p>
              </div>
              <div className="itemInfo labels">
                <p>
                  <LabelNames labels={entry.data.labels} />
                </p>
              </div>
              {entry.data.labels.length > 0 ? (
                <div className="itemInfo catNo">
                  <p>{entry.data.labels ? entry.data.labels.filter(l => l.catno).map(l => <span key={l.catno}>{l.catno}{" "}</span>) : null}</p>
                </div>
              ) : null}
              <div className="itemInfo formats">
                <p>
                  <FormatNames formats={entry.data.formats} />
                </p>
              </div>
              <div className="itemInfo country">
                <p>
                  <Link to={`/catalogue?countries=${entry.data.country}`}>{entry.data.country}</Link>
                </p>
              </div>
              {entry.data.releaseDate ? (
                <div className="itemInfo releaseDate">
                  <p>
                    <span className="day"> {moment(entry.data.releaseDate).format("DD")} </span>
                    <span className="month">{moment(entry.data.releaseDate).format("MMMM")} </span>
                    <span className="year">{moment(entry.data.releaseDate).format("YYYY")}</span>
                  </p>
                </div>) : null}
              <div className="itemInfo genres">
                <p>
                  {entry.data.genres.map(g => (
                    <Link key={g} to={`/catalogue?genres=${g}`}>
                      {g}{" "}
                    </Link>
                  ))}
                </p>
              </div>
              <div className="itemInfo styles">
                <p>
                  {entry.data.styles.map(g => (
                    <Link key={g} to={`/catalogue?styles=${g}`}>
                      {g}{" "}
                    </Link>
                  ))}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="itemInfo title">
                <p>{entry.data.title}</p>
              </div>
              <div className="itemInfo manufacturer">
                <p>
                  <Link to={`/products?manufacturers=${entry.data.manufacturer}`}>{entry.data.manufacturer}</Link>
                </p>
              </div>
              <div className="itemInfo catNo">
                <p>{entry.data.cat}</p>
              </div>
            </>
          )}
          <h3 className="price">
            {cheapest ? <Price value={config.taxes.rules.showPricesBeforeTaxes ? cheapest.prices.beforeTaxes : cheapest.prices.sale} /> : <span className="notAvailable" />}
          </h3>
        </div>
        {theme.views?.full?.imagesAsCarousel ? <div className="carousel">
          <Link to={entry.path}>
            <Carousel autoplay={false} entries={entry.data.images.map(e => ({ to: null, src: e.uri }))} />
          </Link>
        </div> : <>
          <div className="primaryImage">
            <Link to={entry.path}>
              <Image alt={entry.description} entry={currentImage || (entry.data.images.length ? entry.data.images[0] : null)} config={config} />
            </Link>
          </div>
          <div className="secondaryImages">
            {entry.data.images.map((i, idx) =>
              <button key={idx} onClick={e => handleLoadImage(i)} type="button">
                <Image alt={entry.description} entry={i} config={config} />
              </button>
            )}
          </div>
        </>}
        <div className="tracklist">
          {isRelease && entry.data.tracklist
            .filter(t => t.type_ === "track")
            .map((t, index) => (
              <div key={`${t.position}-${index}`} className="trackEntry">
                <PlayerButtonDetailed key={index} track={t} release={entry} variousArtist={isVarious} />
              </div>
            ))}
        </div>
        <div className="descriptions">
          {entry.descriptions.shop.short ? <div className="short">
            <h3>{t("shortDescription")}</h3>
            <p>{entry.descriptions.shop.short}</p>
          </div> : null}
          {entry.descriptions.shop.text ? <div className="long">
            <h3>{t("longDescription")}</h3>
            <p>{entry.descriptions.shop.text.replace(/(<([^>]+)>)/gi, "")}</p>
          </div> : null}
        </div>
        <div className={`buyButtons ${!entry.listings?.length ? "login" : ""} ${isRelease ? "release" : "product"}`}>
          {entry.listings?.length ? entry.listings.map(l => <BuyButton key={l.id} listing={l} forSales={entry.listings} />) : <Link to="/login"><button type="button"><span>{t("login")}</span></button></Link>}
        </div>
        <div className="moreInfo">
          <Link to={entry.path}>
            <button type="button">
              <span>{t("moreInfo", "More info")}</span>
            </button>
          </Link>
        </div>
        <div className="addToWantlist">
          <button type="button" onClick={handleAddToWantlist}>
            <span>{entry.wanted ? t("removeFromWantlist") : t("addToWantlist")}</span>
            {entry.wanted ? <Wantlist active /> : <Wantlist />}
          </button>
        </div>
        {isRelease && session && config.getProperty("information", "snippetDownload") ? (
          <div className="snippetDownload">
            <button
              type="button"
              onClick={handleDownloadSnippet}
              disabled={isGeneratingZip}>
              {isGeneratingZip ? <Loader /> : <span>{t("downloadSnippets")}</span>}
            </button>
          </div>
        ) : null}
        {session && entry.data.assetLink ? (
          <div className="assetsDownload">
            <a target="_blank" href={entry.data.assetLink} rel="noreferrer">
              <button type="button"><span>{t("downloadAssets")}</span></button>
            </a>
          </div>
        ) : null}
      </div>
    </div >
  );
}
