import { useEffect } from "react";
import clone from "clone";
import { useQuery } from "@apollo/client";
import { GET_SESSION } from "../graphql/queries";
import { Store } from "./Store";
import Cookies from "js-cookie";

export default () => {
  const { data: sessionData } = useQuery(GET_SESSION);

  useEffect(() => {
    if (sessionData) {
      Store.update(s => { s.session = clone(sessionData.session); });
      if (sessionData.session?.jwt)
        Cookies.set("jwt", sessionData.session.jwt, { expires: 90 });
      else Cookies.remove("jwt");
    }
  }, [sessionData]);

  return null;
};
