import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Meta from "../global/meta";
import Loader from "../common/loader";
import OrderTable from "./table";
import { Store } from "../../contexts/Store";
import { useLazyQuery } from "@apollo/client";
import { GET_ORDERS } from "../../graphql/queries";

export default function Orders (props) {
  const { session, t } = Store.useState(s => s);
  const [redirect, setRedirect] = useState(null);

  const [getOrders, { loading, data }] = useLazyQuery(GET_ORDERS, { fetchPolicy: "cache-and-network" });

  const orders = data?.orders;

  useEffect(() => {
    if (!session) setRedirect("/");
    else getOrders({ variables: { buyerRef: session.user._id }, fetchPolicy: "cache-and-network" });
  }, [session, getOrders]);

  if (!session) return <Redirect to="/login" />;

  return (
    <div id="orders">
      {redirect ? <Redirect to={redirect} /> : null}
      <Meta title="Orders" />
      {orders && !loading ? <OrderTable t={t} orders={orders} /> : <Loader />}
      {orders && !orders.length ? (
        <div id="noOrders">
          <h2>No orders found for your account</h2>
        </div>
      ) : null}
    </div>
  );
}
