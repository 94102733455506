import { useEffect, useCallback, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { PlayerStore, Store } from "../contexts/Store";
import { useMutation } from "@apollo/client";
import { POST_PLAY_COUNT } from "../graphql/queries";

export default props => {
  const { addNotification, previewTheme, theme } = Store.useState(s => s);
  const player = PlayerStore.useState(s => s);
  const waveformColor = (previewTheme || theme)?.colours?.areas?.find(a => a.name === "audio-waveform")?.value;
  const waveformProgress = (previewTheme || theme)?.colours?.areas?.find(a => a.name === "audio-waveform-progress")?.value;

  const { position, tracklist, domReady, shouldLoadNext, shouldLoadPrevious } = player;
  const [wavesurfer, setWavesurfer] = useState();

  useEffect(() => {
    if (domReady) {
      setWavesurfer(
        WaveSurfer.create({
          normalize: true,
          hideScrollbar: true,
          container: "#waveform",
          backend: "WebAudio",
          waveColor: "#bbb",
          progressColor: "#aaa",
          height: 20
        })
      );
    }
  }, [domReady]);

  useEffect(() => {
    if (wavesurfer) {
      if (waveformColor) wavesurfer.setWaveColor(waveformColor);
      if (waveformProgress) wavesurfer.setProgressColor(waveformProgress);
    }
  }, [wavesurfer, waveformColor, waveformProgress]);

  const [playCount] = useMutation(POST_PLAY_COUNT);

  useEffect(() => {
    if (!shouldLoadNext && !shouldLoadPrevious) return;

    if (shouldLoadNext) {
      PlayerStore.update(s => {
        s.shouldLoadNext = false;
        s.position = position < tracklist.length - 1 ? position + 1 : position;
      });
    } else if (shouldLoadPrevious) {
      PlayerStore.update(s => {
        s.shouldLoadPrevious = false;
        s.position = position - 1 >= 0 ? position - 1 : position;
      });
    }
  }, [position, shouldLoadNext, shouldLoadPrevious, tracklist.length]);

  useEffect(() => {
    if (!wavesurfer) return;
    wavesurfer.on("ready", () => {
      wavesurfer.play();
    });
    wavesurfer.on("play", () => {
      PlayerStore.update(s => {
        s.isPlaying = true;
      });
    });
    wavesurfer.on("loading", percent => {
      PlayerStore.update(s => {
        s.isLoading = percent !== 100;
      });
    });
    wavesurfer.on("finish", () => {
      PlayerStore.update(s => {
        s.shouldLoadNext = true;
      });
    });

    wavesurfer.on("error", err => {
      if (err && err.toString().includes("decoding")) addNotification({ ok: 0, message: err.toString() });
    });
  }, [wavesurfer, addNotification]);

  const loadTracklist = useCallback(
    tracklistToLoad => {
      wavesurfer.stop();
      PlayerStore.update(s => {
        s.isShowing = true;
        s.position = tracklistToLoad.playOffset || 0;
        s.tracklist = tracklistToLoad.tracklist;
      });
    },
    [wavesurfer]
  );

  useEffect(() => {
    if (position > -1 && position < tracklist.length && tracklist[position].track.uri) {
      const trackToPlay = tracklist[position];
      wavesurfer.load(trackToPlay.track.uri);
      PlayerStore.update(s => {
        s.isLoading = true;
        s.isShowing = true;
        s.currentTrackData = { ...trackToPlay };
      });
      playCount({ variables: { id: trackToPlay.release.id, position: trackToPlay.track.position } }).catch(
        console.error
      );
    }
  }, [tracklist, playCount, position, wavesurfer]);

  const playPauseTrack = useCallback(() => {
    wavesurfer.playPause();
    PlayerStore.update(s => {
      s.isPlaying = wavesurfer.isPlaying();
    });
  }, [wavesurfer]);

  const stopAndClose = useCallback(() => {
    wavesurfer.stop();
    PlayerStore.update(s => {
      s.isPlaying = false;
      s.isShowing = false;
    });
  }, [wavesurfer]);

  useEffect(() => {
    PlayerStore.update(s => {
      s.stopAndClose = stopAndClose;
      s.loadTracklist = loadTracklist;
      s.playPauseTrack = playPauseTrack;
    });
  }, [loadTracklist, playPauseTrack, stopAndClose]);

  return null;
};
