import { useEffect, useState } from "react";
import clone from "clone";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { useQuery } from "@apollo/client";
import { GET_CONFIG } from "../graphql/queries";
import { Store } from "./Store";
import URI from "urijs";
import { useMediaQuery } from "react-responsive";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
const isProduction = process.env.REACT_APP_BUILD_ENV === "production";

export default () => {
  const [configWasInitiallyLoaded, setConfigWasInitiallyLoaded] = useState(false);
  const isEditingTheme = new URI(window.location.href).search(true).theme;
  const { data: configData, refetch } = useQuery(GET_CONFIG, { fetchPolicy: "cache-and-network" });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  useEffect(() => {
    if (configData && configData.config) {
      const config = clone(configData.config);
      if (config.apiKey) Cookies.set("x-api-key", config.apiKey.value);

      const theme = config.eshop.theme.selected;
      if (!theme) return;
      config.getProperty = function (moduleId, property) {
        const module = config.modules.find(m => m.id === moduleId);
        if (module && module.data) return module.data[property];
        return null;
      };
      config.getColourAreaValue = function (areaName) {
        if (!theme.colours) return;
        const areas = theme.colours.areas;
        if (!areas) return null;
        const area = areas.find(a => a.name === areaName);
        if (!area) return null;
        return area.value;
      };

      const root = document.documentElement;
      const fontSizes = theme.fontSizes;
      const spacings = theme.spacings;
      const colours = theme.colours;
      const miscs = theme.miscs;

      if (fontSizes)
        fontSizes[isTabletOrMobile ? "mobile" : "desktop"].forEach((item, i) =>
          root.style.setProperty(`--cg-${item.name}`, item.value)
        );
      if (spacings)
        spacings[isTabletOrMobile ? "mobile" : "desktop"].forEach((item, i) =>
          root.style.setProperty(`--cg-${item.name}`, item.value)
        );

      if (!configWasInitiallyLoaded) {
        const customCss = theme.fontFaces + " " + theme.styles.reduce((v, s) => v + s.css, "");
        const stylesheet = theme.stylesheet;
        if ((customCss || stylesheet) && !isEditingTheme) {
          if (stylesheet) {
            const linkElement = document.createElement("link");
            linkElement.setAttribute("rel", "stylesheet");
            linkElement.setAttribute("type", "text/css");
            linkElement.setAttribute("href", stylesheet);
            document.head.appendChild(linkElement);
          }
          if (customCss) {
            const style = document.createElement("style");
            style.setAttribute("type", "text/css");
            document.head.appendChild(style);
            style.innerHTML = customCss;
          }
        }

        const googleTagManager = config.getProperty("googleAnalytics", "gtm");
        if (googleTagManager) TagManager.initialize({ gtmId: googleTagManager });

        const configUA = config.google?.analytics?.view?.name || config.getProperty("googleAnalytics", "id");
        const GATracker = config.google?.analytics?.property?.tracker;

        const isUA = configUA && configUA.includes("UA-");
        const UATracker = isUA && config.google?.analytics?.view?.name?.match(/^UA-[^ ]*/)[0];
        const testMode = !isProduction;

        if (UATracker) ReactGA.initialize(UATracker, { testMode });

        const trackers = [{ trackingId: process.env.REACT_APP_GA, testMode }];
        if (GATracker) trackers.push({ trackingId: GATracker, testMode });

        if (!isProduction) console.log("Trackers", trackers, UATracker, "Config is using old UA", isUA, "Test mode:", testMode);
        ReactGA4.initialize(trackers);

        ReactGA4.set({ appName: config.getProperty("information", "shopName") });
        ReactGA4.set({ appId: String(config.id) });
        ReactGA4.set({ hostname: config.uri });
        ReactGA4.set({ set: { user_properties: { origin: config.uri } } });
      }

      root.style.setProperty("--cg-primary", colours.primary);
      root.style.setProperty("--cg-secondary", colours.secondary);

      if (miscs)
        miscs.forEach(item => {
          root.style.setProperty(`--cg-global-${item.name}`, item.value);
        });
      if (colours) {
        colours.areas.forEach((item, i) => {
          root.style.setProperty(`--cg-${item.name}`, item.value);
        });
        colours.presets.forEach((item, i) => {
          root.style.setProperty(`--cg-preset-${item.name.replace("#", "").toLowerCase()}`, item.value);
        });
      }

      Store.update(s => {
        s.config = clone(config);
        s.reloadConfig = refetch;
        s.theme = clone(theme);
      });
      setConfigWasInitiallyLoaded(true);
    }
  }, [configData, refetch, isTabletOrMobile, configWasInitiallyLoaded, isEditingTheme]);

  return null;
};
