import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { Store } from "../contexts/Store";

const useAnalytics = () => {
  const config = Store.useState(s => s.config);
  const location = useLocation();
  const url = location.pathname + location.search;

  /** * Page view ***/
  useEffect(() => {
    setTimeout(() => {
      if (config) {
        console.log("Page view", url, document.title);
        if (ReactGA.ga())
          ReactGA.pageview(url);
        if (ReactGA4.isInitialized)
          ReactGA4.send("pageview");
      }
    }, 600);
  }, [url]);

  return null;
};

export default useAnalytics;
