import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ entry, config, className, id, alt }) => {
  const [isHover, setIsHover] = useState(false);

  const imageEntry = entry || null;
  let imageSrc = imageEntry?.uri ||
              config.getProperty("designs", "missingImageUri") ||
              "/img/default-release.png";

  const alternativeSrc = imageEntry && imageEntry.alternative && imageEntry.alternative.uri;
  if (isHover && alternativeSrc) imageSrc = alternativeSrc;

  return <LazyLoadImage className={className || ""} id={id} alt={alt} src={imageSrc} onMouseEnter={e => setIsHover(true)} onMouseLeave={e => setIsHover(false)} />;
};

export default Image;
