import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Meta from "../components/global/meta";
import Loader from "../components/common/loader";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import URI from "urijs";
import { useQuery } from "@apollo/client";
import { GET_ARTISTS } from "../graphql/queries";
import { Store } from "../contexts/Store";

export default function Home (props) {
  const location = useLocation();
  const search = new URI(location.pathname + location.search).search(true);
  const letter = search.letter;

  const { loading, data } = useQuery(GET_ARTISTS, { variables: { letter } });
  const artists = data && data.artists;

  useEffect(() => {
    Store.update(s => { s.globalClass = "artists"; });
    setTimeout(() => {
      window.prerenderReady = true;
    }, 100);
  }, []);

  useEffect(() => {
    if (letter) {
      const element = document.getElementById(`letter-${letter}`);
      if (element) element.scrollIntoView();
    }
  }, [letter]);

  if (loading) return <Loader style={{ marginTop: "var(--gutter)" }} />;

  const letters = [];
  for (let i = 0; i < 26; i++) {
    const character = String.fromCharCode(97 + i);
    letters.push({
      letter: character,
      entries: artists ? artists.filter(a => a.name.toLowerCase().charAt(0) === character) : []
    });
  }

  return (
    <div id="artists">
      <Meta title="Artists" noIndex={true} />
      <div className="header">
        <h1>Artists</h1>
        <div className="abecedaire">
          {letters.map(l => (
            <Link
              key={l.letter}
              className={`${l.letter === letter ? "active" : ""}`}
              to={new URI(location.pathname).setSearch({ letter: l.letter }).toString()}>
              {l.letter}
            </Link>
          ))}
        </div>
      </div>
      <div className="content">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 3, 750: 5, 900: 8 }}>
          <Masonry gutter="100">
            {letters
              .filter(l => l.entries.length)
              .map(l => (
                <div key={l} className={`entry ${l.letter === letter ? "active" : ""}`} id={`letter-${l.letter}`}>
                  <h3>{l.letter}</h3>
                  {l.entries.map((e, i) => (
                    <p key={e.name + i}>
                      <Link className="name" to={`/catalogue?artists=${e.id}&stock=all`}>
                        {e.name}
                      </Link>
                    </p>
                  ))}
                </div>
              ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
}
