import React, { useEffect } from "react";
import Meta from "./global/meta";
import Loader from "./common/loader";
import FourOFour from "./global/404";
import BlocksV2 from "./common/blocksV2";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ARTICLE, POST_REGISTER_EVENT } from "../graphql/queries";
import { Store } from "../contexts/Store";

export default function Page (props) {
  const currentPath = props.location.pathname + props.location.search;
  const handle = props.handle || ("/" + props.match?.params?.handle);

  const [getArticleData, { loading, data }] = useLazyQuery(GET_ARTICLE);
  const [registerEvent] = useMutation(POST_REGISTER_EVENT);

  const article = data && data.article;

  useEffect(() => {
    Store.update(s => { s.globalClass = `page ${handle}`; });
    if (handle) getArticleData({ variables: { handle, preview: props.location.search.includes("preview=true") } });
  }, [handle, getArticleData]);

  useEffect(() => {
    if (article)
      registerEvent({ variables: { type: "pageView", data: { ref: article._id } } });
  }, [article]);

  if (article === undefined || loading) return <Loader withMargins />;
  else if (article === null) return <FourOFour />;

  return (
    <div id="page">
      <Meta
        path={currentPath}
        description={article.seo.description || article.title}
        index={article.seo.index}
        title={article.title}
        updated={article.modified}
        image={article.thumbnail?.formats?.thumbnail?.url}
      />
      {article.content.rows ? <BlocksV2 rows={article.content.rows} /> : null}
    </div>
  );
}
