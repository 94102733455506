import React, { useEffect, useCallback } from "react";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { Store } from "./Store";

export const AppContext = ({ children, apolloClient }) => {
  const { config: storeConfig, checkout } = Store.useState(s => s);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  const resetStore = useCallback(async () => {
    await apolloClient.queryManager.fetchQueryRejectFns;
    await apolloClient.cache.reset();
  }, [apolloClient]);

  const addNotification = useCallback(data => {
    if (!data.ok) NotificationManager.error(data.message);
    else NotificationManager.info(data.message);
  }, []);

  useEffect(() => {
    Store.update(s => {
      s.addNotification = addNotification;
      s.t = t;
      s.i18n = i18n;
      s.resetStore = resetStore;
      s.isTabletOrMobile = isTabletOrMobile;
    });
  }, [addNotification, i18n, resetStore, t, isTabletOrMobile]);

  return <>{storeConfig && checkout ? children : null}</>;
};

export default AppContext;
